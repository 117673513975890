import { hasSecurityRoles } from "@salvus/shared/modules/authorization";

const routes = Object.freeze([
  {
    key: "/",
    path: "/",
    name: "Home",
    exact: true,
    requiredRoles: [],
    isProtected: true,
    children: []
  },
  {
    key: "/dashboard",
    name: "Dashboard",
    path: "/dashboard",
    exact: true,
    navbar: true,
    isProtected: true,
    requiredRoles: [],
    children: []
  },
  {
    key: "/discoveries",
    name: "Discoveries",
    path: "/discoveries",
    exact: true,
    navbar: true,
    isProtected: true,
    requiredRoles: [
      "GlobalAdmin",
      "Admin",
      "TenantAdmin",
      "DiscoveriesAdmin",
      "DiscoveriesCollaborator",
      "DiscoveriesReader"
    ],
    children: []
  },
  {
    key: "/learning",
    name: "Learning",
    path: "/learning",
    exact: true,
    navbar: true,
    isProtected: true,
    requiredRoles: [],
    children: []
  },
  {
    key: "/settings",
    name: "Settings",
    path: "/settings",
    exact: true,
    isProtected: true,
    requiredRoles: [
      "GlobalAdmin",
      "Admin",
      "TenantAdmin",
      "TenantManagementAdmin",
      "TenantManagementReader",
      "UserManagementAdmin",
      "UserManagementReader",
      "SubscriptionManagementAdmin",
      "SubscriptionManagementReader",
      "LogManagementAdmin",
      "LogManagementReader",
      "TopicManagementAdmin",
      "TopicManagementReader"
    ],
    settingsbar: true,
    children: [
      {
        key: "/settings/tenants",
        name: "Tenants",
        path: "/settings/tenants",
        exact: true,
        isProtected: true,
        requiredRoles: [
          "GlobalAdmin",
          "Admin",
          "TenantAdmin",
          "TenantManagementAdmin",
          "TenantManagementReader"
        ],
        children: [
          {
            key: "/settings/tenants/details",
            name: "Details",
            path: "/settings/tenants/:tenantId/details",
            exact: true,
            isProtected: true,
            requiredRoles: [
              "GlobalAdmin",
              "Admin",
              "TenantAdmin",
              "TenantManagementAdmin",
              "TenantManagementReader"
            ],
            children: []
          }
        ]
      },
      {
        key: "/settings/users",
        name: "Users",
        path: "/settings/users",
        exact: true,
        isProtected: true,
        requiredRoles: [
          "GlobalAdmin",
          "TenantAdmin",
          "UserManagementAdmin",
          "UserManagementReader"
        ],
        children: []
      },
      {
        key: "/settings/subscriptions",
        name: "Subscriptions",
        path: "/settings/subscriptions",
        exact: true,
        isProtected: true,
        requiredRoles: [
          "GlobalAdmin",
          "Admin",
          "TenantAdmin",
          "SubscriptionManagementAdmin",
          "SubscriptionManagementReader"
        ],
        children: []
      },
      {
        key: "/settings/topics",
        name: "Topics",
        path: "/settings/topics",
        exact: true,
        isProtected: true,
        requiredRoles: [
          "GlobalAdmin",
          "Admin",
          "TenantAdmin",
          "TopicManagementAdmin",
          "TopicManagementReader"
        ],
        children: [
          {
            key: "/settings/topics/id/keywords",
            path: "/settings/topics/:topicId/keywords",
            exact: true,
            isProtected: true,
            requiredRoles: [
              "GlobalAdmin",
              "Admin",
              "TenantAdmin",
              "TopicManagementAdmin",
              "TopicManagementReader"
            ],
            children: []
          }
        ]
      },
      {
        key: "/settings/logs",
        name: "Logs",
        path: "/settings/logs",
        exact: true,
        isProtected: true,
        requiredRoles: [
          "GlobalAdmin",
          "Admin",
          "TenantAdmin",
          "LogManagementAdmin",
          "LogManagementReader"
        ],
        children: []
      }
    ]
  },
  {
    key: "/profile",
    name: "Profile",
    path: "/profile",
    exact: true,
    isProtected: true,
    requiredRoles: [],
    children: []
  },
  {
    key: "/auth/callback",
    name: "Authentication",
    path: "/auth/callback",
    exact: true,
    isProtected: false,
    requiredRoles: [],
    children: []
  }
]);

const flattenRoutes = routeNodes => {
  const flattened = [];
  routeNodes.forEach(node => {
    flattened.push(node);
    flattened.push(...flattenRoutes(node.children || []));
  });
  return flattened;
};

export const flattenedRouteList = flattenRoutes(routes);

const getTopNavRoutes = routeNodes =>
  routeNodes?.filter(route => route?.navbar);
export const topNavRouteList = getTopNavRoutes(routes);

const getSettingsNavRouteList = routeNodes =>
  routeNodes?.filter(route => route?.settingsbar)?.[0]?.children;
export const settingsRouteList = getSettingsNavRouteList(routes);

export const filterNonSecuredRoutes = (
  routesToFilter,
  currentUser,
  activeTenantKey
) => {
  function filter(data) {
    return data?.reduce((arr, item) => {
      if (
        !item?.requiredRoles?.length ||
        hasSecurityRoles(currentUser, activeTenantKey, item.requiredRoles)
      ) {
        arr.push(item);
      }
      return arr; // <<-- need to return the accumulator
    }, []);
  }
  return filter(routesToFilter);
};

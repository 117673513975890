import React from "react";
import SettingsSideMenu from "../../components/SideMenus/SettingsSideMenu";
import PageLayout from "../../layouts/PageLayout";
import Content from "../../modules/System/Tenants/Content";

const TenantsPage = () => (
  <PageLayout
    className="tenants-page"
    side={{
      placement: "left",
      component: <SettingsSideMenu />
    }}
    header={{
      title: "Tenants",
      description:
        "Manage the tenants. Currently, the Tenants are managed via manual process and a engineered script process, however, we are aligning with Shell to integrate with Collibra and automate the majority of this data."
    }}
  >
    <Content />
  </PageLayout>
);

export default TenantsPage;

import { Avatar, Button } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import tinycolor from "tinycolor2";
import LayoutContext from "../../../../context/LayoutContext";

const defaultAvatarProps = {
  shape: "circle",
  size: "small"
};

const PresentationColor = ({
  avatarProps,
  color,
  style,
  children,
  onClick,
  showRandomGenerator
}) => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const completeAvatarProps = { ...defaultAvatarProps, ...avatarProps };

  const handleOnClick = useCallback(
    async value => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value);
        notify(notificationTypes.success, {
          message: "Copied!",
          description: "Color has been copied to clipboard"
        });
      }
    },
    [notificationTypes, notify]
  );

  const handleOnButtonClick = useCallback(async () => {
    const value = tinycolor.random().toHexString();
    if (onClick) {
      onClick(value);
    }
  }, [onClick]);

  return (
    <>
      <Avatar
        onClick={() => handleOnClick(color)}
        style={{
          cursor: "pointer",
          backgroundColor: color,
          verticalAlign: "middle",
          ...style
        }}
        {...completeAvatarProps}
      >
        {children}
      </Avatar>
      {showRandomGenerator && (
        <Button type="link" onClick={handleOnButtonClick}>
          Generate Color
        </Button>
      )}
    </>
  );
};

PresentationColor.propTypes = {
  avatarProps: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func,
  color: PropTypes.string,
  showRandomGenerator: PropTypes.bool,
  children: PropTypes.node
};

PresentationColor.defaultProps = {
  avatarProps: defaultAvatarProps,
  style: {},
  onClick: () => {},
  color: "blue",
  showRandomGenerator: false,
  children: null
};

export default PresentationColor;

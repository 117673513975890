import { Button } from "antd";
import parse from "html-react-parser";
import { cloneDeep, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { keywordSuggester } from "../../utils/api";
import { useCustomPOSTRequest } from "../../utils/hooks/restRequest";
import LearningContext from "./LearningContext";

const KeywordSuggestReplacerItem = ({ onUpdateKeyword, keyword }) => {
  const { postData } = useCustomPOSTRequest(keywordSuggester);
  const isSubscribedRef = useRef(true);
  const [suggestion, setSuggestion] = useState({});
  useEffect(
    () => () => {
      isSubscribedRef.current = false;
    },
    []
  );
  useEffect(() => {
    postData(keyword).then(data => {
      if (isSubscribedRef.current) {
        setSuggestion(data);
      }
    });
  }, [postData, keyword]);

  if (isEmpty(suggestion) || !suggestion.text || !suggestion.highlighted) {
    return null;
  }
  return (
    <div className="suggestion" style={{ dispaly: "block" }}>
      <span style={{ fontSize: 16, fontWeight: "bold" }}>
        Did you mean{" "}
        <Button
          type="link"
          style={{ fontSize: 16, fontStyle: "italic" }}
          onClick={() => {
            setSuggestion({});
            onUpdateKeyword(suggestion.text, keyword);
          }}
        >
          {parse(suggestion.highlighted || suggestion.text)}{" "}
        </Button>
      </span>
      <span style={{ fontSize: 16 }}>
        (Currently showing result for <b>{keyword}</b>)
      </span>
    </div>
  );
};

KeywordSuggestReplacerItem.propTypes = {
  onUpdateKeyword: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired
};

const KeywordSuggestReplacer = () => {
  const {
    filterContext: { selectedKeywords },
    filterOperations: { updateKeywords }
  } = useContext(LearningContext);
  const onUpdateKeyword = useCallback(
    (newKeyword, oldKeyword) => {
      const newKeywords = cloneDeep(selectedKeywords);
      const updatedKeywordIndex = newKeywords.findIndex(
        item => item === oldKeyword
      );
      newKeywords[updatedKeywordIndex] = newKeyword;
      updateKeywords(newKeywords);
    },
    [selectedKeywords, updateKeywords]
  );
  if (selectedKeywords.length === 0) {
    return null;
  }
  return (
    <div style={{ marginLeft: 20 }}>
      {selectedKeywords.map(item => (
        <KeywordSuggestReplacerItem
          key={item}
          keyword={item}
          onUpdateKeyword={onUpdateKeyword}
        />
      ))}
    </div>
  );
};

export default KeywordSuggestReplacer;

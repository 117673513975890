// eslint-disable-next-line

// import "moment/min/locales";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import utcPlugin from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import React from "react";
import { createRoot } from "react-dom/client";
// import "scrolling-element";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { doDataDogInit } from "./utils/datadog";

import "./index.less";

dayjs.extend(utcPlugin);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);

doDataDogInit();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Collapse, Descriptions, Typography } from "antd";
import React from "react";
import { ProjectLogo } from "../../../../components/CoreComponents";
import CustomDrawer from "../../../../components/CustomDrawer";
import { PROJECT_NAME, PROJECT_TAG_LINE } from "../../../../config";

const TEAM = [
  { name: "Haik, Stephen M GSUSI-PTS/IG", email: "Stephen.Haik@shell.com" },
  { name: "Khan, Nawaf H SBOBNG-PTIZ/T/S", email: "Nawaf.Khan@shell.com" },
  {
    name: "Rajaguru, Arun Baran SBOBNG-PTIZ/T/SS",
    email: "Arun-Baran.Rajaguru@shell.com"
  },
  {
    name: "Connolly, Timothy A SOPUS-PTS/IB",
    email: "Timothy.Connolly@shell.com"
  },
  { name: "Ayers, Jama H SEPCO-UPD/P/MSC", email: "James.Ayers3@shell.com" },
  { name: "Sun, Vanessa SITI-PTIY/CFB", email: "Vanessa.Sun@shell.com" },
  { name: "Boutiette, Chris SIEP-PTX/E/T", email: "Chris.Boutiette@shell.com" },
  {
    name: "Teague, Christopher SIEP-PTX/I/T",
    email: "Christopher.Teague@shell.com"
  },
  { name: "S, Naveen R SSSCCH-PTIY/FHB", email: "Naveen.S2@shell.com" },
  {
    name: "Ghirardelli, Federico GSUK-PTX/D/E",
    email: "Federico.Ghirardelli@shell.com"
  },
  { name: "Li, Zhuohui SIEP-PTX/I/T", email: "Zhuohui.Z.Li@shell.com" },
  {
    name: "Cunningham, Ryan SIEP-PTX/I/T",
    email: "Ryan.R.Cunningham@shell.com"
  },
  { name: "Fish, Tucker SIEP-PTX/I/T", email: "Tucker.Fish@shell.com" }
];

const CONTRIBUTORS = [
  { name: "Billings, David D GSUSI-PTS/IO", email: "david.billings@shell.com" },
  { name: "Barras, Justin P SEPCO-UPD/P/MSC", email: "J.Barras@shell.com" },
  { name: "Jakob, Todd SEPCO-UPD/P/MSC", email: "T.Jakob@shell.com" },
  {
    name: "Hopkins, Peter H SEPCO-UPD/P/MSC",
    email: "Peter.Hopkins@shell.com"
  },
  { name: "Allen, Claude A SEPCO-UPD/P/SO", email: "Claude.C.Allen@shell.com" }
];

const CreditDrawer = props => (
  <CustomDrawer title="About" {...props}>
    <ProjectLogo
      projectName={PROJECT_NAME}
      projectTagline={PROJECT_TAG_LINE}
      logoSize={45}
      projectNameFontSize={24}
      projectTaglineFontSize={12}
    />
    <div style={{ marginTop: 16, marginLeft: 4 }}>
      <p>
        <Typography.Text>
          UTL are the shift handover reports that are produced at a rate of 1000
          a day or 365,000 a year and are largely not integrated to other
          digital systems. Each report contains critical shift information and
          enable the transparency of what is going on at site. In fact, UTL is
          the best source of tracking unpermitted work at site.
        </Typography.Text>
      </p>
      <p>
        <Typography.Text>
          We have been working with our colleagues in the I&L space to help
          augment our discipline leads, managers and operators to leverage our
          NLP technology in Salvus to highlight key areas of interest within the
          UTL reports.
        </Typography.Text>
      </p>
      <p>
        <Typography.Text>
          Through this POC we can increase performance and reduce the likelihood
          that someone could miss a key piece of information relevant to them
          which could lead to a safety incident if not identified. Because UTL
          is mostly freeform text we see this as a great opportunity to use NLP
          for structuring the data in a way that can be leveraged by our users
          to keep our frontlines safe. Our plan is to complete a POC of this NLP
          model this year, with the following objectives: - Prove that we can
          find a way to automatically read the UTL reports and process them as
          they come in (extract user persona related information - ex: lifeboat
          information) - Prove we can work with our discipline leads to train
          NLP models that identify and alerts key HSSE information that need
          investigation (automation of the AI/learning-loop technology)
        </Typography.Text>
      </p>
      <p>
        <Typography.Text>
          Next, we are looking to Pilot the POC functionality for the GoM. This
          pilot will help us to prove the value and decide on the best ways to
          present the UTL insights (leading safety indicators) and LFI&rsquo;s
          to our key user personas (discipline leads, operators, managers) and
          be immediately actionable
        </Typography.Text>
      </p>
      <Collapse defaultActiveKey={["0", "1"]}>
        <Collapse.Panel header="Team" key="0">
          <Descriptions column={1}>
            {TEAM.map(team => (
              <Descriptions.Item key={team.email} label={<b>{team.name}</b>}>
                <a href={`mailto: ${team.email}`}>{team.email}</a>
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Collapse.Panel>
        <Collapse.Panel header="Contributors" key="1">
          <Descriptions column={1}>
            {CONTRIBUTORS.map(contributor => (
              <Descriptions.Item
                key={contributor.email}
                label={<b>{contributor.name}</b>}
              >
                <a href={`mailto: ${contributor.email}`}>{contributor.email}</a>
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Collapse.Panel>
      </Collapse>
      <p style={{ textAlign: "center", marginTop: 12 }}>
        <Typography.Text type="secondary">
          © 2020. Shell. All Rights Reserved
        </Typography.Text>
      </p>
    </div>
  </CustomDrawer>
);

export default CreditDrawer;

import {
  DeleteOutlined,
  PlusOutlined,
  SafetyCertificateOutlined
} from "@ant-design/icons";
import { Button, Col, Divider, Input, Popconfirm, Row, Table } from "antd";
import { startCase } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import ComponentLoader from "../../../components/ComponentLoader";
import LayoutContext from "../../../context/LayoutContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  useApi,
  userTenantsEndpoint,
  usersEndpoint
} from "../../API";
import { useAuthorization } from "../../Auth/hooks";
import AddUsersForm from "./AddUsersForm";
import SystemRolesForm from "./SystemRolesForm";
import TenantRolesForm from "./TenantRolesForm";
import UserForm from "./UserForm";

const { Search } = Input;

const Content = () => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const {
    isActiveTenantGlobal,
    activeTenantKey,
    hasSecurityRoles,
    adminRoles
  } = useAuthorization();
  const [data, setData] = useState();
  const [search, setSearch] = useState("");

  const [formUserInstructions, setFormUserInstructions] = useState({});
  const [formSystemRolesInstructions, setFormSystemRolesInstructions] =
    useState({});
  const [formTenantRolesInstructions, setFormTenantRolesInstructions] =
    useState({});
  const [formAddUsersInstructions, setFormAddUsersInstructions] = useState({});

  const canManage = useMemo(
    () => hasSecurityRoles(adminRoles.users),
    [adminRoles.users, hasSecurityRoles]
  );

  const {
    request: getUsers,
    loading: loadingUsers,
    error: errorGettingUsers
  } = useApi(usersEndpoint, RequestMethodType.GET, DefaultRequestConfig, false);

  const { request: removeUserTenant } = useApi(
    userTenantsEndpoint,
    RequestMethodType.DELETE,
    DefaultRequestConfig,
    false
  );

  const fetchData = useCallback(async () => {
    const response = await getUsers({
      url: isActiveTenantGlobal ? usersEndpoint : userTenantsEndpoint
    });
    setData(response.result.hits.hits || []);
  }, [getUsers, isActiveTenantGlobal]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRemoveFromTenant = useCallback(
    async sourceData => {
      try {
        const id = sourceData?._id;

        await removeUserTenant({
          url: `${userTenantsEndpoint}/${id}`
        });

        notify(notificationTypes.success, {
          message: "User removed from Tenant",
          description: `${sourceData?._source?.firstName} ${
            sourceData?._source?.lastName
          } was successfully removed from ${startCase(activeTenantKey)}`
        });

        setFormAddUsersInstructions({});
        fetchData();
      } catch (err) {
        notify(notificationTypes.error, {
          message: `Unable to remove ${sourceData?._source?.firstName} ${
            sourceData?._source?.lastName
          } from ${startCase(activeTenantKey)}`,
          description: err?.response?.data?.message || err.message || ""
        });
      }
    },
    [activeTenantKey, fetchData, notificationTypes, notify, removeUserTenant]
  );

  const columns = useMemo(() => {
    if (isActiveTenantGlobal) {
      return [
        {
          title: "User",
          key: "user",
          width: 275,
          defaultSortOrder: "ascend",
          sorter: (a, b) =>
            a?._source?.lastName?.localeCompare(b?._source?.lastName),
          render: record => (
            <div key="email" className="column-paragraph-ellipsis level-2">
              {record?._source?.firstName} {record?._source?.lastName}
            </div>
          )
        },
        {
          title: "User ID",
          key: "userId",
          width: 150,
          sorter: (a, b) =>
            a?._source?.userId?.localeCompare(b?._source?.userId),
          render: record => (
            <div>
              {record?._source?.userId ? (
                record?._source?.userId
              ) : (
                <em>never logged In</em>
              )}
            </div>
          )
        },
        {
          title: "Email",
          key: "email",
          width: 275,
          sorter: (a, b) => a?._source?.email?.localeCompare(b?._source?.email),
          render: record => (
            <div key="email" className="column-paragraph-ellipsis level-2">
              {record?._source?.email}
            </div>
          )
        },
        {
          title: "System Roles",
          key: "systemRoles",
          render: record => (
            <div key="systemrole">
              {record?._source?.systemRoles
                .map(item => startCase(item))
                .join(", ")}
            </div>
          )
        },
        {
          title: "Tenants",
          key: "tenants",
          render: record => (
            <div key="tenant">
              {record?._source?.tenants
                ?.map(item => startCase(item))
                .join(", ")}
            </div>
          )
        },
        {
          title: "Actions",
          className: "actions",
          width: 200,
          render: record => (
            <Button
              type="link"
              style={{ marginRight: "4px", align: "flex-start" }}
              disabled={!canManage}
              onClick={() => {
                setFormSystemRolesInstructions({
                  open: true,
                  onSaveAction: () => {
                    fetchData();
                    setFormSystemRolesInstructions({});
                  },
                  data: record
                });
              }}
              icon={<SafetyCertificateOutlined />}
            >
              Assign System Role(s)
            </Button>
          )
        }
      ];
    }

    return [
      {
        title: "User",
        key: "user",
        width: 275,
        defaultSortOrder: "ascend",
        sorter: (a, b) =>
          a?._source?.lastName?.localeCompare(b?._source?.lastName),
        render: record => (
          <div key="email" className="column-paragraph-ellipsis level-2">
            {record?._source?.firstName} {record?._source?.lastName}
          </div>
        )
      },
      {
        title: "User ID",
        key: "userId",
        width: 150,
        sorter: (a, b) => a?._source?.userId?.localeCompare(b?._source?.userId),
        render: record => (
          <div>
            {record?._source?.userId ? (
              record?._source?.userId
            ) : (
              <em>never logged In</em>
            )}
          </div>
        )
      },
      {
        title: "Email",
        key: "email",
        width: 275,
        sorter: (a, b) => a?._source?.email?.localeCompare(b?._source?.email),
        render: record => (
          <div key="email" className="column-paragraph-ellipsis level-2">
            {record?._source?.email}
          </div>
        )
      },
      {
        title: "Roles",
        key: "roles",
        render: record => (
          <div key="role">
            {record?._source?.tenantRoles?.[`${activeTenantKey}`]
              ?.map(item => startCase(item))
              .join(", ")}
          </div>
        )
      },
      {
        title: "Actions",
        className: "actions",
        width: 350,
        render: record => (
          <div>
            <Button
              type="link"
              style={{ marginRight: "4px", align: "flex-start" }}
              disabled={!canManage}
              onClick={() => {
                setFormTenantRolesInstructions({
                  open: true,
                  onSaveAction: () => {
                    fetchData();
                    setFormTenantRolesInstructions({});
                  },
                  data: record
                });
              }}
              icon={<SafetyCertificateOutlined />}
            >
              Assign Role(s)
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title={`Remove ${record._source.firstName} ${
                record._source.lastName
              } from the ${startCase(activeTenantKey)}?`}
              onConfirm={async () => handleRemoveFromTenant(record)}
            >
              <Button
                icon={<DeleteOutlined />}
                type="link"
                danger
                disabled={!canManage}
              >
                Remove From Tenant
              </Button>
            </Popconfirm>
          </div>
        )
      }
    ];
  }, [
    activeTenantKey,
    canManage,
    fetchData,
    handleRemoveFromTenant,
    isActiveTenantGlobal
  ]);

  if (errorGettingUsers) {
    return (
      <ComponentLoader
        hasErrors={!!errorGettingUsers}
        errorComponent="Unable to retrieve the data at this time"
      />
    );
  }

  return (
    <>
      <Row key="row-actions" gutter={[5, 5]} className="top-filter-row">
        {data?.length ? (
          <Col flex="auto">
            <Search
              allowClear
              placeholder="Search by First Name, Last Name, or Email..."
              onSearch={value => setSearch(value)}
            />
          </Col>
        ) : null}
        <Col>
          {isActiveTenantGlobal ? (
            <Button
              type="primary"
              disabled={!canManage}
              icon={<PlusOutlined />}
              style={{
                marginLeft: "auto"
              }}
              onClick={() => {
                setFormUserInstructions({
                  open: true,
                  onSaveAction: () => {
                    fetchData();
                    setFormUserInstructions({});
                  }
                });
              }}
            >
              Add User
            </Button>
          ) : (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled={!canManage}
              style={{
                marginLeft: "auto"
              }}
              onClick={() => {
                setFormAddUsersInstructions({
                  open: true,
                  onSaveAction: () => {
                    fetchData();
                    setFormAddUsersInstructions({});
                  }
                });
              }}
            >
              Add User to Tenant
            </Button>
          )}
        </Col>
      </Row>

      <Table
        className="table align-top"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          showTotal: total => `Total ${total} items`,
          hideOnSinglePage: true
        }}
        columns={columns}
        dataSource={data?.filter(
          value =>
            !search ||
            [
              value?._source?.firstName?.toLowerCase(),
              value?._source?.lastName?.toLowerCase(),
              value?._source?.email.toLowerCase()
            ].some(str => str?.includes(search.toLowerCase()))
        )}
        rowKey={item => item._id}
        scroll={{ x: true }}
        loading={loadingUsers}
      />

      <UserForm
        open={formUserInstructions?.open}
        onCancel={() => setFormUserInstructions({})}
        onSave={formUserInstructions?.onSaveAction}
      />

      <SystemRolesForm
        open={formSystemRolesInstructions?.open}
        onCancel={() => setFormSystemRolesInstructions({})}
        onSave={formSystemRolesInstructions?.onSaveAction}
        data={formSystemRolesInstructions?.data}
      />

      <TenantRolesForm
        open={formTenantRolesInstructions?.open}
        onCancel={() => setFormTenantRolesInstructions({})}
        onSave={formTenantRolesInstructions?.onSaveAction}
        data={formTenantRolesInstructions?.data}
      />

      <AddUsersForm
        open={formAddUsersInstructions?.open}
        onCancel={() => setFormAddUsersInstructions({})}
        onSave={formAddUsersInstructions?.onSaveAction}
        data={formAddUsersInstructions?.data}
      />
    </>
  );
};

export default Content;

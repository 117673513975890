export const PROJECT_NAME = "SALVUS";
export const PROJECT_TAG_LINE = "Investigate & Learn";
export const PROJECT_VERSION = process.env.REACT_APP_VERSION || "1.0.0";
export const PROJECT_ENV = process.env.REACT_APP_ENVIRONMENT || "dev";

export const SERVICES = {
  API:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_ENDPOINT
      : "http://localhost:4000",
  KIBANA:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_KIBANA_URL
      : "http://localhost:5601",
  SYSTEM:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SYSTEM_ENDPOINT
      : "http://localhost:7076",
  INVESTIGATE:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_INVESTIGATE_ENDPOINT
      : "http://localhost:7077",
  AUTH:
    process.env.NODE_ENV === "production"
      ? `${
          process.env.REACT_APP_SYSTEM_ENDPOINT || "http://localhost:7076"
        }/api/authz`
      : "http://localhost:7076/api/authz"
};

const PING_ID_AUTHORITY =
  process.env.REACT_APP_PINGID_AUTHORITY || "https://sso-dev.shell.com";

export const PINGID = {
  config_name: process.env.REACT_APP_PINGID_CONFIG_NAME || "salvus",
  client_id:
    process.env.REACT_APP_PINGID_CLIENT_ID ||
    "f1627893764d19cb89e99b05afe2d36c",
  redirect_uri:
    process.env.REACT_APP_PINGID_REDIRECT_URI ||
    `${window.location.origin}/auth/callback`,
  post_logout_redirect_uri:
    process.env.REACT_APP_PINGID_LOGOUT_REDIRECT_URI ||
    `${window.location.origin}`,
  silent_redirect_uri:
    process.env.REACT_APP_PINGID_LOGOUT_REDIRECT_URI ||
    `${window.location.origin}/auth/callback`,
  response_type: process.env.REACT_APP_PINGID_RESPONSE_TYPE || "code",
  scope: process.env.REACT_APP_PINGID_SCOPE || "openid profile",
  authority: PING_ID_AUTHORITY,
  automaticSilentRenew: true,
  response_mode: null,

  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  metadata: {
    issuer: `${PING_ID_AUTHORITY}`,
    authorization_endpoint: `${PING_ID_AUTHORITY}/as/authorization.oauth2`,
    token_endpoint: `${PING_ID_AUTHORITY}/as/token.oauth2`,
    revocation_endpoint: `${PING_ID_AUTHORITY}/as/revoke_token.oauth2`,
    userinfo_endpoint: `${PING_ID_AUTHORITY}/idp/userinfo.openid`,
    introspection_endpoint: `${PING_ID_AUTHORITY}/as/introspect.oauth2`,
    jwks_uri: `${PING_ID_AUTHORITY}/pf/JWKS`,
    registration_endpoint: `${PING_ID_AUTHORITY}/as/clients.oauth2`,
    ping_revoked_sris_endpoint: `${PING_ID_AUTHORITY}/pf-ws/rest/sessionMgmt/revokedSris`,
    ping_end_session_endpoint: `${PING_ID_AUTHORITY}/idp/startSLO.ping`,
    device_authorization_endpoint: `${PING_ID_AUTHORITY}/as/device_authz.oauth2`
  }
};

export default {
  PROJECT_NAME,
  SERVICES
};

import PropTypes from "prop-types";
import React from "react";
import { animated, useSpring } from "react-spring";
import ShellLogo from "../ShellLogo";

import "./index.less";

export default function ProjectLogo({
  projectName,
  animate,
  logoSize,
  projectTagline,
  projectNameFontSize,
  projectTaglineFontSize
}) {
  const [textProps] = useSpring(() => ({
    shellTransform: [0, 0],
    projectTransform: [0, 0],
    shellTextColor: "#d42e12",
    projectNameText: "#003882",
    from: animate
      ? {
          shellTextColor: "black",
          projectNameTextColor: "grey",
          shellTransform: [0, -50],
          projectTransform: [0, 50]
        }
      : {
          shellTextColor: "#d42e12",
          projectNameTextColor: "#003882",
          shellTransform: [0, 0],
          projectTransform: [0, 0]
        },
    config: {
      mass: 3,
      tension: 200,
      friction: 25
    }
  }));

  return (
    <div className="logo-container">
      <ShellLogo animate={animate} width={logoSize} height={logoSize} />

      <div className="logo">
        <animated.h2
          style={{
            fontSize: projectNameFontSize,
            transform: textProps.projectTransform.interpolate(
              (x, y) => `translate(${x}px, ${y}px)`
            )
          }}
        >
          {projectName}
        </animated.h2>
        <animated.h5
          style={{
            fontSize: projectTaglineFontSize,
            transform: textProps.shellTransform.interpolate(
              (x, y) => `translate(${x}px, ${y}px)`
            )
          }}
        >
          {projectTagline}
        </animated.h5>
      </div>
    </div>
  );
}

ProjectLogo.propTypes = {
  projectName: PropTypes.string,
  projectTagline: PropTypes.string,
  animate: PropTypes.bool,
  logoSize: PropTypes.any,
  projectNameFontSize: PropTypes.any,
  projectTaglineFontSize: PropTypes.any
};

ProjectLogo.defaultProps = {
  projectName: "",
  projectTagline: "",
  animate: false,
  logoSize: 96,
  projectNameFontSize: "3rem",
  projectTaglineFontSize: "1.5rem"
};

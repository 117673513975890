import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { AuthMessage } from "../../modules/Auth/components";

const AuthCallback = ({ history }) => {
  useEffect(() => {
    history.go(0);
  }, [history]);

  return <AuthMessage message="Successfully Authenticated" />;
};

AuthCallback.propTypes = {
  history: PropTypes.object.isRequired
};

export default AuthCallback;

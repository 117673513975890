import {
  ArrowUpOutlined,
  CaretLeftOutlined,
  CaretRightOutlined
} from "@ant-design/icons";
import { Button, FloatButton, Layout } from "antd";
import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import PageHeader from "../../components/PageHeader";
import LayoutContext from "../../context/LayoutContext";

import "./index.less";

const { Sider, Content } = Layout;
const { BackTop } = FloatButton;
const defaultClassName = "page-layout";

const PageLayout = ({ side, header, children, className, props }) => {
  const { currentTheme } = useContext(LayoutContext);
  const [collapsed, setCollapsed] = useState(false);
  const hasSiderComponent = !!(side?.component || side?.component?.length);
  const hasHeader = !isEmpty(header);

  const renderContent = useCallback(
    () => (
      <>
        <BackTop
          style={{ right: "5px", bottom: "10px" }}
          target={() => document.getElementById("scrollable_content")}
        >
          <Button size="large" shape="circle" icon={<ArrowUpOutlined />} />
        </BackTop>

        <Layout
          className={`${defaultClassName}-content`}
          id="scrollable_content"
        >
          <Content>
            {hasHeader && (
              <PageHeader
                title={header?.title}
                description={header?.description}
                showDivider={header?.showDivider || false}
                actions={header?.actions}
                onBack={header?.onBack}
              />
            )}
            {children}
          </Content>
        </Layout>
      </>
    ),
    [children, hasHeader, header]
  );

  const renderSide = useCallback(
    () => (
      <Sider
        collapsible
        collapsed={collapsed}
        collapsedWidth={15}
        theme={currentTheme}
        className={classNames(`${defaultClassName}-side`, {
          "side-sm-expanded": side?.size === "small" && !collapsed,
          "side-lg-expanded": side?.size === "large" && !collapsed,
          "side-collapsed": collapsed
        })}
        {...side?.props}
      >
        <Button
          size="small"
          shape="circle"
          className={classNames(`${defaultClassName}-side-trigger`)}
          icon={collapsed ? <CaretRightOutlined /> : <CaretLeftOutlined />}
          onClick={() => {
            setCollapsed(!collapsed);
            if (collapsed && side?.onCollapse) {
              side?.onExpand();
            }
            if (!collapsed && side?.onExpand) {
              side?.onCollapse();
            }
          }}
        />
        <div className={classNames(`${defaultClassName}-side-content`)}>
          {side.component}
        </div>
      </Sider>
    ),
    [collapsed, currentTheme, side]
  );

  return (
    <Layout className={classNames(defaultClassName, className)} {...props}>
      {hasSiderComponent && side?.placement === "left" && renderSide()}
      {renderContent()}
      {hasSiderComponent && side?.placement === "right" && renderSide()}
    </Layout>
  );
};

PageLayout.propTypes = {
  className: PropTypes.string,
  props: PropTypes.object,
  children: PropTypes.node,
  side: PropTypes.shape({
    placement: PropTypes.oneOf(["left", "right"]),
    size: PropTypes.oneOf(["", "default", "small", "large"]),
    component: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    onCollapse: PropTypes.func,
    onExpand: PropTypes.func,
    props: PropTypes.object
  }),
  header: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    showDivider: PropTypes.bool,
    onBack: PropTypes.func,
    actions: PropTypes.node
  })
};

PageLayout.defaultProps = {
  className: PropTypes.string,
  props: null,
  children: null,
  side: null,
  header: null
};

export default PageLayout;

import { Tabs } from "antd";
import React, { useState } from "react";
import MessageComponent from "../../components/MessageComponent";
import SettingsSideMenu from "../../components/SideMenus/SettingsSideMenu";
import PageLayout from "../../layouts/PageLayout";
import { useAuthorization } from "../../modules/Auth/hooks";
import DailyDiscoveriesReportLogsContent from "../../modules/Investigate/DiscoveriesReportLogs/Content";
import InvestigateETLLogsContent from "../../modules/Investigate/InvestigateETLLogs/Content";

import "./index.less";

const LogsPage = () => {
  const { isActiveTenantGlobal } = useAuthorization();

  const [activeTab, setActiveTab] = useState("1");

  const renderErrorMessage = () => (
    <MessageComponent
      title="Sorry!"
      message="You must select a Tenant before proceeding"
      type="danger"
    />
  );

  return (
    <PageLayout
      className="logs-page"
      side={{
        placement: "left",
        component: <SettingsSideMenu />
      }}
      header={{
        title: "Logs",
        description: "View and manage log files."
      }}
    >
      <Tabs
        activeKey={activeTab}
        style={{ marginBottom: "30px" }}
        onChange={setActiveTab}
        items={[
          {
            key: "1",
            label: "Investigate ETL",
            children: isActiveTenantGlobal ? (
              renderErrorMessage()
            ) : (
              <InvestigateETLLogsContent />
            )
          },
          {
            key: "2",
            label: "Daily Discoveries Report",
            children: isActiveTenantGlobal ? (
              renderErrorMessage()
            ) : (
              <DailyDiscoveriesReportLogsContent />
            )
          }
        ]}
      />
    </PageLayout>
  );
};

export default LogsPage;

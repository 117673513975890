import { ShareAltOutlined } from "@ant-design/icons";
import { serializeSearchParams } from "@salvus/shared/utils";
import { Button, Typography } from "antd";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo } from "react";
import LayoutContext from "../../context/LayoutContext";
import { useAuthorization } from "../../modules/Auth/hooks";

import "./index.less";

const defaultClassPrefix = "side-filters";

const SideFilters = ({
  title,
  filterContext,
  onClear,
  children,
  includeActiveTenantKey = true
}) => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const showShare = !isEmpty(filterContext);
  const { activeTenantKey } = useAuthorization();

  const serializedParams = useCallback(
    () =>
      serializeSearchParams({
        ...(includeActiveTenantKey ? { tenantKey: activeTenantKey } : null),
        filters: {
          ...filterContext
        }
      }),
    [activeTenantKey, filterContext, includeActiveTenantKey]
  );

  const handleOnShareFilters = useCallback(() => {
    try {
      const serializedParameters = serializedParams();
      const shareUrl = `${window.location.origin}${
        window.location.pathname
      }?${serializedParams()}`;

      if (navigator.clipboard) {
        navigator.clipboard.writeText(shareUrl);

        notify(notificationTypes.success, {
          message: "Filter has been copied to your clipboard",
          description:
            "To share this filter, simply paste (Ctrl + V) to receiver"
        });
      } else {
        // eslint-disable-next-line
        window.prompt(
          "Copy to clipboard: Ctrl+C, Enter",
          `${window.location.origin}/result/${serializedParameters}`
        );
      }
    } catch (err) {
      notify(notificationTypes.error, {
        message: "Unable to share this filter",
        description: err.message || ""
      });
    }
  }, [notificationTypes, notify, serializedParams]);

  const renderHeader = useMemo(
    () => (
      <div className={`${defaultClassPrefix}-header`}>
        <Typography.Title level={5}>{title}</Typography.Title>
        {showShare && (
          <Button
            type="link"
            icon={<ShareAltOutlined />}
            onClick={handleOnShareFilters}
          />
        )}
      </div>
    ),
    [handleOnShareFilters, showShare, title]
  );

  const renderFooter = useMemo(
    () => (
      <div className={`${defaultClassPrefix}-footer`}>
        <Button type="primary" style={{ width: "100%" }} onClick={onClear}>
          Reset
        </Button>
      </div>
    ),
    [onClear]
  );

  return (
    <div className={`${defaultClassPrefix}-container`}>
      {renderHeader}
      <div className={`${defaultClassPrefix}-content`}>{children}</div>
      {renderFooter}
    </div>
  );
};

SideFilters.propTypes = {
  title: PropTypes.string,
  filterContext: PropTypes.object,
  onClear: PropTypes.func,
  children: PropTypes.node,
  includeActiveTenantKey: PropTypes.bool
};

SideFilters.defaultProps = {
  title: "Filter",
  filterContext: {},
  onClear: () => {},
  children: null,
  includeActiveTenantKey: true
};

export default SideFilters;

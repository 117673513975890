import {
  ExclamationCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Avatar, Layout, Menu, Popover, Typography } from "antd";
import React, { useContext, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ProjectLogo } from "../../components/CoreComponents";
import { PROJECT_NAME, PROJECT_TAG_LINE } from "../../config";
import AuthContext from "../../context/AuthContext";
import TenantContext from "../../context/TenantContext";
import CreditDrawer from "../../modules/System/components/CreditDrawer";
import TenantSelection from "../../modules/System/components/TenantSelection";
import { filterNonSecuredRoutes, topNavRouteList } from "../../routeList";

// const menuItem = route => {
//   const children =
//     !isEmpty(route.children) &&
//     route.children
//       .filter(childRoute => childRoute && !childRoute?.hidden)
//       .map(childRoute => menuItem(childRoute));

//   return {
//     key: route.key,
//     label: children?.length ? (
//       <span>{route.name}</span>
//     ) : (
//       <div style={{ display: "flex", justifyContent: "space-between" }}>
//         <Link to={() => ({ pathname: route.path })}>
//           <span>{route.name}</span>
//         </Link>
//       </div>
//     ),
//     children: !isEmpty(children) ? children : null,
//     ...(!isEmpty(children) ? { popupOffset: [0, -20] } : null),
//     ...(!isEmpty(children) ? { popupClassName: "main-menu-container" } : null)
//   };
// };

const Header = () => {
  const [creditVisible, setCreditVisible] = useState(false);
  const { currentUser, logout } = useContext(AuthContext);
  const { activeTenantKey } = useContext(TenantContext);

  const [open, setOpen] = useState(false);

  const location = useLocation();

  const handleOnOpenChange = isOpen => {
    setOpen(isOpen);
  };

  const selectedMenuItem = useMemo(
    () =>
      topNavRouteList.find(
        route => route.path === `/${location.pathname.split("/", 3)[1]}`
      ),
    [location.pathname]
  );

  const userMenuItems = useMemo(() => {
    const routeList = [
      {
        key: "user-menu-item-about",
        name: "About",
        icon: <ExclamationCircleOutlined style={{ fontSize: "16px" }} />,
        label: "About",
        onClick: () => {
          setCreditVisible(true);
        }
      },
      {
        key: "user-menu-item-profile",
        path: "/profile",
        name: "Profile",
        icon: <UserOutlined style={{ fontSize: "16px" }} />
      },
      {
        key: "user-menu-item-settings",
        path: "/settings",
        name: "Settings",
        icon: <SettingOutlined style={{ fontSize: "16px" }} />
      },
      {
        key: "user-menu-item-logout",
        label: "Logout",
        name: "Logout",
        icon: <LogoutOutlined style={{ fontSize: "16px" }} />,
        onClick: async () => {
          await logout();
        }
      }
    ];
    return filterNonSecuredRoutes(routeList, currentUser, activeTenantKey)?.map(
      route => ({
        key: route?.key,
        path: route?.path,
        ...(route?.icon ? { icon: route?.icon } : null),
        ...(route?.onClick
          ? { label: route?.name, onClick: route.onClick }
          : {
              label: (
                <Link
                  to={() => ({ pathname: route?.path })}
                  style={{ width: "90%" }}
                >
                  <span>{route?.name}</span>
                </Link>
              )
            })
      })
    );
  }, [currentUser, activeTenantKey, logout]);

  const topNavMenuItems = useMemo(
    () =>
      topNavRouteList.map(route => ({
        key: route?.key,
        path: route?.path,
        ...(route?.icon ? { icon: route?.icon } : null),
        ...(route?.onClick
          ? { label: route?.name, onClick: route.onClick }
          : {
              label: (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Link to={() => ({ pathname: route.path })}>
                    <span>{route.name}</span>
                  </Link>
                </div>
              )
            })
      })),
    []
  );

  const renderUserMenuTitleComponent = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "5px" }}>
        <Avatar icon={<UserOutlined />} />
      </div>
      <div style={{ flex: "1 1 0%", display: "flex", flexDirection: "column" }}>
        <Typography.Text style={{ fontSize: "14px" }}>
          {currentUser.displayName}
        </Typography.Text>
        <Typography.Text style={{ fontSize: "12px" }}>
          {currentUser.email}
        </Typography.Text>
      </div>
    </div>
  );

  return (
    <Layout.Header className="header-layout">
      <CreditDrawer
        open={creditVisible}
        onClose={() => {
          setCreditVisible(false);
        }}
        width="70vw"
      />
      <Link to="/">
        <ProjectLogo
          projectName={PROJECT_NAME}
          projectTagline={PROJECT_TAG_LINE}
          logoSize={45}
          projectNameFontSize={31}
          projectTaglineFontSize={12}
        />
      </Link>

      <div className="main-menu">
        <Menu
          key="Menu"
          mode="horizontal"
          selectedKeys={[selectedMenuItem?.path]}
          items={topNavMenuItems}
        />
      </div>

      <div className="tenant-selection-wrapper">
        <TenantSelection key="tenant-selection" />
      </div>

      <div className="user-menu">
        <Popover
          title={renderUserMenuTitleComponent}
          content={
            <Menu
              key="user-menu"
              items={userMenuItems}
              onClick={() => setOpen(false)}
            />
          }
          placement="bottomRight"
          trigger="hover"
          onOpenChange={handleOnOpenChange}
          open={open}
          overlayClassName="user-menu-container"
        >
          <Avatar
            className="user-menu-trigger"
            size="large"
            icon={<UserOutlined />}
          />
        </Popover>
      </div>
    </Layout.Header>
  );
};

export default Header;

import { Pie } from "@ant-design/plots";
import { startCase } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import ComponentLoader from "../../../components/ComponentLoader";
import CustomCard from "../../../components/CustomCard";
import TenantContext from "../../../context/TenantContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  analyticsEndpoint,
  useApi
} from "../../API";
import DashboardContext from "./Context";
import { createFilters } from "./utils";

const AssetsVisualizer = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();

  const { request: getAnalytics, loading: loadingAnalytics } = useApi(
    analyticsEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const { activeTenantAssets } = useContext(TenantContext);

  const {
    filterContext: { topics, dataProviders, assets, dates },
    isReady
  } = useContext(DashboardContext);

  const fetchData = useCallback(async () => {
    const payload = {
      requests: [
        {
          type: "count-of-assets",
          dimensions: ["assets"],
          ...createFilters({
            topics,
            dataProviders,
            assets,
            dates
          })
        }
      ]
    };

    const response = await getAnalytics({ data: payload });

    if (response?.result?.[0]?.errors) {
      setError(true);
      return;
    }

    setData(response?.result?.[0]?.results?.data);
  }, [topics, dataProviders, assets, dates, getAnalytics]);

  useEffect(() => {
    const asyncFetchData = async () => {
      await fetchData();
    };

    if (isReady) {
      asyncFetchData();
    }
  }, [fetchData, isReady]);

  const GraphComponent = useMemo(
    () => (
      <Pie
        {...{
          data,
          angleField: "value",
          colorField: "key",
          radius: 0.9,
          label: {
            type: "inner",
            offset: "-8%",
            content: ({ key, value }) =>
              activeTenantAssets.length && key
                ? `${
                    activeTenantAssets.find(t => t.key === key).name
                  } - ${value}`
                : `${startCase(key)} - ${value}`,
            style: {
              fontSize: 10
            }
          },
          pieStyle: {
            fillOpacity: 0.5,
            lineWidth: 1,
            strokeOpacity: 0.7,
            shadowColor: "black",
            shadowBlur: 10,
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            cursor: "pointer"
          },
          interactions: [
            {
              type: "element-selected"
            },
            {
              type: "element-active"
            },
            {
              type: "pie-legend-active"
            }
          ],
          tooltip: {
            formatter: ({ key, value }) => ({
              name:
                activeTenantAssets.length && key
                  ? activeTenantAssets.find(t => t.key === key).name
                  : startCase(key),
              value
            })
          },
          legend: false,
          animation: {
            appear: {
              animation: "scale-in-y", // Effects of the first animation
              duration: 500 // Duration of the first animation
            }
          }
        }}
        style={{ height: "100%" }}
      />
    ),
    [data, activeTenantAssets]
  );

  return (
    <CustomCard
      header={{
        title: "Assets",
        description: "Number of discoveries per Asset"
      }}
      className="assets-visualizer"
    >
      <ComponentLoader
        isLoading={loadingAnalytics || !data}
        hasErrors={error}
        errorComponent="Unable to retrieve the data at this time"
        hasNoData={data != null && !data?.length}
        noDataComponent="No assets to show"
      >
        {GraphComponent}
      </ComponentLoader>
    </CustomCard>
  );
};

export default AssetsVisualizer;

import { Result } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: null
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {}

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <Result
          status="500"
          title="Something went wrong with SALVUS"
          subTitle="This issue is recorded and STW Team will investigate shortly. You can also help us by provide some feedback on how the application crashed"
        />
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { useMemo } from "react";
import MessageComponent from "../../components/MessageComponent";
import PageLayout from "../../layouts/PageLayout";
import { useAuthorization } from "../../modules/Auth/hooks";
import Content from "../../modules/Investigate/Dashboard/Content";
import { DashboardContextProvider } from "../../modules/Investigate/Dashboard/Context";
import Filters from "../../modules/Investigate/Dashboard/Filters";

import "./index.less";

const DashboardPage = () => {
  const {
    hasSecurityRoles,
    isActiveTenantNotGlobal,
    isActiveTenantGlobal,
    adminRoles,
    readerRoles
  } = useAuthorization();

  const canView = useMemo(
    () =>
      hasSecurityRoles([...adminRoles.dashboard, ...readerRoles.dashboard]) &&
      isActiveTenantNotGlobal,
    [
      adminRoles.dashboard,
      hasSecurityRoles,
      isActiveTenantNotGlobal,
      readerRoles.dashboard
    ]
  );

  const renderContent = useMemo(() => {
    if (!canView) {
      if (isActiveTenantGlobal) {
        return (
          <MessageComponent
            title="Sorry!"
            message="You must select a Tenant before proceeding"
            type="danger"
          />
        );
      }

      return (
        <MessageComponent
          title="Hey! Guess What"
          message="You can't access this Dashboard, however in the near future, we might just start showing you insights for learning. Stay Tuned!"
        />
      );
    }

    return <Content />;
  }, [canView, isActiveTenantGlobal]);

  return (
    <DashboardContextProvider>
      <PageLayout
        className="dashboard-page"
        side={{
          ...(canView
            ? {
                placement: "left",
                component: <Filters />,
                size: "large"
              }
            : {})
        }}
      >
        {renderContent}
      </PageLayout>
    </DashboardContextProvider>
  );
};

export default DashboardPage;

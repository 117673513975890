import { Drawer, Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";

const defaultClassName = "custom-drawer";

const CustomDrawer = ({ title, open, width, onClose, children, ...rest }) => (
  <Drawer
    open={open}
    className={defaultClassName}
    title={
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        {title}
      </Typography.Title>
    }
    onClose={onClose}
    width={width}
    {...rest}
  >
    {children}
  </Drawer>
);

CustomDrawer.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
};

CustomDrawer.defaultProps = {
  title: "",
  width: "55vw",
  open: false,
  onClose: () => {},
  children: null
};

export default CustomDrawer;

import { Button, Form, Input, Select, Typography } from "antd";
import { omit } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import CustomSelect from "../../../components/CustomSelect";

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { flex: 1 }
};

const { Option } = Select;

const AssetForm = ({ open, onCancel, onSave, data }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [status, setStatus] = useState("inactive");
  const [isOnboarded, setIsOnboarded] = useState(false);

  const { resetFields, setFieldsValue } = form;
  const modalTitle = data ? `Edit: ${data.name}` : `Add Tenant`;

  const fetchData = useCallback(() => {
    resetFields();
    setFieldsValue(data);

    setFieldsValue(omit(data, ["isOnboarded", "status"]));
    setIsOnboarded(data?.isOnboarded);
    setStatus(data?.status);
  }, [data, setFieldsValue, resetFields]);

  useEffect(() => {
    if (!form || !data || !open) {
      return;
    }

    fetchData();
  }, [data, open, form, fetchData]);

  const handleIsOnboardedChange = useCallback(value => {
    setIsDirty(true);
    setIsOnboarded(value);
  }, []);

  const handleStatusChange = useCallback(value => {
    setIsDirty(true);
    setStatus(value);
  }, []);

  const handleCleanup = useCallback(async () => {
    resetFields();
    setFieldsValue({});
    setIsOnboarded(false);
    setStatus("inactive");
    setIsDirty(false);
    setSaving(false);
  }, [resetFields, setFieldsValue]);

  const handleOnCancel = useCallback(async () => {
    handleCleanup();
    onCancel();
  }, [onCancel, handleCleanup]);

  const handleOnSave = useCallback(
    async values => {
      setSaving(true);
      const payload = {
        ...values,
        isOnboarded,
        status
      };
      onSave(data, payload);
      handleCleanup();
    },
    [isOnboarded, status, onSave, data, handleCleanup]
  );

  return !open ? null : (
    <CustomModal
      key="tenant-modal"
      title={modalTitle}
      open={open}
      onCancel={handleOnCancel}
      footer={[
        <div style={{ flex: "1 1 0%" }} key="actions">
          <Button key="cancel" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={saving}
            onClick={() => form.submit()}
            disabled={saving || !isDirty}
          >
            Save
          </Button>
        </div>
      ]}
    >
      <Form
        key="form"
        onFinish={handleOnSave}
        form={form}
        {...formItemLayout}
        className="form-slim"
      >
        {data && (
          <Form.Item key="key" name="key" label="Key">
            <Typography.Text className="ant-form-text" type="secondary">
              {data.key}
            </Typography.Text>
          </Form.Item>
        )}

        <Form.Item
          key="name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a name"
            }
          ]}
          label="Name"
          onChange={() => setIsDirty(true)}
        >
          <Input key="nameinput" />
        </Form.Item>

        <Form.Item
          key="lookupExp"
          name="lookupExp"
          rules={[
            {
              required: true,
              message: "Please input a lookup expression"
            }
          ]}
          label="Lookup Exp"
          onChange={() => setIsDirty(true)}
        >
          <Input key="lookupExpinput" />
        </Form.Item>

        <Form.Item key="isOnboarded" name="isOnboarded" label="Onboarded">
          <div>
            <CustomSelect
              value={isOnboarded}
              placeholder="Has Onboarded?..."
              onChange={handleIsOnboardedChange}
              mode=""
            >
              {[
                { label: "Yes", value: true },
                { label: "No", value: false }
              ].map(i => (
                <Option key={i.value} value={i.value}>
                  {i.label}
                </Option>
              ))}
            </CustomSelect>
          </div>
        </Form.Item>

        <Form.Item key="status" name="status" label="Status">
          <div>
            <CustomSelect
              placeholder="Select status..."
              mode=""
              value={status}
              onChange={handleStatusChange}
            >
              {[
                { label: "Active", key: "active" },
                { label: "Inactive", key: "inactive" }
              ].map(i => (
                <Option key={i.key} value={i.key}>
                  {i.label}
                </Option>
              ))}
            </CustomSelect>
          </div>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

AssetForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  data: PropTypes.object
};

AssetForm.defaultProps = {
  open: false,
  onCancel: () => {},
  onSave: () => {},
  data: null
};

export default AssetForm;

import { Column } from "@ant-design/plots";
import { Pagination } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ComponentLoader from "../../../components/ComponentLoader";
import CustomCard from "../../../components/CustomCard";
import TenantContext from "../../../context/TenantContext";

import {
  analyticsEndpoint,
  DefaultRequestConfig,
  RequestMethodType,
  useApi
} from "../../API";
import { createFilters } from "../Dashboard/utils";

const defaultPageSize = 10;

const Visualizer = ({ topicKeyword }) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedKeywordData, setSelectedKeywordData] = useState();
  const { request: getAnalytics, loading: loadingAnalytics } = useApi(
    analyticsEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const { activeTenantTopics } = useContext(TenantContext);

  const setKeywordData = useCallback(async () => {
    setSelectedKeywordData(
      activeTenantTopics.find(k => k.key === topicKeyword)
    );
  }, [activeTenantTopics, topicKeyword]);

  const fetchData = useCallback(async () => {
    const analyticsRequest = {
      requests: [
        {
          type: "count-of-keywords",
          dimensions: ["keywords"],
          ...createFilters({ topics: topicKeyword })
        }
      ]
    };

    const response = await getAnalytics({ data: analyticsRequest });

    if (response?.result?.[0]?.errors) {
      setError(true);
      return;
    }

    setData(response?.result?.[0]?.results?.data);
  }, [getAnalytics, topicKeyword]);

  useEffect(() => {
    const asyncFetchData = async () => {
      await fetchData();
    };

    if (topicKeyword) {
      asyncFetchData();
    }
  }, [fetchData, topicKeyword]);

  useEffect(() => {
    if (topicKeyword && activeTenantTopics) {
      setKeywordData();
    }
  }, [activeTenantTopics, topicKeyword, setKeywordData]);

  const config = {
    data: data?.slice(
      (pageNumber - 1) * defaultPageSize,
      pageNumber * defaultPageSize
    ),
    appendPadding: 5,
    renderer: "svg",
    xField: "key",
    yField: "value",
    height: 175,
    label: {
      position: "top",
      offset: -2,
      style: {
        fill: "#000000",
        opacity: 0.6
      }
    },
    animation: {
      appear: {
        animation: "scale-in-y", // Effects of the first animation
        duration: 500 // Duration of the first animation
      }
    },

    columnStyle: {
      radius: [3, 3, 0, 0]
    },

    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
        autoEllipsis: false
      }
    },

    meta: {
      value: {
        alias: "count"
      }
    },

    legend: false,

    color: selectedKeywordData?.color
  };

  const renderPagination = () => {
    if (!data?.length) {
      return null;
    }

    return (
      <Pagination
        key="pagination"
        size="small"
        total={data?.length}
        defaultCurrent={1}
        defaultPageSize={defaultPageSize}
        onChange={value => setPageNumber(value)}
        showSizeChanger={false}
        hideOnSinglePage
      />
    );
  };

  return (
    <CustomCard
      header={{
        title: "Keywords",
        description:
          "This graphs shows the number of times each keyword was found in the data"
      }}
      footer={{
        actions: [renderPagination()]
      }}
      className="visualizer"
    >
      <ComponentLoader
        isLoading={loadingAnalytics || !data}
        hasErrors={error}
        errorComponent="Unable to retrieve the data at this time"
        hasNoData={data && !data?.length}
        noDataComponent="No stats to show"
      >
        <Column {...config} style={{ height: "100%" }} />
      </ComponentLoader>
    </CustomCard>
  );
};

Visualizer.propTypes = {
  topicKeyword: PropTypes.string
};

Visualizer.defaultProps = {
  topicKeyword: null
};

export default Visualizer;

import { Card, Col, Row, Typography } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import "./index.less";

const defaultClassNamePrefix = "custom-card";

const CustomCardHeader = ({ title, description, extra }) => {
  const bordered = false;

  const renderTitle = () => {
    if (!title) {
      return null;
    }

    return React.isValidElement(title) ? (
      title
    ) : (
      <Typography.Text
        key="title"
        style={{
          fontSize: "14px",
          textTransform: "uppercase",
          fontWeight: "bold"
        }}
      >
        {title}
      </Typography.Text>
    );
  };

  const renderDescription = () => {
    if (!description) {
      return null;
    }

    return React.isValidElement(description) ? (
      description
    ) : (
      <Typography.Text
        key="description"
        type="secondary"
        style={{ fontSize: "12px" }}
      >
        {description}
      </Typography.Text>
    );
  };

  const renderExtra = () => {
    if (!extra) {
      return null;
    }

    return React.isValidElement(extra) ? (
      extra
    ) : (
      <Typography.Title key="-extra" level={5}>
        {extra}
      </Typography.Title>
    );
  };

  if (!title && !extra) {
    return null;
  }

  return (
    <Row
      className={classNames(`${defaultClassNamePrefix}-header`, {
        bordered: bordered === true
      })}
      justify="space-between"
      align="middle"
    >
      {(title || description) && (
        <Col className="header-title">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {renderTitle()}
            <div style={{ display: "flex", flexDirection: "row" }}>
              {renderDescription()}
            </div>
          </div>
        </Col>
      )}

      {extra && <Col className="header-extra">{renderExtra()}</Col>}
    </Row>
  );
};

CustomCardHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

CustomCardHeader.defaultProps = {
  title: null,
  description: null,
  extra: null
};

const CustomCardFooter = ({ actions }) => {
  const bordered = false;

  const renderActions = () => {
    if (!actions || !Array.isArray(actions)) {
      return [];
    }

    return actions.map((action, index) => <Col key={index}>{action}</Col>);
  };

  if (!actions || !actions?.length) {
    return null;
  }

  return (
    <Row
      className={classNames(`${defaultClassNamePrefix}-footer`, {
        bordered: bordered === true
      })}
      justify="space-around"
      align="bottom"
    >
      {renderActions()}
      {/* <Col className="footer-actions">{renderActions()}</Col> */}
    </Row>
  );
};

CustomCardFooter.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node)
};

CustomCardFooter.defaultProps = {
  actions: null
};

const CustomCard = ({ className, header, footer, children, props }) => (
  <Card className={classNames(defaultClassNamePrefix, className)} {...props}>
    <CustomCardHeader {...header} />

    <div className={`${defaultClassNamePrefix}-content`}>{children}</div>

    <CustomCardFooter {...footer} />
  </Card>
);

CustomCard.propTypes = {
  className: PropTypes.string,
  props: PropTypes.any,
  header: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  }),
  footer: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.node)
  }),
  children: PropTypes.node
};

CustomCard.defaultProps = {
  className: null,
  props: null,
  header: null,
  footer: null,
  children: null
};

export default CustomCard;

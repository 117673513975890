import {
  datesFromEpoch,
  epochFromDates,
  predefinedDates,
  predefinedRanges
} from "@salvus/shared/utils";
import { DatePicker, Typography } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import InfoTooltip from "../InfoTooltip";

import "./index.less";

const { RangePicker } = DatePicker;

const defaultClassNamePrefix = "custom-dates";

const CustomDates = ({
  className,
  label,
  info,
  direction,
  labelProps,
  value,
  defaultValue,
  onChange,
  rangePickerProps
}) => {
  const handleOnChange = useCallback(
    async values => {
      const newDates = (values || []).map((dt, index) =>
        index === 0
          ? predefinedDates(dt).startOfDay
          : predefinedDates(dt).endOfDay
      );

      onChange(epochFromDates(newDates));
    },
    [onChange]
  );

  return (
    <div
      className={classNames(`${defaultClassNamePrefix}-container`, className, [
        `${defaultClassNamePrefix}-${direction}`
      ])}
    >
      {label && (
        <div className={`${defaultClassNamePrefix}-label`}>
          <Typography.Text {...labelProps}>{label}</Typography.Text>
          {info && <InfoTooltip info={info} />}
        </div>
      )}
      <RangePicker
        className={defaultClassNamePrefix}
        presets={predefinedRanges}
        onChange={handleOnChange}
        value={datesFromEpoch(value)}
        defaultValue={defaultValue}
        {...rangePickerProps}
        allowClear={false}
      />
    </div>
  );
};

CustomDates.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.string,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  labelProps: PropTypes.object,
  value: PropTypes.array,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
  rangePickerProps: PropTypes.object
};

CustomDates.defaultProps = {
  className: null,
  label: "Dates",
  info: null,
  direction: "vertical",
  labelProps: null,
  value: [],
  defaultValue: [],
  rangePickerProps: null,
  onChange: () => {}
};

export default CustomDates;

import { Layout } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import LayoutContext from "../../context/LayoutContext";
import { flattenedRouteList } from "../../routeList";
import Header from "./Header";

import "./index.less";

const { Content } = Layout;

const MainLayout = ({ children }) => {
  const { currentTheme } = useContext(LayoutContext);
  const location = useLocation();

  const selectedRoute = useMemo(
    () =>
      flattenedRouteList.find(
        route => route.path === `/${location.pathname.split("/", 3)[1]}`
      ),
    [location.pathname]
  );

  return (
    <Layout className={`main-layout ${currentTheme}-theme`}>
      {selectedRoute?.isProtected && <Header />}

      <Layout className="body-layout">
        <Content className="content-container">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
};

import { FileProtectOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import LearningContext from "./LearningContext";

const defaultClassName = "activity-types-filter";

const ActivityTypesFilter = ({
  label,
  info,
  placeholder,
  direction,
  ...rest
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    filterContext,
    availableSuggestions,
    filterOperations: { addActivity, updateActivity, removeActivity }
  } = useContext(LearningContext);

  const { selectedActivities } = filterContext;

  const handleOnActivityClick = value => {
    if (selectedActivities?.includes(value)) {
      removeActivity(value);
    } else {
      addActivity(value);
    }
  };

  return (
    <>
      <Modal
        open={modalVisible}
        footer={null}
        onCancel={() => {
          setModalVisible(false);
        }}
        title="Select Activity Types"
        centered
        width="65%"
        zIndex="99999"
      >
        <Row gutter={20}>
          {availableSuggestions.activities.map(activity => (
            <Col key={activity.value} lg={6} md={12} sm={24} xs={24}>
              {selectedActivities && (
                <div
                  className={[
                    "activity-types-card",
                    selectedActivities?.includes(activity.value)
                      ? "selected"
                      : ""
                  ].join(" ")}
                  onClick={() => handleOnActivityClick(activity.value)}
                >
                  <img
                    className="activity-types-card-image"
                    src={activity.image}
                    alt={activity.name}
                  />
                  <span className="activity-types-card-title">
                    {activity.label}
                  </span>
                </div>
              )}
            </Col>
          ))}
        </Row>
      </Modal>

      <div style={{ width: "100%" }}>
        <CustomSelect
          className={defaultClassName}
          label={label}
          info={info}
          placeholder={placeholder}
          direction={direction}
          value={selectedActivities}
          options={availableSuggestions.activities}
          onChange={updateActivity}
          extra={
            <Button
              icon={<FileProtectOutlined />}
              style={{ marginLeft: "5px" }}
              type="primary"
              onClick={() => {
                setModalVisible(true);
              }}
            />
          }
          {...rest}
        />
      </div>
    </>
  );
};

ActivityTypesFilter.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  placeholder: PropTypes.string,
  direction: PropTypes.oneOf(["horizontal", "vertical"])
};

ActivityTypesFilter.defaultProps = {
  label: "Activity Types",
  info: "Filter by Selected Activity Types.",
  placeholder: "Select Activity Types...",
  direction: "vertical"
};

export default ActivityTypesFilter;

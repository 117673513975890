import { Checkbox, Select, Typography } from "antd";
import React, { useContext } from "react";
import CustomSelect from "../../../components/CustomSelect";
import SideFilters from "../../../components/SideFilters";
import {
  ACTIVITY_ACTIONS,
  ACTIVITY_STATUS
} from "../../../constants/investigation";
import AssetsSelect from "../components/AssetsSelect";
import ColorBadge from "../components/ColorBadge";
import DataSourcesSelect from "../components/DataSourcesSelect";
import DateRangeSelect from "../components/DateRangeSelect";
import TopicsSelect from "../components/TopicsSelect";
import DiscoveriesContext from "./Context";

const { Option } = Select;

const Filters = () => {
  const {
    filterContext,
    filterOperations: {
      resetFilters,
      updateTopics,
      updateDataSources,
      updateDates,
      updateAssets,
      updateSearch,
      setMustIncludeAllSearchWords,
      updateActivityStatus,
      updateActivityActions
    }
  } = useContext(DiscoveriesContext);

  const {
    topics,
    dataSources,
    dates,
    assets,
    search,
    mustIncludeAllSearchWords,
    activityStatus,
    activityActions
  } = filterContext;

  return (
    <SideFilters onClear={() => resetFilters()} filterContext={filterContext}>
      <div style={{ width: "100%" }}>
        <CustomSelect
          className="search-filter"
          label="Search for"
          info="Filter based on these Search Terms"
          placeholder="Search for ..."
          direction="vertical"
          onChange={updateSearch}
          value={search}
          mode="tags"
        />
        <div>
          <Checkbox
            checked={mustIncludeAllSearchWords}
            onChange={e => {
              setMustIncludeAllSearchWords(e.target.checked);
            }}
          >
            <Typography.Text>Must Include all</Typography.Text>
          </Checkbox>
        </div>
      </div>

      <TopicsSelect
        value={topics}
        onChange={updateTopics}
        allowSelectAll
        allowDeselectAll
      />

      <AssetsSelect
        value={assets}
        allowSelectAll
        allowDeselectAll
        onChange={updateAssets}
      />

      <DataSourcesSelect
        value={dataSources}
        onChange={updateDataSources}
        allowSelectAll
        allowDeselectAll
      />

      <CustomSelect
        className="activity-status-filter"
        label="Activity Status"
        info="Filter by Activity Status."
        placeholder="Select Activity Status..."
        direction="vertical"
        value={activityStatus}
        onChange={updateActivityStatus}
        optionsData={ACTIVITY_STATUS}
        allowSelectAll
        allowDeselectAll
      >
        {ACTIVITY_STATUS.map(i => (
          <Option key={i.value} value={i.value}>
            <ColorBadge
              color={i.color}
              size="small"
              text={i.label}
              style={{ width: "100%" }}
            />
          </Option>
        ))}
      </CustomSelect>

      <CustomSelect
        className="activity-actions-filter"
        label="Activity Actions"
        info="Filter by Activity Actions."
        placeholder="Select Activity Actions..."
        direction="vertical"
        value={activityActions}
        options={ACTIVITY_ACTIONS}
        onChange={updateActivityActions}
        optionsData={ACTIVITY_ACTIONS}
        allowSelectAll
        allowDeselectAll
      />

      <DateRangeSelect value={dates} onChange={values => updateDates(values)} />
    </SideFilters>
  );
};

export default Filters;

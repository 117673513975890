import { hasSecurityRoles } from "@salvus/shared/modules/authorization";
import { Result } from "antd";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthCallPage from "./containers/AuthCallbackPage";
import DashboardPage from "./containers/DashboardPage";
import DiscoveriesPage from "./containers/DiscoveriesPage";
import LearningPage from "./containers/LearningPage";
import LogsPage from "./containers/LogsPage";
import ProfilePage from "./containers/ProfilePage";
import SettingsPage from "./containers/SettingsPage";
import SubscriptionsPage from "./containers/SubscriptionsPage";
import TenantDetailsPage from "./containers/TenantDetailsPage";
import TenantsPage from "./containers/TenantsPage";
import TopicKeywordsPage from "./containers/TopicKeywordsPage";
import TopicsPage from "./containers/TopicsPage";
import UsersPage from "./containers/UsersPage";
import { useAuth } from "./context/AuthContext";
import { useAuthorization } from "./modules/Auth/hooks";
import { flattenedRouteList } from "./routeList";

// eslint-disable-next-line no-unused-vars, react/prop-types
const PrivateRoute = ({
  component: Component,
  isProtected,
  requiredRoles,
  ...restProps
}) => {
  const { currentUser } = useAuth();
  const { activeTenantKey } = useAuthorization();

  return (
    <Route
      {...restProps}
      render={props => {
        // No need to provide protection
        if (!isProtected) {
          return <Component {...props} />;
        }

        // Check Authorization
        try {
          if (
            !requiredRoles?.length ||
            hasSecurityRoles(currentUser, activeTenantKey, requiredRoles)
          ) {
            return <Component {...props} />;
          }

          // User is not authorized
          return (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
            />
          );
        } catch (err) {
          return (
            <Result
              status="500"
              title="500"
              subTitle="Encountered an error! Please logout and login again if issue persists."
            />
          );
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
  isProtected: PropTypes.bool
};

PrivateRoute.defaultProps = {
  isProtected: false,
  component: null,
  requiredRoles: []
};

const routeMapping = [
  {
    key: "/",
    component: () => <Redirect to="/dashboard" />
  },
  {
    key: "/dashboard",
    component: DashboardPage
  },
  {
    key: "/discoveries",
    component: DiscoveriesPage
  },
  {
    key: "/learning",
    component: LearningPage
  },
  {
    key: "/settings",
    component: SettingsPage
  },
  {
    key: "/settings/tenants",
    component: TenantsPage
  },
  {
    key: "/settings/tenants/details",
    component: TenantDetailsPage
  },
  {
    key: "/settings/users",
    component: UsersPage
  },
  {
    key: "/settings/subscriptions",
    component: SubscriptionsPage
  },
  {
    key: "/settings/topics",
    component: TopicsPage
  },
  {
    key: "/settings/topics/id/keywords",
    component: TopicKeywordsPage
  },
  {
    key: "/settings/logs",
    component: LogsPage
  },
  {
    key: "/profile",
    component: ProfilePage
  },
  {
    key: "/auth/callback",
    component: AuthCallPage
  }
];

export const RoutesMapper = memo(() => {
  const mappedRoutes = flattenedRouteList.map(v => ({
    ...v,
    ...routeMapping.find(sp => sp.key === v.key)
  }));

  return (
    <Switch>
      {mappedRoutes.map(route => (
        <PrivateRoute
          key={route.key}
          exact={route.exact}
          path={route.path}
          component={route.component}
          isProtected={route.isProtected}
          requiredRoles={route.requiredRoles?.filter(Boolean)}
        />
      ))}
      <Route key="errorPage" path="*">
        <Redirect to={{ pathname: "/" }} />
      </Route>
    </Switch>
  );
});

import React from "react";
import PropTypes from "prop-types";
import { animated, useSpring } from "react-spring";

import "./index.less";
import ShellLogo from "../ShellLogo";

export default function ClassicProjectLogo({ projectName, animate, logoWidth, logoHeight, fontSize }) {
  const [textProps] = useSpring(() => ({
    transform: [0, 0],
    fontSize,
    shellText: "#D42E12",
    projectNameText: "#003882",
    from: animate
      ? {
          shellText: "black",
          projectNameText: "grey",
          transform: [0, -200]
        }
      : { shellText: "#D42E12", projectNameText: "#003882", transform: [0, 0] },
    config: {
      mass: 3,
      tension: 400,
      friction: 25
    }
  }));

  return (
    <div style={{ display: "flex", justifyContent: "flex-start", padding: 6, flexWrap: "nowrap" }}>
      <ShellLogo animate={animate} width={logoWidth} height={logoHeight} />
      <animated.span
        className="logo-text"
        style={{
          ...textProps,
          transform: textProps.transform.interpolate((x, y) => `translate(${x}px, ${y}px)`),
          marginLeft: 8,
          color: textProps.shellText
        }}
      >
        Shell
      </animated.span>
      <animated.span
        className="logo-text"
        style={{
          ...textProps,
          fontSize: textProps.fontSize,
          transform: textProps.transform.interpolate((x, y) => `translate(${x}px, ${y}px)`),
          color: textProps.projectNameText
        }}
      >
        {projectName}
      </animated.span>
    </div>
  );
}

ClassicProjectLogo.propTypes = {
  projectName: PropTypes.string,
  animate: PropTypes.bool,
  logoWidth: PropTypes.any,
  logoHeight: PropTypes.any,
  fontSize: PropTypes.any
};

ClassicProjectLogo.defaultProps = {
  projectName: "STW",
  animate: false,
  logoWidth: 120,
  logoHeight: 120,
  fontSize: "5rem"
};

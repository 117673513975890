import { Button, Card, Col, Input, Row, Typography } from "antd";
import { startCase } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ComponentLoader from "../../../components/ComponentLoader";
import {
  DefaultRequestConfig,
  RequestMethodType,
  topicsEndpoint,
  useApi
} from "../../API";
import { useAuthorization } from "../../Auth/hooks";
import ColorBadge from "../components/ColorBadge";
import TopicForm from "./TopicForm";

const { Search } = Input;

const Content = () => {
  const { hasSecurityRoles, adminRoles } = useAuthorization();
  const [data, setData] = useState();
  const [formOpen, setFormOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [search, setSearch] = useState("");

  const canEdit = useMemo(
    () => hasSecurityRoles(adminRoles.topics),
    [adminRoles.topics, hasSecurityRoles]
  );

  const {
    request: getTopics,
    loading: loadingTopics,
    error: errorGettingTopics
  } = useApi(
    topicsEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const history = useHistory();
  const location = useLocation();

  const fetchData = useCallback(async () => {
    const response = await getTopics();
    const responseData = response.result.hits.hits;

    setData(responseData);
  }, [setData, getTopics]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ComponentLoader
      isLoading={loadingTopics || !data}
      hasErrors={Boolean(errorGettingTopics)}
      errorComponent="Unable to retrieve the data at this time"
      hasNoData={data && !data?.length}
      noDataComponent="No data to show"
    >
      <Row key="row-actions" gutter={[5, 5]} className="top-filter-row">
        <Col flex="auto">
          <Search
            allowClear
            placeholder="Search by Name, Key, or Description..."
            onSearch={value => setSearch(value)}
          />
        </Col>
      </Row>
      <Row
        key="row-cards"
        className="cards-container"
        gutter={[16, 16]}
        type="flex"
        align="middle"
        style={{ paddingBottom: "10px" }}
      >
        {(data || [])
          .filter(
            value =>
              !search ||
              [
                value?._source?.key?.toLowerCase(),
                value?._source?.name?.toLowerCase(),
                value?._source?.description.toLowerCase()
              ].some(str => str?.includes(search.toLowerCase()))
          )
          .map(item => (
            <Col
              key={item._id}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
            >
              <Card
                key={`${item._id}-card`}
                className="container-card no-title-border no-actions-border large-border"
                hoverable
                actions={[
                  <Button
                    key="Edit"
                    type="link"
                    disabled={!canEdit}
                    onClick={() => {
                      setSelectedId(item._id);
                      setFormOpen(true);
                    }}
                  >
                    Edit
                  </Button>,
                  <Button
                    key="keywords"
                    type="link"
                    onClick={() => {
                      history.push(`${location.pathname}/${item._id}/keywords`);
                    }}
                  >
                    Keywords
                  </Button>
                ]}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <ColorBadge
                    color={item?._source?.presentationColor}
                    style={{ marginTop: "2px" }}
                  />
                  <Typography.Title
                    level={5}
                    style={{ marginTop: 0, paddingLeft: "5px" }}
                  >
                    {startCase(item._source?.name)}
                  </Typography.Title>
                </div>
                <Typography.Paragraph
                  type="secondary"
                  ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                >
                  {item._source?.description}
                </Typography.Paragraph>
              </Card>
            </Col>
          ))}
      </Row>

      <TopicForm
        open={formOpen}
        onCancel={() => {
          setSelectedId();
          setFormOpen(false);
        }}
        onSave={() => {
          setSelectedId();
          setFormOpen(false);
          fetchData();
        }}
        id={selectedId}
      />
    </ComponentLoader>
  );
};

export default Content;

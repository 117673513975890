import { startCase } from "lodash";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import MessageComponent from "../../components/MessageComponent";
import SettingsSideMenu from "../../components/SideMenus/SettingsSideMenu";
import { GlobalTenantConfig } from "../../constants/common";
import PageLayout from "../../layouts/PageLayout";
import Content from "../../modules/Investigate/TopicKeywords/Content";

import { useAuthorization } from "../../modules/Auth/hooks";
import "./index.less";

const TopicKeywordsPage = () => {
  const { activeTenantKey } = useAuthorization();
  const history = useHistory();
  const [topicName, setTopicName] = useState(null);

  const getTopicName = name => {
    setTopicName(name);
  };

  const renderContent = useMemo(() => {
    if (!activeTenantKey || activeTenantKey === GlobalTenantConfig.key) {
      return (
        <MessageComponent
          title="Sorry!"
          message="You must select a Tenant before proceeding"
          type="danger"
        />
      );
    }

    return <Content onTopicNameChange={getTopicName} />;
  }, [activeTenantKey]);

  return (
    <PageLayout
      className="topic-keywords-page"
      side={{
        placement: "left",
        component: <SettingsSideMenu />
      }}
      header={{
        title: `${startCase(topicName || "")}: Keywords`,
        description: `Manage the Keywords for "${topicName || ""}".`,
        onBack: () => history.goBack()
      }}
    >
      {renderContent}
    </PageLayout>
  );
};

export default TopicKeywordsPage;

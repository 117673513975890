import { Select } from "antd";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import CustomSelect from "../../../../components/CustomSelect";
import TenantContext from "../../../../context/TenantContext";
import ColorBadge from "../ColorBadge";

const defaultClassName = "topics-filter";

const { Option } = Select;

const TopicsSelect = ({ label, info, placeholder, direction, ...rest }) => {
  const { activeTenantTopics } = useContext(TenantContext);

  return (
    <div style={{ width: "100%" }}>
      <CustomSelect
        className={defaultClassName}
        label={label}
        info={info}
        placeholder={placeholder}
        direction={direction}
        optionsData={activeTenantTopics}
        {...rest}
      >
        {activeTenantTopics.map(i => (
          <Option key={i.key} value={i.key}>
            <ColorBadge
              color={i.color}
              text={i.name}
              size="small"
              style={{ width: "100%" }}
            />
          </Option>
        ))}
      </CustomSelect>
    </div>
  );
};

TopicsSelect.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  placeholder: PropTypes.string,
  direction: PropTypes.oneOf(["horizontal", "vertical"])
};

TopicsSelect.defaultProps = {
  label: "Topics",
  info: "Set of topics used in discovering items from reports.",
  placeholder: "Select Topic(s)...",
  direction: "vertical"
};

export default TopicsSelect;

import axios from "axios";
import { SERVICES } from "../config";

const PATH = {
  ADD_USER_SEARCH_HISTORY: `${SERVICES.API}/api/es/add-user-search-history`,
  ES_BASE: `${SERVICES.API}/api/es`,
  GET_DOC_KEYWORDS: `${SERVICES.API}/api/es`,
  UPDATE_RANKINGS: `${SERVICES.API}/api/es/update_rankings`,
  QUERY_ITEM: `${SERVICES.API}/api/es/item`,
  GET_SUGGESTED_KEYWORDS: `${SERVICES.API}/api/es/suggested-keywords`,
  LEARNING_COUNT: `${SERVICES.API}/api/es/learning-count`,
  LEARNING_SEARCH: `${SERVICES.API}/api/es/learning-search`,
  AGGREGATION_QUERY: `${SERVICES.API}/api/es/terms-aggregate`,
  KEYWORD_SUGGESTER: `${SERVICES.API}/api/es/keyword-suggester`,
  LEARNING_SIMILAR_PREFIX: `${SERVICES.API}/api/es/learning-search-similar`
};

export const addUserSearchHistory = async searchContent =>
  axios.post(PATH.ADD_USER_SEARCH_HISTORY, searchContent);

export const updateRankings = async (
  documentID,
  updateRankingsObject,
  keywords = []
) =>
  axios.put(PATH.UPDATE_RANKINGS, {
    documentID,
    updateRankingsObject,
    keywords
  });

export const keywordSuggester = async keyword =>
  axios.post(`${PATH.KEYWORD_SUGGESTER}`, { rawKeyword: keyword });

export const getLFIItemByID = async id =>
  axios.get(`${PATH.QUERY_ITEM}/learning-data/${id}`);

export const getSuggestedKeywords = async () =>
  axios.post(PATH.GET_SUGGESTED_KEYWORDS);

export const getAggregateQuery = async (aggregateName, fieldName) =>
  axios.post(PATH.AGGREGATION_QUERY, { aggregateName, fieldName });

export const learningSearch = async (
  filterContext,
  offset = 0,
  bodyObj = {
    highlight: { fields: { title: {}, description: {}, "attachments.*": {} } },
    _source: { excludes: ["text_embedding", "text_field"] }
  }
) => {
  const res = await axios.post(PATH.LEARNING_SEARCH, {
    filterContext,
    offset,
    bodyObj
  });
  const { data } = res;
  return data;
};

export const learningCount = async (filterContext, countConfig = {}) => {
  const res = await axios.post(PATH.LEARNING_COUNT, {
    filterContext,
    countConfig
  });
  const { data } = res;
  return data;
};

// LEARNING: Similar
export const getSimilarData = async requestConfig => {
  const res = await axios
    .post(`${PATH.LEARNING_SIMILAR_PREFIX}`, requestConfig)
    .catch(() => ({}));
  return res.data;
};

export default {
  addUserSearchHistory,
  updateRankings
};

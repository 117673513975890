import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import localeData from "dayjs/plugin/localeData.js";
import quarterOfYear from "dayjs/plugin/quarterOfYear.js";
import utcPlugin from "dayjs/plugin/utc.js";
import weekOfYear from "dayjs/plugin/weekOfYear.js";
import weekYear from "dayjs/plugin/weekYear.js";
import weekday from "dayjs/plugin/weekday.js";

dayjs.extend(utcPlugin);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);

const defaultFormat = "MM/DD/YYYY hh:mm:ss A";
const longHourTimeFormat = "HH:mm";
const shortHourTimeFormat = "hh:mm A";

const newDate = date => (date ? dayjs(date) : dayjs());

const newDateWithFormat = (date, format) => dayjs(date, format);

const formatDate = (date, dateFormat = defaultFormat) =>
  newDate(date).format(dateFormat);

const formatUTCDate = (date, dateFormat = defaultFormat) =>
  newDate(date).utc().format(dateFormat);

const datesFromEpoch = dates => dates?.map(d => newDate(Number(d)));

const epochFromDates = dates => dates?.map(d => d.valueOf());

const numOfDaysBetweenDates = (date1, date2) =>
  newDate(date1).diff(newDate(date2), "days");

const predefinedDates = value => {
  const date = newDate(value);

  return {
    year: date.year(),
    month: date.month(),
    week: date.week(),
    quarter: date.quarter(),
    dayOfWeek: date.day(),
    day: date.date(),
    startOfDay: date.startOf("day"),
    endOfDay: date.endOf("day"),
    startOfYesterday: date.subtract(1, "day").startOf("day"),
    endOfYesterday: date.subtract(1, "day").endOf("day"),
    startOfTomorrow: date.add(1, "day").startOf("day"),
    endOfTomorrow: date.add(1, "day").endOf("day"),
    startOfWeek: date.startOf("week"),
    endOfWeek: date.endOf("week"),
    startOfLastWeek: date.subtract(7, "day").startOf("week"),
    endOfLastWeek: date.subtract(7, "day").endOf("week"),
    startOfLastSevenDays: date.subtract(7, "day").startOf("day"),
    endOfLastSevenDays: date.subtract(7, "day").endOf("day"),
    startOfLastThirtyDays: date.subtract(30, "day").startOf("day"),
    endOfLastThirtyDays: date.subtract(30, "day").endOf("day"),
    startOfLastSixtyDays: date.subtract(60, "day").startOf("day"),
    endOfLastSixtyDays: date.subtract(60, "day").endOf("day"),
    startOfLastNinetyDays: date.subtract(90, "day").startOf("day"),
    endOfLastNinetyDays: date.subtract(90, "day").endOf("day"),
    startOfLast120Days: date.subtract(120, "day").startOf("day"),
    endOfLast120Days: date.subtract(120, "day").endOf("day"),
    startOfMonth: date.startOf("month"),
    endOfMonth: date.endOf("month"),
    startOfLastMonth: date.subtract(1, "month").startOf("month"),
    endOfLastMonth: date.subtract(1, "month").endOf("month"),
    startOfYear: date.startOf("year"),
    endOfYear: date.endOf("year"),
    startOfLastYear: date.subtract(1, "year").startOf("year"),
    endOfLastYear: date.subtract(1, "year").endOf("year")
  };
};

const predefinedRanges = [
  {
    label: "Today",
    value: [predefinedDates().startOfDay, predefinedDates().endOfDay]
  },
  {
    label: "Yesterday",
    value: [
      predefinedDates().startOfYesterday,
      predefinedDates().endOfYesterday
    ]
  },
  {
    label: "Last 7 Days",
    value: [predefinedDates().startOfLastSevenDays, predefinedDates().endOfDay]
  },
  {
    label: "Last 30 Days",
    value: [predefinedDates().startOfLastThirtyDays, predefinedDates().endOfDay]
  },
  {
    label: "Last 60 Days",
    value: [predefinedDates().startOfLastSixtyDays, predefinedDates().endOfDay]
  },
  {
    label: "Last 90 Days",
    value: [predefinedDates().startOfLastNinetyDays, predefinedDates().endOfDay]
  },
  {
    label: "Last 120 Days",
    value: [predefinedDates().startOfLast120Days, predefinedDates().endOfDay]
  }
];

export {
  datesFromEpoch,
  dayjs,
  defaultFormat,
  epochFromDates,
  formatDate,
  formatUTCDate,
  longHourTimeFormat,
  newDate,
  newDateWithFormat,
  numOfDaysBetweenDates,
  predefinedDates,
  predefinedRanges,
  shortHourTimeFormat
};

import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, Row, Table, Typography } from "antd";
import { startCase } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import ComponentLoader from "../../../components/ComponentLoader";
import TenantContext from "../../../context/TenantContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  tenantsEndpoint,
  useApi
} from "../../API";
import { useAuthorization } from "../../Auth/hooks";
import TenantForm from "./TenantForm";

const { Search } = Input;

const TenantsPage = () => {
  const { activeTenantKey, loadTenants } = useContext(TenantContext);
  const { hasSecurityRoles, adminRoles, isActiveTenantGlobal } =
    useAuthorization();
  const [data, setData] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [search, setSearch] = useState("");

  const {
    request: getTenants,
    loading: loadingTenants,
    error: errorGettingTenants
  } = useApi(
    tenantsEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const canManage = useMemo(
    () => hasSecurityRoles(adminRoles.tenants),
    [adminRoles, hasSecurityRoles]
  );

  const history = useHistory();
  const location = useLocation();

  const fetchData = useCallback(async () => {
    const response = await getTenants();
    const responseData = response.result.hits.hits;

    setData(responseData);
  }, [getTenants, setData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const tenants = useMemo(
    () =>
      isActiveTenantGlobal
        ? data
        : data.filter(i => i._source.key === activeTenantKey),
    [activeTenantKey, data, isActiveTenantGlobal]
  );

  const columns = useMemo(
    () => [
      {
        title: "Key",
        key: "key",
        width: 150,
        sorter: (a, b) => a?._source?.key?.localeCompare(b?._source?.key),
        render: record => record?._source?.key
      },
      {
        title: "Name",
        key: "name",
        width: 300,
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?._source?.name?.localeCompare(b?._source?.name),
        render: record => startCase(record?._source.name)
      },
      {
        title: "Description",
        key: "description",
        render: record => (
          <Typography.Paragraph
            ellipsis={{ rows: 2, expandable: false }}
            key={record?._source?.description}
            className="column-paragraph-ellipsis"
          >
            {record?._source?.description}
          </Typography.Paragraph>
        )
      },
      {
        title: "Onboarded",
        key: "isOnboarded",
        width: 150,
        sorter: (a, b) => a._source.isOnboarded - b._source.isOnboarded,
        render: record => (record?._source?.isOnboarded ? "Yes" : "No")
      },
      {
        title: "Status",
        key: "status",
        width: 150,
        sorter: (a, b) => a?._source?.status?.localeCompare(b?._source?.status),
        render: record => startCase(record?._source?.status)
      },
      {
        title: "Actions",
        className: "actions",
        width: 175,
        render: record => (
          <>
            <Button
              type="link"
              style={{ marginRight: "4px" }}
              disabled={!canManage}
              onClick={() => {
                setSelectedId(record._id);
                setFormOpen(true);
              }}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Button
              key="details"
              type="link"
              onClick={() => {
                history.push(`${location.pathname}/${record._id}/details`);
              }}
            >
              Details
            </Button>
          </>
        )
      }
    ],
    [canManage, history, location.pathname]
  );

  if (errorGettingTenants) {
    return (
      <ComponentLoader
        hasErrors={!!errorGettingTenants}
        errorComponent="Unable to retrieve the data at this time"
      />
    );
  }

  return (
    <>
      {isActiveTenantGlobal ? (
        <Row key="row-actions" gutter={[5, 5]} className="top-filter-row">
          {data?.length ? (
            <Col flex="auto">
              <Search
                allowClear
                placeholder="Search by Name..."
                onSearch={value => setSearch(value)}
              />
            </Col>
          ) : null}
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled={!canManage}
              style={{
                marginLeft: "auto"
              }}
              onClick={() => {
                setFormOpen(true);
              }}
            >
              Add Tenants
            </Button>
          </Col>
        </Row>
      ) : null}

      <Table
        className="table align-top"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          showTotal: total => `Total ${total} items`,
          hideOnSinglePage: true
        }}
        columns={columns}
        dataSource={tenants?.filter(
          value =>
            !search ||
            [value?._source?.name?.toLowerCase()].some(str =>
              str?.includes(search.toLowerCase())
            )
        )}
        rowKey={item => item._id}
        scroll={{ x: true }}
        loading={loadingTenants}
      />

      <TenantForm
        open={formOpen}
        onCancel={() => {
          setSelectedId();
          setFormOpen(false);
        }}
        onSave={() => {
          setSelectedId();
          setFormOpen(false);
          loadTenants();
        }}
        id={selectedId}
      />
    </>
  );
};

export default TenantsPage;

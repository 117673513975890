const exceptions = {
  badRequest: { type: "Bad Request", statusCode: 400 },
  notFound: { type: "Not Found", statusCode: 404 },
  notAuthenticated: { type: "Not Authenticated", statusCode: 401 },
  unauthorized: { type: "Unauthorized", statusCode: 403 },
  notAllowed: { type: "Not Allowed", statusCode: 405 },
  insufficientData: { type: "Insufficient Data", statusCode: 422 },
  internalServer: { type: "Internal Server Error", statusCode: 500 },
  notImplemented: { type: "Not Implemented", statusCode: 501 }
};

class STWError extends Error {
  constructor(type, message, statusCode) {
    super();
    this.name = "Error";
    this.message = `${type || "Error"}: ${message}`;
    this.statusCode = statusCode || 500;
  }
}

const throwIfNullValue = (params, exceptions, message) => {
  Object.entries(params).forEach(([key, value]) => {
    if ((Array.isArray(value) && !value?.length) || !value) {
      throw new STWError(
        exceptions.type,
        `${message}: ${key}`,
        exceptions.statusCode
      );
    }
  });
};

const throwBadRequestIfNullValue = (params = {}, message = "") =>
  throwIfNullValue(
    params,
    exceptions.badRequest,
    message || "missing parameter"
  );

const throwNotFoundIfNullValue = (params = {}, message = "") =>
  throwIfNullValue(params, exceptions.notFound, message || "data for");

const throwBadRequest = message => {
  throw new STWError(
    exceptions.badRequest.type,
    message,
    exceptions.badRequest.statusCode
  );
};

const throwNotImplemented = message => {
  throw new STWError(
    exceptions.notImplemented.type,
    message,
    exceptions.notImplemented.statusCode
  );
};

export {
  STWError,
  exceptions,
  throwBadRequest,
  throwBadRequestIfNullValue,
  throwNotFoundIfNullValue,
  throwNotImplemented
};

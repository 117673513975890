import { Segmented } from "antd";
import PropTypes from "prop-types";
import React from "react";

import "./index.less";

const OvalSegmented = ({ segmentedProps, onChange, value, options }) => (
  <Segmented
    className="oval-segmented-component"
    options={options}
    onChange={onChange}
    value={value}
    {...segmentedProps}
  />
);

OvalSegmented.propTypes = {
  segmentedProps: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.any
};

OvalSegmented.defaultProps = {
  onChange: () => {},
  value: null,
  options: null,
  segmentedProps: {}
};

export default OvalSegmented;

import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table } from "antd";
import { startCase } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ComponentLoader from "../../../components/ComponentLoader";
import {
  DefaultRequestConfig,
  RequestMethodType,
  useApi,
  userSubscriptionsEndpoint
} from "../../API";
import { useAuthorization } from "../../Auth/hooks";
import SubscriptionForm from "./SubscriptionForm";

const { Search } = Input;

const Content = () => {
  const { hasSecurityRoles, adminRoles, activeTenantKey } = useAuthorization();

  const [data, setData] = useState();
  const [formInstructions, setFormInstructions] = useState({});
  const [search, setSearch] = useState("");

  const canEdit = useMemo(
    () => hasSecurityRoles(adminRoles.subscriptions),
    [adminRoles.subscriptions, hasSecurityRoles]
  );

  //
  const {
    request: getSubscriptions,
    loading: loadingSubscriptions,
    error: errorGettingSubscriptions
  } = useApi(
    userSubscriptionsEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const fetchData = useCallback(async () => {
    const response = await getSubscriptions();
    const responseData = response.result.hits.hits;

    setData(responseData);
  }, [getSubscriptions]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = useMemo(
    () => [
      {
        title: "User",
        key: "user",
        width: 275,
        defaultSortOrder: "ascend",
        sorter: (a, b) =>
          a?._source?.lastName?.localeCompare(b?._source?.lastName),
        render: record => (
          <div key="email" className="column-paragraph-ellipsis level-2">
            {record?._source?.firstName} {record?._source?.lastName}
          </div>
        )
      },
      {
        title: "Email",
        key: "email",
        width: 275,
        sorter: (a, b) => a?._source?.email?.localeCompare(b?._source?.email),
        render: record => (
          <div key="email" className="column-paragraph-ellipsis level-2">
            {record?._source?.email}
          </div>
        )
      },
      {
        title: "Subscriptions",
        key: "subscriptions",

        render: record => {
          const subscription =
            record?._source?.subscriptions?.[`${activeTenantKey}`]
              ?.discoveriesReport;

          //
          return (
            <>
              <div
                key="subscribed"
                className="column-paragraph-ellipsis level-2"
                style={{ wordWrap: "break-word", wordBreak: "break-word" }}
              >
                <strong>Subscribed</strong>:{" "}
                {subscription?.subscribed ? "Yes" : "No"}
              </div>
              {subscription?.subscribed ? (
                <>
                  <div
                    key="assets"
                    className="column-paragraph-ellipsis level-2"
                    style={{ wordWrap: "break-word", wordBreak: "break-word" }}
                  >
                    <strong>Assets</strong>:{" "}
                    {subscription?.assetKeys?.map(i => startCase(i)).join(", ")}
                  </div>
                  <div
                    key="topics"
                    className="column-paragraph-ellipsis level-3"
                    style={{ wordWrap: "break-word", wordBreak: "break-word" }}
                  >
                    <strong>Topics</strong>:{" "}
                    {subscription?.topicKeys?.map(i => startCase(i)).join(", ")}
                  </div>
                </>
              ) : null}
            </>
          );
        }
      },
      {
        title: "Actions",
        className: "actions",
        width: 200,
        render: record => (
          <Button
            type="link"
            style={{ marginRight: "4px" }}
            disabled={!canEdit}
            onClick={() => {
              setFormInstructions({
                open: true,
                onSaveAction: () => {
                  fetchData();
                  setFormInstructions({});
                },
                data: record
              });
            }}
            icon={<EditOutlined />}
          >
            Edit Subscription
          </Button>
        )
      }
    ],
    [activeTenantKey, canEdit, fetchData]
  );

  if (errorGettingSubscriptions) {
    return (
      <ComponentLoader
        hasErrors={Boolean(errorGettingSubscriptions)}
        errorComponent="Unable to retrieve the data at this time"
      />
    );
  }

  return (
    <>
      <Row key="row-actions" gutter={[5, 5]} className="top-filter-row">
        <Col flex="auto">
          <Search
            allowClear
            placeholder="Search by First Name, Last Name, or Email..."
            onSearch={value => setSearch(value)}
          />
        </Col>
      </Row>
      <Table
        className="table align-top"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          showTotal: total => `Total ${total} items`,
          hideOnSinglePage: true
        }}
        loading={loadingSubscriptions}
        columns={columns}
        dataSource={data?.filter(
          value =>
            !search ||
            [
              value?._source?.firstName?.toLowerCase(),
              value?._source?.lastName?.toLowerCase(),
              value?._source?.email.toLowerCase()
            ].some(str => str?.includes(search.toLowerCase()))
        )}
        rowKey={item => item?.key}
        scroll={{ x: true }}
      />
      <SubscriptionForm
        open={formInstructions?.open}
        onCancel={() => setFormInstructions({})}
        onSave={formInstructions?.onSaveAction}
        data={formInstructions?.data}
      />
    </>
  );
};

export default Content;

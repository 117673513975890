import qs from "qs";

/**
 * This serializes query string parameters including objects
 * @param {string | object} data to be serialized
 * @returns string representation of the complex object
 */
const serializeSearchParams = data =>
  qs.stringify(data, { depth: 40, arrayLimit: 50 });

/**
 * This converts the serialized string back to an object
 * @param {string} data to be normalized
 * @returns normalized version
 */

const normalizeSeachParams = data =>
  qs.parse(data, { depth: 40, arrayLimit: 50 });

export { normalizeSeachParams, serializeSearchParams };

import { formatDate } from "@salvus/shared/utils";
import { Card, Col, Row, Typography } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import CustomLink from "../CustomLink";

import "./index.less";

const defaultClassNamePrefix = "result-card";

export const ResultCardHeader = ({
  title,
  url,
  onClick,
  date,
  source,
  extra
}) => {
  const renderTitle = () => {
    if (!title) {
      return null;
    }

    return React.isValidElement(title) ? (
      title
    ) : (
      <CustomLink href={url} onClick={onClick}>
        <Typography.Title level={5}>{title}</Typography.Title>
      </CustomLink>
    );
  };

  const renderSubtitle = () => {
    if (!date && !source) {
      return null;
    }
    return (
      <>
        {date && (
          <Typography.Text type="secondary" style={{ marginRight: "12px" }}>
            {formatDate(date, "YYYY-MM-DD")}
          </Typography.Text>
        )}
        {source && <Typography.Text type="secondary">{source}</Typography.Text>}
      </>
    );
  };

  const renderExtra = () => {
    if (!extra) {
      return null;
    }

    return React.isValidElement(extra) ? (
      extra
    ) : (
      <Typography.Title level={5}>{extra}</Typography.Title>
    );
  };

  if (!title && !date && !source && !extra) {
    return null;
  }

  return (
    <Row
      className={`${defaultClassNamePrefix}-header`}
      justify="space-between"
      align="middle"
    >
      <Col className="header-title">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {renderTitle()}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {renderSubtitle()}
          </div>
        </div>
      </Col>
      <Col className="header-extra">{renderExtra()}</Col>
    </Row>
  );
};

ResultCardHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  url: PropTypes.string,
  onClick: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  source: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

ResultCardHeader.defaultProps = {
  title: null,
  url: null,
  onClick: () => {},
  date: null,
  source: null,
  extra: null
};

export const ResultCardFooter = ({ actions }) => {
  const renderActions = () => {
    if (!actions || !Array.isArray(actions)) {
      return [];
    }

    return actions.map(action => action);
  };

  if (!actions || !actions?.length) {
    return null;
  }

  return (
    <Row
      className={`${defaultClassNamePrefix}-footer`}
      justify="start"
      align="bottom"
    >
      <Col className="footer-actions">{renderActions()}</Col>
    </Row>
  );
};

ResultCardFooter.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node)
};

ResultCardFooter.defaultProps = {
  actions: null
};

const ResultCard = ({
  className,
  header,
  footer,
  children,
  props,
  selected
}) => (
  <Card
    className={classNames(defaultClassNamePrefix, className, {
      selected
    })}
    {...props}
  >
    {header}

    <div className={`${defaultClassNamePrefix}-content`}>
      <div className="content-body">{children}</div>
    </div>
    {footer}
  </Card>
);

ResultCard.propTypes = {
  className: PropTypes.string,
  props: PropTypes.object,
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  selected: PropTypes.bool
};

ResultCard.defaultProps = {
  className: null,
  props: null,
  header: null,
  footer: null,
  children: null,
  selected: false
};

export default ResultCard;

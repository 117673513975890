import { Checkbox, Typography } from "antd";
import { groupBy } from "lodash";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import CustomSelect from "../../components/CustomSelect";
import LearningContext from "./LearningContext";

const defaultClassName = "keywords-filter";

const KeywordsFilter = ({ label, info, placeholder, direction, ...rest }) => {
  const {
    suggestingKeywords,
    filterContext,
    filterOperations: {
      updateKeywords,
      setMustIncludeAllKeywords,
      setSynonymsEnabled
    }
  } = useContext(LearningContext);

  const { selectedKeywords, synonymsEnabled, mustIncludeAllKeywords } =
    filterContext;

  const keywordData = Object.entries(
    groupBy(suggestingKeywords, "activityType")
  ).map(([group, value]) => ({
    label: group,
    options: value.map(v => ({ label: v.value, value: v.value }))
  }));

  return (
    <div style={{ width: "100%" }}>
      <CustomSelect
        className={defaultClassName}
        label={label}
        info={info}
        placeholder={placeholder}
        direction={direction}
        value={selectedKeywords}
        onChange={updateKeywords}
        options={keywordData}
        mode="tags"
        {...rest}
      />
      <div>
        <Checkbox
          checked={mustIncludeAllKeywords}
          onChange={e => {
            setMustIncludeAllKeywords(e.target.checked);
          }}
        >
          <Typography.Text>Must Include all Keywords</Typography.Text>
        </Checkbox>
      </div>
      <div>
        <Checkbox
          checked={synonymsEnabled}
          onChange={e => {
            setSynonymsEnabled(e.target.checked);
          }}
        >
          <Typography.Text>Match Similar Keyword(s)</Typography.Text>
        </Checkbox>
      </div>
    </div>
  );
};

KeywordsFilter.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  placeholder: PropTypes.string,
  direction: PropTypes.oneOf(["horizontal", "vertical"])
};

KeywordsFilter.defaultProps = {
  label: "Keywords",
  info: "Filter by Selected Keywords.",
  placeholder: "Select Keywords...",
  direction: "vertical"
};

export default KeywordsFilter;

import { attempt, isEmpty, isError } from "lodash-es";

const defaultHighlightWrapper =
  '<em style="background-color:#f7d117;color:#003882;font-weight:bold">&nbsp;{0}&nbsp;</em>';

/**
 * Converts a string into Boolean
 *
 * @param {string} value The string version of Boolean
 * @returns {boolean} Guranteed True/False based on the input
 */
const toBoolean = value => {
  if (typeof value === "string" && !!value) {
    value = value?.trim().toLowerCase();
  }

  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
};

/**
 * Equivalent to C# String.Format() using Numerical placeholders
 *
 * @param {string} content The content that needs to be formatted with
 * @param {array of any} values The values that will be used to replace the content
 * @returns {string} Formatted string
 *
 * @example:
 * toFormatted("Hello {0}, have a wonderful {1}", "Chris", "Day");
 * @returns
 * Hello Chris, have a wonderful Day
 */
const toFormatted = (content, ...values) => {
  for (const index in values) {
    content = (content || "").replace("{" + index + "}", values[index]);
  }
  return content;
};

const toProperCase = value =>
  value
    .trim()
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

/**
 *
 * @param {string} value
 * @returns
 */
const parseAsJson = value => {
  if (typeof value === "object") {
    return value;
  }
  const result = attempt(JSON.parse.bind(null, value));
  if (isError(result) || isEmpty(result)) {
    return value;
  }
  return result;
};

/**
 * Removes all special characters and converts to lowercase
 * @param data
 * @returns
 */
const slugify = data =>
  data
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, "");

const regexify = data => data.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

const highlightData = (data, start, end, highlightCoordinates) => {
  let result = data;

  const replace = (data, start, end) =>
    "".concat(
      data.substr(0, start),
      toFormatted(defaultHighlightWrapper, data.substr(start, end)),
      data.substr(start + end)
    );

  const relevantCoordinates = highlightCoordinates.filter(
    c => c[0] >= start && c[1] <= end
  );

  relevantCoordinates.forEach(c => {
    result = replace(result, c[0] - start, c[1] - c[0]);
  });

  return result;
};

export {
  highlightData,
  parseAsJson,
  regexify,
  slugify,
  toBoolean,
  toFormatted,
  toProperCase
};

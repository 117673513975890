import { Result } from "antd";
import React, { useCallback } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useAuthorization } from "../../modules/Auth/hooks";
import { filterNonSecuredRoutes, settingsRouteList } from "../../routeList";

const SettingsPage = () => {
  const { activeTenantKey } = useAuthorization();
  const { currentUser } = useAuth();

  const getSettingsAuthorization = useCallback(() => {
    const routes = filterNonSecuredRoutes(
      settingsRouteList,
      currentUser,
      activeTenantKey
    );

    if (routes?.length) {
      return <Redirect to={{ pathname: routes[0].path }} />;
    }

    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
    );
  }, [activeTenantKey, currentUser]);

  return <>{getSettingsAuthorization()}</>;
};

export default SettingsPage;

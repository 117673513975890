import React, { useMemo } from "react";
import MessageComponent from "../../components/MessageComponent";
import PageLayout from "../../layouts/PageLayout";
import { useAuthorization } from "../../modules/Auth/hooks";
import Content from "../../modules/Investigate/Discoveries/Content";
import { DiscoveriesContextProvider } from "../../modules/Investigate/Discoveries/Context";
import Filters from "../../modules/Investigate/Discoveries/Filters";

import "./index.less";

const DiscoveriesPage = () => {
  const { activeTenantKey, isActiveTenantGlobal } = useAuthorization();

  const canFilter = activeTenantKey && !isActiveTenantGlobal;

  const renderContent = useMemo(() => {
    if (!canFilter) {
      return (
        <MessageComponent
          title="Sorry!"
          message="You must select a Tenant before proceeding"
          type="danger"
        />
      );
    }

    return <Content />;
  }, [canFilter]);

  return (
    <DiscoveriesContextProvider>
      <PageLayout
        className="discoveries-page"
        side={{
          ...(canFilter
            ? {
                placement: "left",
                component: <Filters />,
                size: "large"
              }
            : {})
        }}
      >
        {renderContent}
      </PageLayout>
    </DiscoveriesContextProvider>
  );
};

export default DiscoveriesPage;

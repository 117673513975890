import React, { useContext } from "react";
import CustomDates from "../../components/CustomDates";
import CustomSelect from "../../components/CustomSelect";
import SideFilters from "../../components/SideFilters";
import ActivityTypesFilter from "./ActivityTypesFilter";
import KeywordsFilter from "./KeywordsFilter";
import LearningContext from "./LearningContext";
import LocationsFilter from "./LocationsFilter";

const Filters = () => {
  const {
    filterContext,
    availableSuggestions: { dataSources },
    filterOperations: { resetFilters, updateDataSources, updateDateRange }
  } = useContext(LearningContext);

  const { selectedDataSources, selectedDateRange } = filterContext;

  return (
    <SideFilters
      onClear={() => resetFilters()}
      filterContext={filterContext}
      includeActiveTenantKey={false}
    >
      <ActivityTypesFilter />
      <KeywordsFilter />
      <div style={{ width: "100%" }}>
        <CustomSelect
          className="data-sources-filter"
          label="Data Sources"
          info="Filter by Data Sources."
          placeholder="Select Data Sources..."
          direction="vertical"
          value={selectedDataSources}
          options={dataSources}
          onChange={updateDataSources}
        />
      </div>
      <LocationsFilter />
      <div style={{ width: "100%" }}>
        <CustomDates
          className="dates-filter"
          value={selectedDateRange}
          onChange={updateDateRange}
        />
      </div>
    </SideFilters>
  );
};

export default Filters;

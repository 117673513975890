export const NlpMinConfidenceScore = 0.75;

export const ACTIVITY_STATUS = [
  {
    label: "Requires Action",
    value: "requireAction",
    color: "red"
  },
  {
    label: "In Progress",
    value: "inProgress",
    color: "green"
  },
  {
    label: "Resolved",
    value: "resolved",
    color: "blue"
  }
];

export const ACTIVITY_ACTIONS = [
  {
    label: "Improvement implemented",
    value: "improvementImplemented"
  },
  {
    label: "Noted / followed up with Asset",
    value: "notedAndFollowedUp"
  },
  {
    label: "Long term / Fit4 consideration",
    value: "longTermAndFit4"
  },
  {
    label: "No Action required",
    value: "noActionRequired"
  }
];

import { numOfDaysBetweenDates } from "@salvus/shared/utils";
import PropTypes from "prop-types";
import React from "react";
import CustomDates from "../../../../components/CustomDates";

const defaultClassName = "dates-filter";

const DateRangeSelect = ({ label, value, ...rest }) => {
  const onDisabledDate = current => {
    const tooLate = value[0] && numOfDaysBetweenDates(current, value[0]) >= 120;
    const tooEarly =
      value[1] && numOfDaysBetweenDates(value[1], current) >= 120;
    return !!tooEarly || !!tooLate;
  };

  return (
    <div style={{ width: "100%" }}>
      <CustomDates
        className={defaultClassName}
        label={label}
        value={value}
        rangePickerProps={{ disabledDate: onDisabledDate }}
        {...rest}
      />
    </div>
  );
};

DateRangeSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array
};

DateRangeSelect.defaultProps = {
  label: "Dates",
  value: []
};

export default DateRangeSelect;

import { inRange, isEqual, pullAt, uniqWith } from "lodash-es";

/**
 * Ensures the value is an array
 * @param {any | any[]) data to be returned as Array
 * @returns
 */
const toArray = data => (!data ? [] : Array.isArray(data) ? data : [data]);

/**
 * Returns whether original array contains at least 1 value from the compare array
 * @param {any[]} array
 * @param {any[]} compare
 * @returns
 */
const doesArraysIntersect = (array, compare) =>
  array.some(p => compare.includes(p));

/**
 * Adds or removes item from an array
 * @param {any[]} array
 * @param {string} value
 */
const toggleString = (array, value) =>
  !value
    ? array
    : array.includes(value)
    ? array.filter(i => i !== value)
    : [...array, value];

const makeUniqueGroupings = data => {
  const makeUnique = groupings => {
    groupings.forEach((parent, parentIndex) => {
      groupings.forEach((child, childIndex) => {
        if (parentIndex !== childIndex) {
          if (
            inRange(parent[0], child[0], child[1]) ||
            inRange(parent[1], child[0], child[1]) ||
            inRange(child[0], parent[0], parent[1]) ||
            inRange(child[1], parent[0], parent[1])
          ) {
            pullAt(groupings, [parentIndex, childIndex]);
            groupings.push([
              Math.min(parent[0], child[0]),
              Math.max(parent[1], child[1])
            ]);

            return makeUnique(groupings);
          }
        }
      });
    });

    return groupings;
  };

  const newGroupings = uniqWith(data, isEqual);
  return makeUnique(newGroupings).sort((a, b) => b[0] - a[0]);
};

const toArrayFromString = (value, delimiter = ".") =>
  value.split(delimiter).filter(Boolean);

const getElementsFromStringKey = (value, indexes = [], delimiter = ".") => {
  let arrayFromString = toArrayFromString(value, delimiter);
  let newKey = [];
  indexes.forEach(i => newKey.push(arrayFromString[i]));
  return newKey.join(delimiter);
};

const removeLastElementFromStringKey = (value, delimiter = ".") => {
  let newKey = toArrayFromString(value, delimiter);
  newKey.pop();
  return newKey.join(delimiter);
};

export {
  doesArraysIntersect,
  getElementsFromStringKey,
  makeUniqueGroupings,
  removeLastElementFromStringKey,
  toArray,
  toArrayFromString,
  toggleString
};

import { useProtectedREST } from "../Auth/hooks";

export const DefaultRequestConfig = {};
export const RequestMethodType = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE"
};

export const useApi = (
  requestUrl,
  requestMethod,
  requestConfig = DefaultRequestConfig,
  shouldAutomaticFetch = false
) =>
  useProtectedREST(
    requestUrl,
    requestMethod,
    requestConfig,
    shouldAutomaticFetch
  );

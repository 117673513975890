import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Divider, Select, Typography } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import InfoTooltip from "../InfoTooltip";

import "./index.less";

const defaultClassNamePrefix = "custom-select";

const CustomSelect = ({
  className,
  label,
  info,
  direction,
  labelProps,
  mode,
  responsive,
  options,
  placeholder,
  onChange,
  onClear,
  children,
  extra,
  allowSelectAll,
  allowDeselectAll,
  optionsData,
  ...rest
}) => {
  const renderFooter = useCallback(
    menu => {
      if ((!allowSelectAll && !allowDeselectAll) || !optionsData?.length) {
        return menu;
      }

      return (
        <>
          {menu}
          <Divider
            style={{
              margin: "8px 0"
            }}
          />
          <div
            style={{
              padding: "0 8px 4px",
              display: "flex",
              justifyContent: "space-around"
            }}
          >
            {allowSelectAll && (
              <Button
                type="text"
                icon={<CheckOutlined />}
                onClick={() => onChange(optionsData.map(o => o.key || o.value))}
              >
                Select All
              </Button>
            )}
            {allowDeselectAll && (
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => onChange([])}
              >
                Deselect All
              </Button>
            )}
          </div>
        </>
      );
    },
    [allowDeselectAll, allowSelectAll, onChange, optionsData]
  );

  return (
    <div
      className={classNames(`${defaultClassNamePrefix}-container`, className, [
        `${defaultClassNamePrefix}-${direction}`
      ])}
    >
      {label && (
        <div className={`${defaultClassNamePrefix}-label`}>
          <Typography.Text {...labelProps}>{label}</Typography.Text>
          {info && <InfoTooltip info={info} />}
        </div>
      )}
      <div style={{ display: "flex" }}>
        <Select
          className={defaultClassNamePrefix}
          mode={mode}
          maxTagCount={responsive ? "responsive" : ""}
          onChange={onChange}
          onClear={onClear}
          {...(options?.length ? { options } : null)}
          placeholder={placeholder}
          allowClear={false}
          maxTagTextLength={3}
          showArrow
          popupClassName={`${defaultClassNamePrefix}-popup`}
          dropdownRender={menu => renderFooter(menu)}
          {...rest}
        >
          {children}
        </Select>
        {extra || null}
      </div>
    </div>
  );
};

CustomSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.string,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  labelProps: PropTypes.object,
  mode: PropTypes.oneOf(["", "tags", "multiple"]),
  responsive: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  children: PropTypes.node,
  extra: PropTypes.node,
  allowSelectAll: PropTypes.bool,
  allowDeselectAll: PropTypes.bool,
  optionsData: PropTypes.array
};

CustomSelect.defaultProps = {
  className: null,
  label: null,
  info: null,
  direction: "vertical",
  labelProps: null,
  mode: "multiple",
  responsive: true,
  options: null,
  placeholder: "Select Item...",
  onChange: () => {},
  onClear: () => {},
  children: null,
  extra: null,
  allowSelectAll: false,
  allowDeselectAll: false,
  optionsData: null
};

export default CustomSelect;

import { CopyOutlined } from "@ant-design/icons";
import { predefinedDates, toArray } from "@salvus/shared/utils";
import { Button, Col, Row, Select, Table, Typography } from "antd";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import ComponentLoader from "../../../components/ComponentLoader";
import CustomDates from "../../../components/CustomDates";
import CustomSearch from "../../../components/CustomSearch";
import CustomSelect from "../../../components/CustomSelect";
import LayoutContext from "../../../context/LayoutContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  logsEndpoint,
  useApi
} from "../../API";

const { Option } = Select;

export const createFilters = ({ logType, dates, results, actions, search }) => {
  const filters = {};

  if (logType) {
    filters.logType = logType;
  }

  if (dates?.length === 2) {
    filters.dates = dates;
  }

  if (toArray(results).length) {
    filters.results = results;
  }

  if (toArray(actions).length) {
    filters.actions = actions;
  }

  if (toArray(search).length) {
    filters.search = search;
  }

  return {
    filters
  };
};

const Content = () => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const [data, setData] = useState();
  const [filterByDateRange, setFilterByDateRange] = useState([
    predefinedDates().startOfDay.utc().valueOf(),
    predefinedDates().endOfDay.utc().valueOf()
  ]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      defaultPageSize: 20,
      current: 1,
      pageSize: 20,
      showSizeChanger: true
    }
  });

  const [filterByResults, setFilterByResults] = useState([]);
  const [filterSearch, setFilterSearch] = useState(null);

  const {
    request: getLogs,
    loading: loadingLogs,
    error: errorGettingLogs
  } = useApi(logsEndpoint, RequestMethodType.POST, DefaultRequestConfig, false);

  const fetchData = useCallback(
    async (pagination, _, sorter) => {
      const payload = {
        ...createFilters({
          logType: "discoveriesreportlog",
          dates: filterByDateRange,
          results: filterByResults,
          search: filterSearch
        }),
        sort: [
          {
            [`${sorter?.columnKey || "runOn"}`]:
              sorter?.order === "ascend" ? "asc" : "desc"
          }
        ],
        from:
          (pagination?.current ? pagination.current - 1 : 0) *
          (pagination?.pageSize || 20),
        size: pagination?.pageSize || 20
      };

      const response = await getLogs({ data: payload });
      const responseData = response.result.hits;

      setData(responseData.hits);

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          ...pagination,
          total: responseData.total.value
        }
      });
    },
    // tableParams can not be dependency; it will throw endless cycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterByDateRange, filterByResults, filterSearch, getLogs]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCopyToClipboardOnClick = useCallback(
    async value => {
      try {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(value);
          notify(notificationTypes.success, {
            message: "Copied!",
            description: "Details have been copied to clipboard"
          });
        } else {
          // eslint-disable-next-line
          window.prompt("Copy to clipboard: Ctrl+C, Enter", value);
        }
      } catch (err) {
        notify(notificationTypes.error, {
          message: "Unable to copy to clipboard",
          description: ""
        });
      }
    },
    [notify, notificationTypes]
  );

  const columns = useMemo(
    () => [
      {
        title: "Log Date",
        key: "runOn",
        width: 125,
        defaultSortOrder: "descend",
        sorter: true,
        render: record => `${new Date(record._source.runOn).toLocaleString()}`
      },
      // Not sure if this is to be displayed or not?
      {
        title: "Data Name",
        key: "dataName",
        width: 175,
        sorter: true,
        render: record => record?._source.dataName
      },
      {
        title: "Run Id",
        key: "runId",
        width: 175,
        sorter: true,
        render: record => (
          <Typography.Paragraph
            ellipsis={{ rows: 2, expandable: false }}
            key={record?._source?.runId}
            className="column-paragraph-ellipsis"
          >
            {record?._source?.runId}
          </Typography.Paragraph>
        )
      },
      {
        title: "Results",
        key: "result",
        sorter: true,
        width: 175,
        render: record => (record._source.result || "").toUpperCase()
      },
      {
        title: "Details",
        key: "details",

        render: record =>
          !isEmpty(record?._source?.details) ? (
            <>
              <Typography.Paragraph
                ellipsis={{ rows: 2, expandable: false }}
                key={record?._id}
                className="column-paragraph-ellipsis"
                style={{ marginBottom: "2px" }}
              >
                {JSON.stringify(record._source.details)}
              </Typography.Paragraph>
              <Button
                icon={<CopyOutlined />}
                onClick={() => {
                  handleCopyToClipboardOnClick(
                    JSON.stringify(record._source.details)
                  );
                }}
                size="small"
              >
                Copy to Clipboard
              </Button>
            </>
          ) : (
            ""
          )
      }
    ],
    [handleCopyToClipboardOnClick]
  );

  if (errorGettingLogs) {
    return (
      <ComponentLoader
        hasErrors={!!errorGettingLogs}
        errorComponent="Unable to retrieve the data at this time"
      />
    );
  }

  return (
    <>
      <Row key="row-actions" gutter={[5, 5]} className="top-filter-row">
        <Col xs={12} md={8} lg={5}>
          <CustomDates
            label="Dates:"
            value={filterByDateRange}
            onChange={setFilterByDateRange}
          />
        </Col>
        <Col xs={12} md={8} lg={7}>
          <div style={{ width: "100%" }}>
            <CustomSearch
              label="Search:"
              placeholder="Search for Data Name, Run Id"
              onSearch={setFilterSearch}
            />
          </div>
        </Col>

        <Col xs={12} md={8} lg={4}>
          <div style={{ width: "100%" }}>
            <CustomSelect
              label="Results:"
              value={filterByResults}
              placeholder="Select result(s)..."
              onChange={setFilterByResults}
            >
              {[
                { label: "SUCCESS", value: "success" },
                { label: "FAILURE", value: "failure" }
              ].map(i => (
                <Option key={i.value} value={i.value}>
                  {i.label}
                </Option>
              ))}
            </CustomSelect>
          </div>
        </Col>
        <Col flex="auto">
          <Button
            type="primary"
            style={{
              marginLeft: "auto"
            }}
            onClick={() => fetchData()}
          >
            Refresh
          </Button>
        </Col>
      </Row>

      <Table
        className="table align-top"
        pagination={{
          ...tableParams.pagination,
          showTotal: total => `Total ${total} items`
        }}
        columns={columns}
        dataSource={data}
        rowKey={item => item._id}
        scroll={{ x: true }}
        onChange={fetchData}
        loading={loadingLogs}
      />
    </>
  );
};

export default Content;

import {
  FileSearchOutlined,
  FlagOutlined,
  FormOutlined,
  LinkOutlined
} from "@ant-design/icons";
import {
  highlightData,
  makeUniqueGroupings,
  toArray
} from "@salvus/shared/utils";
import { Button, Col, Pagination, Row, Tag, Tooltip, Typography } from "antd";
import classNames from "classnames";
import parse from "html-react-parser";
import { startCase, toNumber, uniq } from "lodash";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import AnimatedNumber from "../../../components/AnimatedNumber";
import ComponentLoader from "../../../components/ComponentLoader";
import SimilarItems from "../../../components/Learning/Results/SimilarItems";
import ResultCard, {
  ResultCardFooter,
  ResultCardHeader
} from "../../../components/ResultCard";
import TenantContext from "../../../context/TenantContext";
import lightVars from "../../../themes/lightVars";
import {
  DefaultRequestConfig,
  RequestMethodType,
  dataSearchEndpoint,
  useApi
} from "../../API";
import { useAuthorization } from "../../Auth/hooks";
import Activity from "./Activity";
import DiscoveriesContext from "./Context";
import Flagged from "./Flagged";

const defaultGutters = [18, 5];
const defaultPageSize = 50;

const Content = () => {
  const { hasSecurityRoles, adminRoles } = useAuthorization();

  const [data, setData] = useState();
  const [total, setTotal] = useState();
  const [selected, setSelected] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [activityInstructions, setActivityInstructions] = useState();
  const [learningsInstructions, setLearningsInstructions] = useState();
  const [flaggedInstructions, setFlaggedInstructions] = useState();
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);

  const canFlag = useMemo(
    () => hasSecurityRoles(adminRoles.discoveries),
    [adminRoles.discoveries, hasSecurityRoles]
  );

  const canActivity = useMemo(
    () =>
      hasSecurityRoles([
        ...adminRoles.discoveries,
        ...adminRoles.discoveriesCollaborator
      ]),
    [adminRoles, hasSecurityRoles]
  );

  const {
    request: getData,
    loading: loadingData,
    error: errorGettingData
  } = useApi(
    dataSearchEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const {
    filterContext: {
      topics,
      dataSources,
      dataProviders,
      dates,
      assets,
      search,
      mustIncludeAllSearchWords,
      activityStatus,
      activityActions
    },
    isReady
  } = useContext(DiscoveriesContext);

  const { activeTenantDataSources, activeTenantTopics, activeTenantAssets } =
    useContext(TenantContext);

  const fetchData = useCallback(
    async (page, pgSize) => {
      //
      const newPageNumber = !page ? 1 : toNumber(page);
      const newPageSize = pgSize || defaultPageSize;

      const createRequest = () => {
        const filters = {};

        if (toArray(dataProviders).length) {
          filters.dataProviders = dataProviders;
        }

        if (dates?.length === 2) {
          filters.dates = dates;
        }

        if (toArray(dataSources).length) {
          filters.dataSources = dataSources;
        }

        if (toArray(topics).length) {
          filters.topics = topics;
        }

        if (toArray(assets).length) {
          filters.assets = assets;
        }

        if (toArray(search).length) {
          filters.search = search;
        }

        if (mustIncludeAllSearchWords) {
          filters.mustIncludeAllSearchWords = mustIncludeAllSearchWords;
        }

        if (toArray(activityStatus).length) {
          filters.activityStatus = activityStatus;
        }

        if (toArray(activityActions).length) {
          filters.activityActions = activityActions;
        }

        return {
          filters,
          from: (newPageNumber - 1) * newPageSize,
          size: newPageSize
        };
      };

      //
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
      //
      const response = await getData({
        data: createRequest()
      });
      //
      setData(response.result.hits.hits);
      setTotal(response.result.hits.total.value);
    },
    [
      getData,
      dataProviders,
      dates,
      dataSources,
      topics,
      assets,
      search,
      mustIncludeAllSearchWords,
      activityStatus,
      activityActions
    ]
  );

  useEffect(() => {
    if (!isReady) {
      return;
    }

    fetchData();
  }, [fetchData, isReady]);

  const extractKeywords = dataItem => {
    const highlightCoordinates = [];
    const uniqKeywords = [];
    const nlpFromSearch = {};
    const nlpOther = {};

    activeTenantTopics
      .filter(kv => dataItem.autoGenerated.topics?.includes(kv.key))
      .forEach(parentKV => {
        const key = parentKV.key;
        const tags = [];

        const partOfSearch = !topics?.length ? true : topics.includes(key);

        // TODO: Keep this code
        // Get Algorithms
        // Object.entries(dataItem?.nlp[`${key}`]?.algorithms || {}).forEach(
        //   ([algorithmKey, algorithmValue]) => {
        //     const ordered = orderBy(algorithmValue, ["confidence"], ["desc"]);
        //     const topHit = ordered[0];
        //     if (topHit?.confidence >= NlpMinConfidenceScore) {
        //       tags.push(algorithmKey);
        //     }
        //   }
        // );

        // Get Keywords
        Object.entries(dataItem?.nlp[`${key}`]?.keywords || {}).forEach(
          ([keywordKey, keywordValues]) => {
            keywordValues.forEach(item => {
              if (partOfSearch) {
                highlightCoordinates.push(item.span);
                uniqKeywords.push(item?.fuzzy_word || keywordKey);
              }
              tags.push(
                item?.fuzzy_word
                  ? `${keywordKey}:(${item.fuzzy_word})`
                  : keywordKey
              );
            });
          }
        );

        if (tags.length) {
          if (partOfSearch) {
            nlpFromSearch[`${key}`] = {
              tags: uniq(tags),
              name: parentKV.name,
              color: parentKV.color
            };
          } else {
            nlpOther[`${key}`] = {
              tags: uniq(tags),
              name: parentKV.name,
              color: parentKV.color
            };
          }
        }
      });
    return {
      uniqKeywords: uniq(uniqKeywords),
      highlightCoordinates: makeUniqueGroupings(highlightCoordinates),
      nlpFromSearch,
      nlpOther
    };
  };

  const renderItem = dataItem => {
    const item = dataItem._source;
    const { nlpFromSearch, nlpOther, highlightCoordinates } =
      extractKeywords(item);

    const renderHeader = () => (
      <ResultCardHeader
        // title={`${item.metadata.name} #${dataItem._id}`}
        // url={item.metadata.endpoint}
        title={
          <Typography.Title level={5}>
            {`${item.metadata.name} #${dataItem._id}`}
          </Typography.Title>
        }
        date={new Date(item.metadata.publishedOn)}
        source={
          activeTenantDataSources.find(i => i.key === item.dataSourceKey)?.name
        }
      />
    );

    const renderFooter = () => (
      <ResultCardFooter
        actions={[
          <Tooltip key="Flagged" placement="top" title="Flag Discovery">
            <Button
              type="link"
              icon={<FlagOutlined />}
              disabled={!canFlag}
              onClick={() => {
                setFlaggedInstructions({
                  id: dataItem._id,
                  open: true,
                  onSave: () => {
                    setSelected(null);
                    setFlaggedInstructions(null);
                    setIsRefreshNeeded(true);
                  },
                  onCancel: () => {
                    setSelected(null);
                    setFlaggedInstructions(null);
                  }
                });
                setSelected(dataItem._id);
              }}
            />
          </Tooltip>,
          <Tooltip key="activity" placement="top" title="Activity">
            <Button
              type="link"
              icon={<FormOutlined />}
              disabled={!canActivity}
              onClick={() => {
                setActivityInstructions({
                  id: dataItem._id,
                  open: true,
                  onClose: () => {
                    setSelected(null);
                    setActivityInstructions(null);
                  }
                });
                setSelected(dataItem._id);
              }}
            />
          </Tooltip>,
          <Tooltip key="learnings" placement="top" title="Related Learnings">
            <Button
              type="link"
              icon={<FileSearchOutlined />}
              onClick={() => {
                setLearningsInstructions({
                  instructions: {
                    threshold: 0.7,
                    searchFor:
                      Object.entries(dataItem._source.data)
                        .filter(
                          ([key, _]) =>
                            ![
                              "date",
                              "time",
                              "author",
                              "name",
                              "endpoint"
                            ].some(v => key.toLowerCase().indexOf(v) > -1)
                        )
                        .map(([_, value]) => value)
                        ?.join(" ") ||
                      dataItem._source?.autoGenerated?.preprocessedText ||
                      dataItem._source?.autoGenerated?.summarizedText
                  },
                  open: true,
                  onClose: () => {
                    setSelected(null);
                    setLearningsInstructions(null);
                  }
                });
                setSelected(dataItem._id);
              }}
            />
          </Tooltip>,
          <Tooltip
            key="link-out"
            placement="top"
            title="Open the original report"
          >
            <Button
              type="link"
              icon={<LinkOutlined />}
              href={item.metadata.endpoint}
              target="_blank"
              rel="noreferrer noopener"
            />
          </Tooltip>
        ]}
      />
    );

    const renderSearchWordsDisplayer = () => {
      const matchedKeywords = uniq(
        (dataItem.matched_queries || []).map(q => q.split("~")[0])
      ).sort();
      return (
        matchedKeywords.length > 0 && (
          <div key="tags" className="tags">
            {matchedKeywords.map(keyword => (
              <Tag
                key={keyword}
                style={{
                  cursor: "pointer",
                  border: `1px solid ${lightVars["@shellRed"]}`,
                  color: lightVars["@shellRed"],
                  borderRadius: "25px"
                }}
              >
                <Tooltip title="Search word matched your filter">
                  {keyword}
                </Tooltip>
              </Tag>
            ))}
          </div>
        )
      );
    };

    const renderTags = () => {
      const tags = [
        activeTenantAssets.find(l => l.key === item?.autoGenerated?.asset)?.name
      ];
      return (
        <div className="tags" key="location_assets">
          <div className="tags-container">
            <Typography.Text
              className="h5Inline"
              style={{ marginRight: "4px" }}
            >
              Asset:{" "}
            </Typography.Text>{" "}
            {tags.map(tag => (
              <Typography.Text key={tag} style={{ marginRight: "4px" }}>
                {tag}
              </Typography.Text>
            ))}
          </div>
        </div>
      );
    };

    const renderKeywords = (entries, colorized) =>
      Object.entries(entries).map(([_, slugData]) => (
        <div className="tags" key={slugData.name}>
          <div className="tags-container">
            <Tag
              {...(colorized ? { color: slugData.color } : null)}
              key={slugData.name}
            >
              {startCase(slugData.name)}
            </Tag>

            <Typography.Text>{slugData.tags.join(", ")}</Typography.Text>
          </div>
        </div>
      ));

    const renderDataSection = dataItems => {
      let characterCounter = 0;
      return Object.entries(dataItems)
        .filter(([_, value]) => value)
        .map(([itemKey, itemValue]) => {
          const trimmedItemValue = itemValue.trim();
          const valueLength = characterCounter + trimmedItemValue.length + 1;
          const highlightedData = highlightData(
            trimmedItemValue,
            characterCounter,
            valueLength,
            highlightCoordinates
          );
          characterCounter = valueLength;

          return (
            <Fragment key={itemKey}>
              <Col xl={3} md={6} sm={6} className="col-item-prop">
                <Typography.Text style={{ fontWeight: "bold" }}>
                  {startCase(itemKey)}
                </Typography.Text>
              </Col>
              <Col xl={21} md={18} sm={18} className="col-item-value">
                <Typography.Text>{parse(highlightedData)}</Typography.Text>
              </Col>
            </Fragment>
          );
        });
    };
    return (
      <ResultCard
        key={dataItem._id}
        header={renderHeader()}
        footer={renderFooter()}
        selected={selected === dataItem?._id}
      >
        <Row>
          <Col>{renderSearchWordsDisplayer()}</Col>
        </Row>
        <Row key={`${dataItem._id}-data`} gutter={defaultGutters}>
          <Col xl={19} lg={18} sm={17} className="data-separator">
            <Row gutter={defaultGutters} className="row-item">
              {renderDataSection(item?.data || {})}
            </Row>
          </Col>
          <Col xl={5} lg={6} sm={7}>
            <Row
              key={`${dataItem._id}-extra`}
              gutter={defaultGutters}
              className={classNames("row-item")}
            >
              <Col lg={23} sm={20} className="tags col-item-value">
                {renderKeywords(nlpFromSearch, true)}
                {renderKeywords(nlpOther, false)}
                {renderTags()}
              </Col>
            </Row>
          </Col>
        </Row>
      </ResultCard>
    );
  };

  return (
    <>
      <SimilarItems
        open={learningsInstructions?.open}
        instructions={learningsInstructions?.instructions}
        title="Related Learnings"
        onClose={learningsInstructions?.onClose}
      />

      <Flagged
        open={flaggedInstructions?.open}
        id={flaggedInstructions?.id}
        onSave={flaggedInstructions?.onSave}
        onCancel={flaggedInstructions?.onCancel}
      />

      <Activity
        open={activityInstructions?.open}
        id={activityInstructions?.id}
        onClose={activityInstructions?.onClose}
      />

      <ComponentLoader
        isLoading={!errorGettingData && (!data || loadingData)}
        hasNoData={data && !data?.length}
        hasErrors={Boolean(errorGettingData)}
        errorComponent="Unable to retrieve the data at this time"
        noDataComponent="No data to show"
      >
        <div
          style={{
            display: "flex",
            margin: "0 0 12px 0",
            width: "100%",
            alignSelf: "center"
          }}
        >
          <div className="result-count">
            Discoveries:{" "}
            <span className="result-count-number">
              <AnimatedNumber value={Math.max(total, 0)} />
            </span>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button
              type="primary"
              style={{ zIndex: 999 }}
              disabled={!isRefreshNeeded}
              loading={loadingData}
              onClick={() => {
                fetchData();
                setIsRefreshNeeded(false);
              }}
            >
              Refresh
            </Button>
          </div>
        </div>
        <div className="results-container">
          {data?.map(item => renderItem(item))}
        </div>
        <Pagination
          className="results-pagination"
          key="pagination"
          size="small"
          total={total}
          showTotal={num => `Total ${num} Discoveries`}
          defaultCurrent={1}
          current={pageNumber}
          pageSize={pageSize}
          pageSizeOptions={[25, 50, 75, 100]}
          defaultPageSize={defaultPageSize}
          onChange={(currentPage, currentPageSize) => {
            fetchData(currentPage, currentPageSize);
          }}
          showSizeChanger
          hideOnSinglePage
        />
      </ComponentLoader>
    </>
  );
};

export default Content;

import React, { useMemo } from "react";
import MessageComponent from "../../components/MessageComponent";
import SettingsSideMenu from "../../components/SideMenus/SettingsSideMenu";
import PageLayout from "../../layouts/PageLayout";
import { useAuthorization } from "../../modules/Auth/hooks";
import Content from "../../modules/System/Subscriptions/Content";

import "./index.less";

const SubscriptionsPage = () => {
  const { isActiveTenantGlobal } = useAuthorization();

  const renderContent = useMemo(() => {
    if (isActiveTenantGlobal) {
      return (
        <MessageComponent
          title="Sorry!"
          message="You must select a Tenant before proceeding"
          type="danger"
        />
      );
    }

    return <Content />;
  }, [isActiveTenantGlobal]);

  return (
    <PageLayout
      className="subscriptions-page"
      side={{
        placement: "left",
        component: <SettingsSideMenu />
      }}
      header={{
        title: "User Subscriptions",
        description:
          "This page allows you to manage the subscriptions of each user that has access to this tenant. To add more users, please visit the User Management section."
      }}
    >
      {renderContent}
    </PageLayout>
  );
};

export default SubscriptionsPage;

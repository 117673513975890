import { OidcProvider } from "@axa-fr/react-oidc";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { PINGID } from "../config";
import { AuthMessage } from "../modules/Auth/components";
import { PingIdContext, PingIdProvider, usePingId } from "./PingIdAuth";

export const AuthProvider = PingIdProvider;
const AuthContext = PingIdContext;
export const useAuth = usePingId;

const onEvent = (configurationName, eventName, data) => {
  if (eventName !== "token_timer" || data?.timeLeft <= 0) {
    // eslint-disable-next-line no-console
    console.log(`Default: oidc:${configurationName}:${eventName}`, data);
  }
};

const loadingComponent = () => <AuthMessage message="Loading..." />;
const authenticatingComponent = () => (
  <AuthMessage message="Authenticating with PingID..." />
);
const sessionLostComponent = () => (
  <AuthMessage message="Your session was lost" isLoginRequired type="danger" />
);
const callbackSuccessComponent = () => (
  <AuthMessage message="Successfully Authenticated" />
);
const callbackErrorComponent = () => (
  <AuthMessage message="Authentication Error" type="danger" />
);
const serviceWorkerNotSupportedComponent = () => (
  <AuthMessage
    message="Service Worker is not supported by your Browser"
    type="danger"
  />
);

export const AuthContextProvider = ({ children }) => {
  const oidcHandlerRef = useRef({
    onEvent,
    LoginComponent: { sessionLostComponent }
  });
  return (
    <OidcProvider
      configurationName={PINGID.config_name}
      loadingComponent={loadingComponent}
      authenticatingComponent={authenticatingComponent}
      sessionLostComponent={() => oidcHandlerRef.current.LoginComponent}
      callbackSuccessComponent={callbackSuccessComponent} // you can inject your own call back success component
      callbackErrorComponent={callbackErrorComponent} // you can inject your own call back error component
      serviceWorkerNotSupportedComponent={serviceWorkerNotSupportedComponent}
      configuration={{
        client_id: PINGID.client_id, // oidc client id
        redirect_uri: PINGID.redirect_uri, // oidc redirect url
        scope: PINGID.scope, // oidc scope (you need to set "offline_access")
        authority: PINGID.authority,
        refresh_time_before_tokens_expiration_in_second: 30,
        service_worker_only: false, // default false
        storage: localStorage,
        // monitor_session: true,
        authority_configuration: {
          ...PINGID.metadata
        },
        extras: { response_type: PINGID.response_type }
      }}
      onEvent={(configurationName, eventName, data) =>
        oidcHandlerRef?.current.onEvent
          ? oidcHandlerRef.current.onEvent(configurationName, eventName, data)
          : onEvent(configurationName, eventName, data)
      }
    >
      <AuthProvider
        ref={oidcHandlerRef}
        env={{
          configName: PINGID.config_name
        }}
      >
        {children}
      </AuthProvider>
    </OidcProvider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;

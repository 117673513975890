import { Button, Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { ProjectLogo } from "../../../components/CoreComponents";
import { VersionText } from "../../../components/VersionText";
import { PROJECT_NAME, PROJECT_TAG_LINE } from "../../../config";

export const Login = ({ login, message }) => (
  <div className="cta-wrapper colored-background">
    <div className="cta-container">
      <div style={{ margin: 15 }}>
        <ProjectLogo
          projectName={PROJECT_NAME}
          projectTagline={PROJECT_TAG_LINE}
          animate
        />
      </div>
      {message ? (
        <Typography.Title level={2} type="danger">
          {message}
        </Typography.Title>
      ) : null}
      <Button
        type="primary"
        onClick={login}
        style={{ width: 200, height: 50, marginTop: 16 }}
      >
        Login with PingID
      </Button>

      <VersionText />
    </div>
  </div>
);

Login.propTypes = {
  message: PropTypes.string,
  login: PropTypes.func.isRequired
};

Login.defaultProps = {
  message: ""
};

import {
  CompressOutlined,
  DislikeOutlined,
  ExpandOutlined,
  FileSearchOutlined,
  FireFilled,
  LikeOutlined,
  LinkOutlined,
  ShareAltOutlined
} from "@ant-design/icons";
import { toArray } from "@salvus/shared/utils";
import {
  Button,
  Dropdown,
  Menu,
  Tag,
  Tooltip,
  Typography,
  notification
} from "antd";
import parse from "html-react-parser";
import { get, has, omit, startCase, uniq } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import sanitize from "sanitize-html";
import {
  DEFAULT_MARGIN,
  REACTION_TYPE,
  RESOURCE_MAPPER,
  SHORT_DESCRIPTION_LENGTH,
  THRESHOLD_MATCHING_FILTER_CRITERIA
} from "../../../constants/learning";
import { useAuth } from "../../../context/AuthContext";
import lightVars from "../../../themes/lightVars";
import { getLFIItemByID, updateRankings } from "../../../utils/api";
import {
  useCustomGETRequest,
  useCustomPUTRequest
} from "../../../utils/hooks/restRequest";
import CustomLink from "../../CustomLink";
import ResultCard, {
  ResultCardFooter,
  ResultCardHeader
} from "../../ResultCard";

import "./index.less";

const ItemLink = (resource, index, showDivider) => {
  const resourceData = useMemo(
    () => RESOURCE_MAPPER[resource.type.toLowerCase()],
    [resource.type]
  );
  return (
    <React.Fragment key={index}>
      <Tooltip
        placement="bottom"
        title={resource?.name || resource?.type || "Attachments"}
      >
        <Menu.Item
          key={index}
          icon={resourceData ? resourceData.icon : null}
          className="result-data-link"
        >
          <a
            href={resource.url}
            key={resource.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {resource?.name || resource?.type || "Attachments"}
          </a>
        </Menu.Item>
      </Tooltip>
      {showDivider ? <Menu.Divider key="divider" /> : null}
    </React.Fragment>
  );
};

const ItemLinks = ({ links }) => {
  const filteredLinks = links?.filter(link => link?.url); // Remove any that do not have a url
  if (!filteredLinks?.length) {
    return null;
  }

  const items = filteredLinks.map((resource, resourceIndex) =>
    ItemLink(resource, resourceIndex, resourceIndex < filteredLinks.length - 1)
  );

  return (
    <Dropdown
      overlay={<Menu className="result-data-links">{items}</Menu>}
      placement="topLeft"
    >
      <Button
        type="link"
        icon={<LinkOutlined />}
        style={{ padding: "4px 12px 4px 0" }}
      >
        {" "}
        {filteredLinks.length}
      </Button>
    </Dropdown>
  );
};

ItemLinks.propTypes = {
  links: PropTypes.array.isRequired
};

const RankingButton = ({ tooltipTriggerable, tooltipProps, ...props }) =>
  tooltipTriggerable ? (
    <Tooltip {...tooltipProps}>
      <Button type="link" {...props} />
    </Tooltip>
  ) : (
    <Button type="link" {...props} />
  );

RankingButton.propTypes = {
  tooltipTriggerable: PropTypes.bool.isRequired,
  tooltipProps: PropTypes.object.isRequired
};

const getUsersCountText = (isActive, rawAmount, isUserIncluded) => {
  const amount = Math.floor(Math.abs(rawAmount));
  let response = "";
  const otherUsers = isUserIncluded ? amount - 1 : amount;
  if (!isActive && otherUsers < 1) {
    return response;
  }
  if (isActive) {
    response += "You ";
  }
  if (otherUsers > 0) {
    if (isActive) {
      response += `and ${otherUsers} other user(s) `;
    } else {
      response += `${otherUsers} other user(s) `;
    }
  }
  return `${response}found this `;
};

const TagsDisplayer = ({ data, propKey, title }) => {
  const tags = useMemo(() => get(data, propKey, []), [data, propKey]);
  if (tags.length <= 0) {
    return null;
  }
  return (
    <div className="tags">
      <Typography.Text>{startCase(title)}</Typography.Text>
      <div className="tags-container">
        {uniq(tags)
          .filter(tag => tag.trim())
          .map(tag => (
            <Tag key={tag} style={{ margin: DEFAULT_MARGIN }}>
              {tag}
            </Tag>
          ))}
      </div>
    </div>
  );
};

TagsDisplayer.propTypes = {
  propKey: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

const KeywordsDisplayer = ({ data }) => {
  const matchedKeywords = useMemo(
    () => uniq((data?.matched_queries || []).map(q => q.split("~")[0])).sort(),
    [data]
  );

  return (
    matchedKeywords.length > 0 && (
      <div key="keywords" className="item-keywords-matched">
        {matchedKeywords.map(keyword => (
          <Tag
            key={keyword}
            style={{
              margin: DEFAULT_MARGIN,
              cursor: "pointer",
              border: "1px solid #D42E12",
              color: "#D42E12",
              borderRadius: "25px"
            }}
          >
            <Tooltip title="Keyword matched your filter">{keyword}</Tooltip>
          </Tag>
        ))}
      </div>
    )
  );
};

KeywordsDisplayer.propTypes = {
  data: PropTypes.object.isRequired,
  selectedKeywords: PropTypes.array.isRequired
};

const getNewReactionStatus = (currentReactionType, type, currentStatus) =>
  currentReactionType === type ? !currentStatus : false;

const getNewReactionBy = (
  currentReactionBy,
  newReactionStatus,
  accountIdentifier
) =>
  newReactionStatus
    ? uniq([...currentReactionBy, accountIdentifier])
    : currentReactionBy.filter(id => id !== accountIdentifier);

const getReactionTitle = (
  isReacted,
  currentItem,
  reactionTypePath,
  reactionTypeByPath,
  accountIdentifier
) => {
  const reactions = get(currentItem, `userRankings.${reactionTypePath}`, 0);
  const reactionBy = get(currentItem, `userRankings.${reactionTypeByPath}`, []);
  const usersCountText = getUsersCountText(
    isReacted,
    reactions,
    reactionBy.includes(accountIdentifier)
  );
  return usersCountText ? `${usersCountText}helpful` : "";
};

const getDefaultLink = data => {
  if (!data?.links.length) {
    return "#";
  }

  return data?.links[0].url;
};

const getNewCurrentItem = (body, currentState, newUserRankings) =>
  body
    ? {
        ...currentState,
        ...omit(body, ["_source", "ignored"]),
        ...body._source,
        userRankings: newUserRankings,
        id: body._id,
        links: toArray(body._source.links)
      }
    : currentState;

const RankingActions = ({ data, selectedKeywords, onItemReacted }) => {
  const { currentUser } = useAuth();
  const accountIdentifier = useMemo(
    () => get(currentUser, "uid", "test"),
    [currentUser]
  );
  const [currentItem, setCurrentItem] = useState(data);
  const { refetch: fetchLFIItem } = useCustomGETRequest(
    getLFIItemByID,
    data.id,
    false
  );
  const { loading: updatingRankings, putData: updateRankingsData } =
    useCustomPUTRequest(updateRankings);

  const isLiked = currentItem.userRankings
    ? currentItem.userRankings.likedBy.includes(accountIdentifier)
    : false;
  const isDisliked = currentItem.userRankings
    ? currentItem.userRankings.dislikedBy.includes(accountIdentifier)
    : false;

  const onReactItem = useCallback(
    async reactionType => {
      try {
        const { likedBy, dislikedBy } = currentItem.userRankings;
        const newLikeStatus = getNewReactionStatus(
          reactionType,
          REACTION_TYPE.LIKE,
          isLiked
        );
        const newDislikeStatus = getNewReactionStatus(
          reactionType,
          REACTION_TYPE.DISLIKE,
          isDisliked
        );

        const newLikedBy = getNewReactionBy(
          likedBy,
          newLikeStatus,
          accountIdentifier
        );
        const newDislikedBy = getNewReactionBy(
          dislikedBy,
          newDislikeStatus,
          accountIdentifier
        );

        const newLikes = newLikedBy.length;
        const newDislikes = newDislikedBy.length;

        const newUserRankings = {
          dislikes: Math.max(newDislikes, 0.1),
          dislikedBy: newDislikedBy,
          likes: Math.max(newLikes, 0.1),
          likedBy: newLikedBy
        };

        await updateRankingsData(
          currentItem.id,
          {
            ...currentItem.userRankings,
            ...newUserRankings,
            newLikeStatus,
            newDislikeStatus
          },
          selectedKeywords.map(keyword => keyword.value)
        );

        const { body } = await fetchLFIItem();

        setCurrentItem(currentState =>
          getNewCurrentItem(body, currentState, newUserRankings)
        );
        await onItemReacted();
      } catch (err) {
        notification.error({
          message: "Unable to like this LFI",
          description: err.message || ""
        });
      }
    },
    [
      updateRankingsData,
      accountIdentifier,
      fetchLFIItem,
      isLiked,
      isDisliked,
      currentItem,
      selectedKeywords,
      onItemReacted
    ]
  );
  return (
    <>
      <RankingButton
        tooltipProps={{
          title: getReactionTitle(
            isLiked,
            currentItem,
            "likes",
            "likedBy",
            accountIdentifier
          )
        }}
        tooltipTriggerable={currentItem.userRankings.likes >= 1}
        className={`ranking-button like-button ${isLiked ? "like-active" : ""}`}
        disabled={updatingRankings}
        style={{ padding: "4px 12px 4px 0" }}
        onClick={async e => {
          e.preventDefault();
          await onReactItem(REACTION_TYPE.LIKE);
        }}
        icon={<LikeOutlined />}
      >
        {` ${currentItem.userRankings.likedBy.length}`}
      </RankingButton>
      <RankingButton
        tooltipProps={{
          title: getReactionTitle(
            isDisliked,
            currentItem,
            "dislikes",
            "dislikedBy",
            accountIdentifier
          )
        }}
        style={{ padding: "4px 12px 4px 0" }}
        tooltipTriggerable={currentItem.userRankings.dislikes >= 1}
        className={`ranking-button dislike-button ${
          isDisliked ? "dislike-active" : ""
        }`}
        disabled={updatingRankings}
        onClick={async e => {
          e.preventDefault();
          await onReactItem(REACTION_TYPE.DISLIKE);
        }}
        icon={<DislikeOutlined />}
      >
        {` ${currentItem.userRankings.dislikedBy.length}`}
      </RankingButton>
    </>
  );
};

RankingActions.propTypes = {
  data: PropTypes.object.isRequired,
  selectedKeywords: PropTypes.array.isRequired,
  onItemReacted: PropTypes.func.isRequired
};

const getHighlightedItem = (key, data) =>
  has(data, `highlight.${key}`)
    ? get(data, `highlight.${key}`).reduce(
        (acc, highlightedArea) =>
          acc.replace(highlightedArea.replace(/<\/*em>/g, ""), highlightedArea),
        data[key].replace(
          /(\sstyle="[-\w\s:;]*)((color:|font-\w*:)[\w\s]*;)([-\w\s:;]*")/gim,
          ""
        )
      ) // TODO need a better way to remove html tag
    : get(data, key, "");

const ResultsInAttachments = ({ data }) => {
  if (
    !Object.keys(data?.highlight || {}).filter(x => x.startsWith("attachments"))
      .length
  ) {
    return null;
  }

  if (data?.attachments?.length === 1) {
    const linkItem = data?.links?.filter(
      x => x.name === data?.attachments?.[0]?.fileName
    );

    return (
      <div className="item-footer">
        <Typography.Text>
          One or more Keywords were found in this attachment:{" "}
          <a
            href={linkItem?.[0].url}
            className="item-link"
            rel="noopener noreferrer"
            target="_blank"
          >
            {linkItem?.[0].name}
          </a>
        </Typography.Text>
      </div>
    );
  }

  return (
    <div className="item-footer">
      <Typography.Text>
        One or more Keywords were found in the attachments
      </Typography.Text>
    </div>
  );
};

ResultsInAttachments.propTypes = {
  data: PropTypes.object.isRequired
};

const ShareAction = ({ data }) => (
  <Tooltip placement="top" title="Share">
    <Button
      className="share-button"
      type="link"
      style={{ padding: "4px 12px 4px 0" }}
      onClick={() => {
        try {
          const url = getDefaultLink(data);
          if (navigator.clipboard) {
            navigator.clipboard.writeText(url);
            notification.success({
              message: "LFI link is copied in clipboard",
              description:
                "To share this LFI, simply paste (Ctrl + V) to receiver"
            });
          } else {
            // eslint-disable-next-line
            window.prompt("Copy to clipboard: Ctrl+C, Enter", url);
          }
        } catch (err) {
          notification.error({
            message: "Unable to share LFI",
            description: "Your browser may not support this functionality"
          });
        }
      }}
      icon={<ShareAltOutlined />}
    />
  </Tooltip>
);

ShareAction.propTypes = {
  data: PropTypes.object.isRequired
};

const SimilarIncidentAction = ({ onClick, showSimilarIncident }) =>
  showSimilarIncident && (
    <Tooltip placement="top" title="Find Similar">
      <Button
        type="link"
        icon={<FileSearchOutlined />}
        style={{ padding: "4px 12px 4px 0" }}
        onClick={() => {
          onClick();
        }}
      />
    </Tooltip>
  );

SimilarIncidentAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  showSimilarIncident: PropTypes.bool.isRequired
};

const DetailedDescription = ({ data, expanded, selectedActivityList }) => (
  <>
    <Typography.Text className="description">
      {parse(sanitize(getHighlightedItem("description", data)))}
    </Typography.Text>

    {expanded && (
      <div style={{ marginTop: "16px" }}>
        {data?.activityTypes &&
          data?.activityTypes.filter(act => act.trim()).length > 0 && (
            <div className="tags">
              <Typography.Text>Activity Types</Typography.Text>
              <div className="tags-container">
                {data?.activityTypes
                  .filter(act => act.trim())
                  .map(activityType => (
                    <Tag
                      key={activityType}
                      color={
                        selectedActivityList.includes(
                          activityType.toLowerCase()
                        )
                          ? lightVars["@shellRed"]
                          : ""
                      }
                      style={{ margin: DEFAULT_MARGIN }}
                    >
                      {selectedActivityList.includes(
                        activityType.toLowerCase()
                      ) ? (
                        <Tooltip title="Activity Type matched your filter">
                          {activityType}
                        </Tooltip>
                      ) : (
                        activityType
                      )}
                    </Tag>
                  ))}
              </div>
            </div>
          )}
        <TagsDisplayer data={data} propKey="discipline" title="Disciplines" />
        <TagsDisplayer data={data} propKey="tags" title="Tags" />
      </div>
    )}
  </>
);

DetailedDescription.propTypes = {
  data: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  selectedActivityList: PropTypes.array.isRequired
};

const Item = ({
  data,
  selectedKeywords,
  selectedActivities,
  showSimilarIncident,
  onItemReacted,
  onOpenLink,
  updateHeight,
  onItemSelected,
  selected
}) => {
  const selectedActivityList = useMemo(
    () => selectedActivities.map(activityType => activityType.toLowerCase()),
    [selectedActivities]
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    updateHeight(height);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  const measuredRef = useCallback(
    node => {
      if (node != null) {
        setHeight(node.getBoundingClientRect().height);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExpanded]
  );

  const renderHeader = () => {
    const sourceUrl = getDefaultLink(data);
    const renderTitle = () => (
      <CustomLink href={sourceUrl} onClick={onOpenLink}>
        {get(data, "_score", 0) > THRESHOLD_MATCHING_FILTER_CRITERIA ? (
          <Tooltip title="This result closely matches your filter criteria">
            <Typography.Title level={5}>
              <FireFilled
                style={{ marginRight: 4, color: lightVars["@shellRed"] }}
              />
              {parse(getHighlightedItem("title", data))}
            </Typography.Title>
          </Tooltip>
        ) : (
          <Typography.Title level={5}>
            {parse(getHighlightedItem("title", data))}
          </Typography.Title>
        )}
      </CustomLink>
    );

    return (
      <ResultCardHeader
        title={renderTitle()}
        date={new Date(data?.submittedDate)}
        source={data?.sourceSlug.toUpperCase()}
      />
    );
  };

  const renderFooter = () => (
    <ResultCardFooter
      actions={[
        <RankingActions
          key="ranking-actions"
          data={data}
          selectedKeywords={selectedKeywords}
          onItemReacted={onItemReacted}
        />,
        <ItemLinks links={data?.links} key="item-links" />,
        <SimilarIncidentAction
          key="similar-incidents"
          showSimilarIncident={showSimilarIncident}
          onClick={() => {
            onItemSelected({
              open: true,
              instructions: {
                searchFor: `${data?.title} ${data?.description}`
              },
              selected: data.id
            });
          }}
        />,
        <ShareAction data={data} key="share-action" />,
        <Button
          key="expand-collapse"
          style={{ padding: "4px 12px 4px 0" }}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          type="link"
          icon={isExpanded ? <CompressOutlined /> : <ExpandOutlined />}
        />
      ]}
    />
  );

  return (
    <div ref={measuredRef}>
      <ResultCard
        className="learning-page"
        key={data.id}
        header={renderHeader()}
        footer={renderFooter()}
        selected={selected}
      >
        <div
          className={
            !isExpanded
              ? `collapsed ${
                  data?.description.length > SHORT_DESCRIPTION_LENGTH
                    ? "long-description"
                    : ""
                }`
              : ""
          }
        >
          <KeywordsDisplayer data={data} selectedKeywords={selectedKeywords} />

          <DetailedDescription
            data={data}
            expanded={isExpanded}
            selectedActivityList={selectedActivityList}
          />
        </div>

        <ResultsInAttachments data={data} selectedKeywords={selectedKeywords} />
      </ResultCard>
      <div style={{ height: "24px", width: "100%", clear: "both" }} />
    </div>
  );
};

Item.propTypes = {
  data: PropTypes.shape({
    _score: PropTypes.number,
    sourceSlug: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
    discipline: PropTypes.arrayOf(PropTypes.string),
    activityTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    links: PropTypes.any,
    highlight: PropTypes.object,
    description: PropTypes.string.isRequired,
    submittedDate: PropTypes.string,
    userRankings: PropTypes.object.isRequired
  }).isRequired,
  onItemReacted: PropTypes.func,
  showSimilarIncident: PropTypes.bool,
  onOpenLink: PropTypes.func,
  selectedActivities: PropTypes.arrayOf(PropTypes.string),
  selectedKeywords: PropTypes.arrayOf(PropTypes.string),
  updateHeight: PropTypes.func,
  onItemSelected: PropTypes.func,
  selected: PropTypes.bool
};

Item.defaultProps = {
  selectedKeywords: [],
  selectedActivities: [],
  showSimilarIncident: false,
  onItemReacted: () => {},
  updateHeight: () => {},
  onItemSelected: () => {},
  selected: false,
  onOpenLink: () => {}
};

export default Item;

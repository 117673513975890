import { TenantRoles } from "@salvus/shared/modules/authorization";
import { Button, Form } from "antd";
import { set, startCase } from "lodash";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import CustomModal from "../../../components/CustomModal";
import CustomSelect from "../../../components/CustomSelect";
import LayoutContext from "../../../context/LayoutContext";
import TenantContext from "../../../context/TenantContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  useApi,
  userRolesEndpoint
} from "../../API";

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { flex: 1 }
};

const TenantRolesForm = ({ open, onCancel, onSave, data }) => {
  const { activeTenantKey } = useContext(TenantContext);
  const { notify, notificationTypes } = useContext(LayoutContext);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [tenantRoles, setTenantRoles] = useState([]);

  const { resetFields } = form;

  const { request: putUserRoles } = useApi(
    userRolesEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const modalTitle = data
    ? `Assign Tenant Role(s) to ${data._source.firstName} ${data._source.lastName}`
    : "Assign Tenant Role(s)";

  const optionsData = useMemo(
    () => TenantRoles.map(r => ({ value: r, label: startCase(r) })),
    []
  );

  const fetchData = useCallback(() => {
    resetFields();
    setTenantRoles(data._source?.tenantRoles?.[`${activeTenantKey}`] || []);
  }, [activeTenantKey, data, resetFields]);

  useEffect(() => {
    if (!form || !data || !open) {
      return;
    }

    fetchData();
  }, [data, open, form, fetchData]);

  const handleCleanup = useCallback(async () => {
    resetFields();
    setIsDirty(false);
    setSaving(false);
    setTenantRoles([]);
  }, [resetFields]);

  const handleOnCancel = useCallback(async () => {
    handleCleanup();
    onCancel();
  }, [onCancel, handleCleanup]);

  const handleRolesOnChange = useCallback(async values => {
    setTenantRoles(values);
    setIsDirty(true);
  }, []);

  const handleOnSave = useCallback(async () => {
    setSaving(true);
    try {
      const id = data?._id;

      const payload = {};
      set(payload, `tenantRoles.${activeTenantKey}`, tenantRoles || []);

      await putUserRoles({
        url: `${userRolesEndpoint}/${id}`,
        data: payload
      });

      const notifyMessage = tenantRoles?.length
        ? `${data?._source?.firstName} ${
            data?._source?.lastName
          } has been assigned ${tenantRoles
            .map(i => startCase(i))
            .join(", ")} role(s) successfully`
        : `${data?._source?.firstName} ${data?._source?.lastName} has been removed from all tenant role(s) successfully`;

      notify(notificationTypes.success, {
        message: "Tenant Role(s) Assigned",
        description: notifyMessage
      });

      onSave(payload);
      handleCleanup();
    } catch (err) {
      setSaving(false);
      notify(notificationTypes.error, {
        message: "Unable to assign tenant role(s)",
        description: err?.response?.data?.message || err.message || ""
      });
    }
  }, [
    data,
    activeTenantKey,
    tenantRoles,
    putUserRoles,
    notify,
    notificationTypes,
    onSave,
    handleCleanup
  ]);

  return !open ? null : (
    <CustomModal
      key="tenant-role-modal"
      title={modalTitle}
      open={open}
      onCancel={handleOnCancel}
      footer={[
        <div style={{ flex: "1 1 0%", marginTop: "10px" }} key="actions">
          <Button key="cancel" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={saving}
            onClick={() => form.submit()}
            disabled={saving || !isDirty}
          >
            Save
          </Button>
        </div>
      ]}
    >
      <Form
        key="form"
        form={form}
        {...formItemLayout}
        className="form-slim"
        onFinish={handleOnSave}
        labelAlign="left"
      >
        <Form.Item
          name="tenantRoles"
          label="Tenant Role(s)"
          key="tenantRoles"
          style={{ margin: "25px 0" }}
        >
          <div>
            <CustomSelect
              value={tenantRoles}
              placeholder="Select role(s)..."
              direction="vertical"
              required
              onChange={handleRolesOnChange}
              optionsData={optionsData}
              options={optionsData}
              allowSelectAll
              allowDeselectAll
            />
          </div>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

TenantRolesForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  data: PropTypes.object
};

TenantRolesForm.defaultProps = {
  open: false,
  onCancel: () => {},
  onSave: () => {},
  data: null
};

export default TenantRolesForm;

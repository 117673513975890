import { Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";
import AudioSpinner from "../Spinners/AudioSpinner";

import "./index.less";

const ComponentLoader = ({
  isLoading,
  loadingComponent,
  hasErrors,
  errorComponent,
  hasNoData,
  noDataComponent,
  children
}) => {
  const renderLoading = () => (
    <div className="component-loader-loading">
      {React.isValidElement(loadingComponent) ? (
        loadingComponent
      ) : (
        <AudioSpinner />
      )}
    </div>
  );

  const renderErrors = () => (
    <div className="component-loader-error">
      {React.isValidElement(errorComponent) ? (
        errorComponent
      ) : (
        <Typography.Text type="danger" style={{ fontSize: "16px" }}>
          {errorComponent}
        </Typography.Text>
      )}
    </div>
  );

  const renderNoData = () => (
    <div className="component-loader-nodata">
      {React.isValidElement(noDataComponent) ? (
        noDataComponent
      ) : (
        <Typography.Text type="warning" style={{ fontSize: "16px" }}>
          {noDataComponent}
        </Typography.Text>
      )}
    </div>
  );

  const renderComponent = () => {
    if (hasErrors) {
      return renderErrors();
    }

    if (isLoading) {
      return renderLoading();
    }

    if (hasNoData) {
      return renderNoData();
    }

    return children;
  };

  return renderComponent();
};

ComponentLoader.propTypes = {
  isLoading: PropTypes.bool,
  loadingComponent: PropTypes.element,
  hasErrors: PropTypes.bool,
  errorComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasNoData: PropTypes.bool,
  noDataComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node
};

ComponentLoader.defaultProps = {
  isLoading: false,
  loadingComponent: null,
  hasErrors: false,
  errorComponent: null,
  hasNoData: false,
  noDataComponent: null,
  children: null
};

export default ComponentLoader;

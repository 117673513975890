import { Select } from "antd";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import CustomSelect from "../../../../components/CustomSelect";
import TenantContext from "../../../../context/TenantContext";

const defaultClassName = "topics-filter";

const { Option } = Select;

const AssetsSelect = ({ label, info, placeholder, direction, ...rest }) => {
  const { activeTenantAssets } = useContext(TenantContext);

  return (
    <div style={{ width: "100%" }}>
      <CustomSelect
        className={defaultClassName}
        label={label}
        info={info}
        placeholder={placeholder}
        direction={direction}
        optionsData={activeTenantAssets}
        {...rest}
      >
        {activeTenantAssets.map(i => (
          <Option key={i.key} value={i.key}>
            {i.name}
          </Option>
        ))}
      </CustomSelect>
    </div>
  );
};

AssetsSelect.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  placeholder: PropTypes.string,
  direction: PropTypes.oneOf(["horizontal", "vertical"])
};

AssetsSelect.defaultProps = {
  label: "Assets",
  info: "Choose the asset to filter your results.",
  placeholder: "Select Asset(s)...",
  direction: "vertical"
};

export default AssetsSelect;

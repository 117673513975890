import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Divider, Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";

import "./index.less";

const PageHeader = ({ title, description, showDivider, onBack, actions }) => {
  const titleStyle = description
    ? { marginBottom: "0px" }
    : { marginBottom: "24px" };
  const descriptionStyle = showDivider
    ? { fontSize: "12px", marginBottom: "0px" }
    : { fontSize: "12px", marginBottom: "12px" };

  return (
    <>
      <div className="page-header">
        {onBack && (
          <Button icon={<ArrowLeftOutlined />} type="link" onClick={onBack} />
        )}
        <div
          style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}
        >
          <Typography.Title level={4} style={titleStyle}>
            {title}
          </Typography.Title>
          {description && (
            <Typography.Paragraph type="secondary" style={descriptionStyle}>
              {description}
            </Typography.Paragraph>
          )}
        </div>
        {actions && (
          <div style={{ display: "flex", alignSelf: "center" }}>{actions}</div>
        )}
      </div>
      {showDivider && <Divider style={{ marginTop: "0px" }} />}
    </>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  showDivider: PropTypes.bool,
  onBack: PropTypes.func,
  actions: PropTypes.node
};

PageHeader.defaultProps = {
  description: "",
  showDivider: false,
  onBack: null,
  actions: null
};

export default PageHeader;

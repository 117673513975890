import { GlobalOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import MapChart from "../../components/Learning/MapChart";
import Countries from "../../components/Learning/MapChart/country";
import LearningContext from "./LearningContext";

const defaultClassName = "locations-filter";

const LocationsFilter = ({ label, info, placeholder, direction, ...rest }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    filterContext: { selectedCountries },
    filterOperations: { setCountries, toggleCountries }
  } = useContext(LearningContext);

  return (
    <>
      <Modal
        open={modalVisible}
        footer={null}
        onCancel={() => {
          setModalVisible(false);
        }}
        centered
        title="Select Locations"
        width="65%"
        zIndex="99999"
      >
        <MapChart
          selectedCountries={selectedCountries}
          onCountryClick={country => toggleCountries(country)}
        />
      </Modal>

      <div style={{ width: "100%" }}>
        <CustomSelect
          className={defaultClassName}
          label={label}
          info={info}
          placeholder={placeholder}
          direction={direction}
          value={selectedCountries}
          onChange={setCountries}
          options={Object.keys(Countries).map(key => ({
            value: key,
            label: Countries[key]
          }))}
          extra={
            <Button
              icon={<GlobalOutlined />}
              style={{ marginLeft: "5px" }}
              type="primary"
              onClick={() => {
                setModalVisible(true);
              }}
            />
          }
          {...rest}
        />
      </div>
    </>
  );
};

LocationsFilter.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  placeholder: PropTypes.string,
  direction: PropTypes.oneOf(["horizontal", "vertical"])
};

LocationsFilter.defaultProps = {
  label: "Locations",
  info: "Filter by Locations.",
  placeholder: "Select Locations...",
  direction: "vertical"
};

export default LocationsFilter;

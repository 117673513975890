import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Tabs,
  Typography
} from "antd";
import { get, set, startCase } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import ComponentLoader from "../../../components/ComponentLoader";
import CustomSelect from "../../../components/CustomSelect";
import { useAuth } from "../../../context/AuthContext";
import LayoutContext from "../../../context/LayoutContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  useApi,
  userSubscriptionsEndpoint,
  usersEndpoint
} from "../../API";
import { useAuthorization } from "../../Auth/hooks";
import AssetsSelect from "../../Investigate/components/AssetsSelect";
import TopicsSelect from "../../Investigate/components/TopicsSelect";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
    xl: { span: 4 }
  },
  wrapperCol: { flex: 1 }
};

const tailLayout = {
  wrapperCol: {
    xs: { offset: 12, span: 12 },
    sm: { offset: 10, span: 14 },
    md: { offset: 8, span: 16 },
    xl: { offset: 4, span: 20 }
  }
};

const { Option } = Select;

const Content = () => {
  const { currentUser } = useAuth();
  const { notify, notificationTypes } = useContext(LayoutContext);
  const { isActiveTenantGlobal, activeTenantKey } = useAuthorization();

  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [saving, setSaving] = useState(false);
  const [subscriptions, setSubscriptions] = useState({});
  const [isDirty, setIsDirty] = useState(false);

  const {
    request: getUser,
    loading: loadingUser,
    error: errorGettingUser
  } = useApi(usersEndpoint, RequestMethodType.GET, DefaultRequestConfig, false);

  const { request: putUserSubscription } = useApi(
    userSubscriptionsEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const fetchData = useCallback(async () => {
    const response = await getUser({
      url: `${usersEndpoint}/${currentUser?.id || "me"}`
    });
    const responseData = response?.result || {};

    setData(responseData);

    const subscriptionsData = get(
      responseData?._source,
      `subscriptions.${activeTenantKey}.discoveriesReport`,
      {}
    );
    setSubscriptions(subscriptionsData);
  }, [activeTenantKey, currentUser, getUser]);

  useEffect(() => {
    if (!currentUser || isActiveTenantGlobal || !activeTenantKey) {
      return;
    }

    fetchData();
  }, [currentUser, fetchData, isActiveTenantGlobal, activeTenantKey]);

  const isSubscribed = useMemo(
    () => Boolean(subscriptions?.subscribed),
    [subscriptions]
  );

  const handleCleanup = useCallback(async () => {
    setIsDirty(false);
    setSaving(false);
  }, []);

  const handleSubscibeOnChange = useCallback(async value => {
    setIsDirty(true);
    setSubscriptions({
      subscribed: value,
      dataProviderKeys: value ? ["utl"] : [],
      topicKeys: [],
      assetKeys: []
    });
  }, []);

  const handleTopicsOnChange = useCallback(async values => {
    setIsDirty(true);
    setSubscriptions(prev => ({
      ...prev,
      topicKeys: values || []
    }));
  }, []);

  const handleAssetsOnChange = useCallback(async values => {
    setIsDirty(true);
    setSubscriptions(prev => ({
      ...prev,
      assetKeys: values || []
    }));
  }, []);

  const handleOnReset = useCallback(() => {
    handleCleanup();
    fetchData();
  }, [fetchData, handleCleanup]);

  const handleOnSave = useCallback(async () => {
    setSaving(true);
    try {
      const id = data?._id;
      const payload = {
        subscriptions: get(data?._source, "subscriptions", {})
      };

      set(
        payload.subscriptions,
        `${activeTenantKey}.discoveriesReport`,
        subscriptions || {}
      );

      await putUserSubscription({
        url: `${userSubscriptionsEndpoint}/${id}`,
        data: payload
      });

      notify(notificationTypes.success, {
        message: "Subscriptions Updated",
        description: "Your subscriptions have successfully updated"
      });

      // eslint-disable-next-line no-promise-executor-return
      await new Promise(r => setTimeout(r, 500));
      await fetchData();

      handleCleanup();
    } catch (err) {
      setSaving(false);
      notify(notificationTypes.error, {
        message: "Unable to update your subscriptions",
        description: err?.response?.data?.message || err.message || ""
      });
    }
  }, [
    data,
    putUserSubscription,
    notify,
    notificationTypes,
    fetchData,
    handleCleanup,
    activeTenantKey,
    subscriptions
  ]);

  const renderRolesTab = () => (
    <>
      <Typography.Text
        style={{ marginBottom: "25px", display: "block" }}
        type="secondary"
      >
        {`These are your security roles for ${startCase(
          activeTenantKey
        )}. If you have profiles with other tenants, change tenant in the top right corner`}
      </Typography.Text>
      <Row key="row-user" gutter={[10, 10]} type="flex" align="middle">
        <Col xs={24} sm={10} md={8} xl={4}>
          <Typography.Text>System Roles</Typography.Text>
        </Col>
        <Col xs={24} sm={14} md={16} xl={20}>
          {currentUser?.systemRoles?.length ? (
            <div>
              {currentUser.systemRoles?.map(i => startCase(i)).join(", ")}
            </div>
          ) : (
            <div>
              <em>None</em>
            </div>
          )}
        </Col>

        {activeTenantKey ? (
          <>
            <Col xs={24} sm={10} md={8} xl={4}>
              <Typography.Text>Tenant Roles</Typography.Text>
            </Col>
            <Col xs={24} sm={14} md={16} xl={20}>
              {currentUser?.tenantRoles?.[`${activeTenantKey}`]?.length ? (
                <div>
                  {currentUser.tenantRoles[`${activeTenantKey}`]
                    ?.map(i => startCase(i))
                    .join(", ")}
                </div>
              ) : (
                <div>
                  <em>None</em>
                </div>
              )}
            </Col>

            <Col xs={24} sm={10} md={8} xl={4}>
              <Typography.Text>Resource Roles</Typography.Text>
            </Col>
            <Col xs={24} sm={14} md={16} xl={20}>
              {currentUser?.resourceRoles?.[`${activeTenantKey}`]?.length ? (
                <div>
                  {currentUser.resourceRoles[`${activeTenantKey}`]
                    ?.map(i => startCase(i))
                    .join(", ")}
                </div>
              ) : (
                <div>
                  <em>None</em>
                </div>
              )}
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );

  const renderSubscriptionsTab = () => {
    if (isActiveTenantGlobal || !activeTenantKey) {
      return (
        <Typography.Title level={5}>
          There are no subscriptions to show.
        </Typography.Title>
      );
    }
    return (
      <ComponentLoader
        isLoading={!currentUser || !data || loadingUser}
        hasErrors={Boolean(errorGettingUser)}
        errorComponent="Unable to retrieve the data at this time"
      >
        <Typography.Text
          style={{ marginBottom: "25px", display: "block" }}
          type="secondary"
        >
          {`These are your subscriptions for ${startCase(
            activeTenantKey
          )}. If you have profiles with other tenants, change tenant in the top right corner`}
        </Typography.Text>
        <Form
          form={form}
          {...formItemLayout}
          className="form-slim"
          onFinish={handleOnSave}
          labelAlign="left"
          colon={false}
        >
          <Form.Item label="Receive Daily Discoveries Report?">
            <div>
              <Checkbox
                checked={subscriptions?.subscribed}
                onChange={e => {
                  setIsDirty(true);
                  handleSubscibeOnChange(e.target.checked);
                }}
              />
            </div>
          </Form.Item>

          {isSubscribed ? (
            <>
              <Form.Item
                key="dataProviders"
                name="dataProviders"
                label="Data Providers"
              >
                <div>
                  <CustomSelect
                    label=""
                    value={subscriptions?.dataProviderKeys}
                    placeholder=""
                    direction="vertical"
                    mode=""
                    disabled
                  >
                    {[{ label: "UTL", value: "UTL" }].map(i => (
                      <Option key={i.value} value={i.value}>
                        {i.label}
                      </Option>
                    ))}
                  </CustomSelect>
                </div>
              </Form.Item>
              <Form.Item label="Topics">
                <div>
                  <TopicsSelect
                    label=""
                    direction="vertical"
                    value={subscriptions?.topicKeys}
                    allowSelectAll
                    allowDeselectAll
                    onChange={handleTopicsOnChange}
                  />
                </div>
              </Form.Item>
              <Form.Item label="Assets">
                <div>
                  <AssetsSelect
                    label=""
                    direction="vertical"
                    value={subscriptions?.assetKeys}
                    allowSelectAll
                    allowDeselectAll
                    onChange={handleAssetsOnChange}
                  />
                </div>
              </Form.Item>
            </>
          ) : null}

          {isDirty || saving ? (
            <Form.Item {...tailLayout}>
              <Button
                key="cancel"
                onClick={handleOnReset}
                disabled={saving || !isDirty}
              >
                Reset
              </Button>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={saving}
                disabled={saving || !isDirty}
                style={{ marginLeft: "8px" }}
              >
                Save
              </Button>
            </Form.Item>
          ) : null}
        </Form>
      </ComponentLoader>
    );
  };

  return (
    <Card className="container-card">
      <Row key="row-user" gutter={[10, 10]} type="flex" align="middle">
        <Col xs={24} sm={10} md={8} xl={4}>
          <Typography.Text>ID</Typography.Text>
        </Col>
        <Col xs={24} sm={14} md={16} xl={20}>
          <Typography.Text>{currentUser.uid}</Typography.Text>
        </Col>
        <Col xs={24} sm={10} md={8} xl={4}>
          <Typography.Text>Name</Typography.Text>
        </Col>
        <Col xs={24} sm={14} md={16} xl={20}>
          <Typography.Text>{currentUser.displayName}</Typography.Text>
        </Col>
        <Col xs={24} sm={10} md={8} xl={4}>
          <Typography.Text>Email</Typography.Text>
        </Col>
        <Col xs={24} sm={14} md={16} xl={20}>
          <Typography.Text>{currentUser.email}</Typography.Text>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        style={{ marginTop: "45px" }}
        onChange={setActiveTab}
        items={[
          {
            key: "1",
            label: "Roles",
            children: renderRolesTab()
          },
          {
            key: "2",
            label: "Subscriptions",
            children: renderSubscriptionsTab()
          }
        ]}
      />
    </Card>
  );
};

export default Content;

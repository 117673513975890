import { Button, Spin, Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { ProjectLogo } from "../../../components/CoreComponents";
import { PROJECT_NAME, PROJECT_TAG_LINE } from "../../../config";

/**
 * @param {String} message
 * @param {import("antd/lib/typography/Base").BaseType=} type
 * @param {Boolean=} isLoginRequired
 * @return {JSX.Element}
 */
export const AuthMessage = ({ message, type, isLoginRequired }) => (
  <div className="cta-wrapper colored-background">
    <div className="cta-container">
      {isLoginRequired ? (
        <ProjectLogo
          projectName={PROJECT_NAME}
          projectTagline={PROJECT_TAG_LINE}
          animate
        />
      ) : (
        <Spin size="large" />
      )}
      <Typography.Title
        level={2}
        type={type}
        style={{ marginTop: "15px", textAlign: "center" }}
      >
        {message}
      </Typography.Title>
      {isLoginRequired && (
        <Button
          type="primary"
          style={{ height: 50 }}
          onClick={() => {
            window.location.href = window.location.origin;
          }}
        >
          Return to Salvus Login
        </Button>
      )}
    </div>
  </div>
);

AuthMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  isLoginRequired: PropTypes.bool
};

AuthMessage.defaultProps = {
  message: "",
  type: "",
  isLoginRequired: false
};

import {
  BookOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  IeOutlined,
  PictureOutlined,
  VideoCameraOutlined
} from "@ant-design/icons";
import React from "react";
import BreakingContainment from "../assets/activitytypes/BreakingContainment.png";
import ColdWork from "../assets/activitytypes/Cold Work.png";
import ConfinedSpace from "../assets/activitytypes/Confined Space.png";
import Demolition from "../assets/activitytypes/Demolition.png";
import DrillingWellServices from "../assets/activitytypes/Drilling Well Services.png";
import Electrical from "../assets/activitytypes/Electrical.png";
import Excavation from "../assets/activitytypes/Excavation.png";
import HotWork from "../assets/activitytypes/Hot Work.png";
import IndustrialCleaning from "../assets/activitytypes/Industrial Cleaning.png";
import InstrumentationandControls from "../assets/activitytypes/Instrumentation and Controls.png";
import LiftAndHoisting from "../assets/activitytypes/LiftandHoisting.png";
import Mechanical from "../assets/activitytypes/Mechanical.png";
import Other from "../assets/activitytypes/Other.png";
import Radiation from "../assets/activitytypes/Radiation.png";
import WorkingAtHeight from "../assets/activitytypes/Working at Height.png";

export const EMPTY_OBJECT = Object.freeze({});
export const EMPTY_ARRAY = Object.freeze([]);
export const THRESHOLD_MATCHING_FILTER_CRITERIA = 5000;
export const DEFAULT_MARGIN = "4px 8px 4px 0";
export const SHORT_DESCRIPTION_LENGTH = 640;

export const REACTION_TYPE = {
  LIKE: "LIKE",
  DISLIKE: "DISLIKE"
};

export const SORT_BY_OPTIONS = [
  {
    name: "Relevance",
    key: "closestMatch"
  },
  {
    name: "Likes",
    key: "mostPopular"
  },
  {
    name: "Date",
    key: "submittedDate"
  }
];

export const SORT_OPTIONS = [
  {
    name: "Most Related",
    key: "mostRelated"
  },
  {
    name: "Other Suggestions",
    key: "otherSuggestion"
  }
];

export const dataSources = [
  { label: "LFE", value: "LFE" },
  { label: "Toolbox", value: "Toolbox" }
];

export const contentTypes = [
  { label: "Video", value: "video" },
  { label: "Presentation", value: "presentation" },
  { label: "PDF", value: "pdf" },
  { label: "Website", value: "website" },
  { label: "Other", value: "other" }
];

export const activityTypes = [
  {
    label: "Breaking Containment",
    value: "Breaking Containment",
    image: `${BreakingContainment}`
  },
  {
    label: "Cold Work",
    value: "Cold Work",
    image: `${ColdWork}`
  },
  {
    label: "Confined Space",
    value: "Confined Space",

    image: `${ConfinedSpace}`
  },
  {
    label: "Lifting and Hoisting",
    value: "Lifting and Hoisting",
    image: `${LiftAndHoisting}`
  },
  {
    label: "Drilling",
    value: "Drilling",
    image: `${DrillingWellServices}`
  },
  {
    label: "Electrical",
    value: "Electrical",
    image: `${Electrical}`
  },
  {
    label: "Mechanical",
    value: "Mechanical",
    image: `${Mechanical}`
  },
  {
    label: "Hot Work",
    value: "Hot Work",
    image: `${HotWork}`
  },
  {
    label: "Cleaning",
    value: "Cleaning",
    image: `${IndustrialCleaning}`
  },
  {
    label: "Instrumentation and Controls",
    value: "Instrumentation and Controls",
    image: `${InstrumentationandControls}`
  },
  {
    label: "Working At Height",
    value: "Working At Height",
    image: `${WorkingAtHeight}`
  },
  {
    label: "Radiation",
    value: "Radiation",
    image: `${Radiation}`
  },
  {
    label: "Excavation",
    value: "Excavation",
    image: `${Excavation}`
  },
  {
    label: "Demolition",
    value: "Demolition",
    displayName: "Decomissioning/Demolition",
    image: `${Demolition}`
  },
  {
    label: "Other",
    value: "Other",
    image: `${Other}`
  }
];

export const RESOURCE_MAPPER = {
  image: { icon: <PictureOutlined />, name: "Image" },
  pdf: { icon: <FilePdfOutlined />, name: "PDF" },
  lfe: { icon: <BookOutlined />, name: "LFE" },
  lfi: { icon: <BookOutlined />, name: "LFI" },
  pwr: { icon: <BookOutlined />, name: "PWR" },
  toolbox: {
    icon: (
      <img
        src="https://toolbox.energyinst.org/design/images/app-icon/icon-192x192.png"
        alt="Toolbox Icon"
        style={{ width: 15, height: 15 }}
      />
    ),
    name: "Toolbox"
  },
  website: { icon: <IeOutlined />, name: "Website" },
  presentation: { icon: <FilePptOutlined />, name: "Presentation" },
  video: { icon: <VideoCameraOutlined />, name: "Video" },
  other: { icon: <FileOutlined />, name: "Other" }
};

import { Button, Checkbox, Col, Input, Row, Typography } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import LayoutContext from "../../../context/LayoutContext";
import TenantContext from "../../../context/TenantContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  dataEndpoint,
  dataFlaggedEndpoint,
  useApi
} from "../../API";
import DiscoveriesContext from "./Context";

const { TextArea } = Input;

const Flagged = ({ open, id, onCancel, onSave }) => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const [data, setData] = useState();
  const [flaggedData, setFlaggedData] = useState();
  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { request: getFlaggedData } = useApi(
    dataEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const { request: putFlaggedData } = useApi(
    dataFlaggedEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const { activeTenantTopics } = useContext(TenantContext);

  const {
    filterContext: { topics }
  } = useContext(DiscoveriesContext);

  const fetchData = useCallback(async () => {
    const response = await getFlaggedData({ url: `${dataEndpoint}/${id}` });
    const nlpData = response.result;
    setData(nlpData);

    const tempFlaggedData = {};
    Object.entries(nlpData?._source?.flagged || {})?.forEach(([key, value]) => {
      tempFlaggedData[`${key}`] = {
        nonRelevant: true,
        comments: value?.comments
      };
    });

    nlpData?._source?.autoGenerated?.topics?.forEach(item => {
      tempFlaggedData[`${item}`] = {
        nonRelevant: false,
        comments: ""
      };
    });

    setFlaggedData(tempFlaggedData);
  }, [getFlaggedData, id]);

  useEffect(() => {
    if (!open || !id) {
      return;
    }

    fetchData();
  }, [id, open, fetchData]);

  const handleCleanup = useCallback(async () => {
    setData();
    setFlaggedData();
    setSaving(false);
    setIsDirty(false);
  }, []);

  const handleOnSave = useCallback(async () => {
    setSaving(true);

    const payload = {};
    Object.entries(flaggedData)?.forEach(([key, value]) => {
      if (value.nonRelevant) {
        payload[`${key}`] = {
          comments: value?.comments
        };
      }
    });

    const customPayload = {
      flagged: payload
    };

    await putFlaggedData({
      url: `${dataFlaggedEndpoint}/${id}`,
      data: customPayload
    });

    notify(notificationTypes.success, {
      message: "Discovery was Flagged",
      description: "You have successfully flagged this discovery"
    });

    onSave();
    handleCleanup();
  }, [
    flaggedData,
    putFlaggedData,
    id,
    notify,
    notificationTypes,
    onSave,
    handleCleanup
  ]);

  const handleOnCancel = useCallback(async () => {
    handleCleanup();
    onCancel();
  }, [onCancel, handleCleanup]);

  const handleItemChecked = (item, checked) => {
    // We are mutating the original object
    // so make sure we update state with new object
    // so sideeffects occur
    const flaggedDataItem = flaggedData[`${item}`];
    flaggedDataItem.nonRelevant = checked;

    //
    setFlaggedData({ ...flaggedData });
    setIsDirty(true);
  };

  const handleItemComments = (item, comments) => {
    // We are mutating the original object
    // so make sure we update state with new object
    // so sideeffects occur
    const flaggedDataItem = flaggedData[`${item}`];
    flaggedDataItem.comments = comments;

    //
    setFlaggedData({ ...flaggedData });
    setIsDirty(true);
  };

  const renderItem = item => {
    const flaggedDataItem = flaggedData[`${item}`];
    const topic = activeTenantTopics.find(i => i.key === item);
    return (
      <Row gutter={[4, 4]} style={{ marginBottom: "10px" }} key={item}>
        <Col span={10}>
          <Checkbox
            checked={flaggedDataItem.nonRelevant}
            onChange={e => {
              handleItemChecked(item, e.target.checked);
            }}
          >
            <Typography.Text>{topic.name}</Typography.Text>
          </Checkbox>
        </Col>
        <Col span={14}>
          <TextArea
            value={flaggedDataItem?.comments}
            placeholder=""
            autoSize={{
              minRows: 2,
              maxRows: 2
            }}
            disabled={!flaggedDataItem?.nonRelevant}
            onChange={e => handleItemComments(item, e.target.value)}
          />
        </Col>
      </Row>
    );
  };

  return !data || !open ? null : (
    <CustomModal
      key="flaggedform-modal"
      title={`${data._source.metadata.name} #${data._id}`}
      open={open}
      onCancel={handleOnCancel}
      footer={
        <div style={{ flex: "1 1 0%" }} key="actions">
          <Button key="cancel" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={saving}
            onClick={handleOnSave}
            disabled={saving || !isDirty}
          >
            Save
          </Button>
        </div>
      }
    >
      <div
        key="mappings"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "4px 20px 20px 0",
          overflow: "hidden"
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <Typography.Title level={5}>
            Tell us what is &quot;NOT&quot; relevant and why...
          </Typography.Title>
        </div>
        <div style={{ flex: "1 1 0%", overflowY: "auto", overflowX: "hidden" }}>
          {Object.keys(flaggedData)
            .filter(item => (topics?.length ? topics.includes(item) : item))
            .map(item => renderItem(item))}
        </div>
      </div>
    </CustomModal>
  );
};

Flagged.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

Flagged.defaultProps = {
  open: false,
  id: null,
  onCancel: () => {},
  onSave: () => {}
};

export default Flagged;

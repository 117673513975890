import React, { useMemo } from "react";
import MessageComponent from "../../components/MessageComponent";
import SettingsSideMenu from "../../components/SideMenus/SettingsSideMenu";
import PageLayout from "../../layouts/PageLayout";
import { useAuthorization } from "../../modules/Auth/hooks";
import Content from "../../modules/Investigate/Topics/Content";

import "./index.less";

const TopicsPage = () => {
  const { activeTenantKey, isActiveTenantGlobal } = useAuthorization();

  const renderContent = useMemo(() => {
    if (!activeTenantKey || isActiveTenantGlobal) {
      return (
        <MessageComponent
          title="Sorry!"
          message="You must select a Tenant before proceeding"
          type="danger"
        />
      );
    }

    return <Content />;
  }, [activeTenantKey, isActiveTenantGlobal]);

  return (
    <PageLayout
      className="topics-page"
      side={{
        placement: "left",
        component: <SettingsSideMenu />
      }}
      header={{
        title: "Topics Management",
        description:
          "Manage the Topics, the Keywords, and even run adhoc scenarios."
      }}
    >
      {renderContent}
    </PageLayout>
  );
};

export default TopicsPage;

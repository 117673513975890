import PropTypes from "prop-types";
import React, { useRef } from "react";
import { animated, useChain, useSpring } from "react-spring";

import useForceUpdate from "../../hooks/useForceUpdate";

export default function ShellLogo({ animate, width, height }) {
  const forceUpdate = useForceUpdate();
  const logoPropsRef = useRef();
  const [logoProps] = useSpring(() => ({
    shellOuterPath: `M37,
      172.6l-4.4-35.4c-11.5-8.2-20.5-14.9-31.2-22.8c-5-37.2,4.3-57.4,
      18-76.6C36.5,
      16.2,
      63.8,
      0,96.6,
      0c30.1,
      0.2,
      58.4,
      14.5,
      76.5,
      38.5c15.2,20.5,
      23.3,
      46.4,
      17.4,
      77L161,
      137.3l-4.4,
      35.4h-46.9c-2.8,
      2.4-6.8,
      4.4-13.1,
      4.6c-4.8-0.1-9.4-1.7-13.2-4.6H37V172.6z`,
    shellInnerPath: `M96.6,
      123.6c-2.4-33-3.1-73.6-5.2-106.3c-3.6-0.8-8.4-0.6-12.6,
      0.1c-3.7,
      1-7.3,
      2.5-10.6,
      4.3C74.6,
      53.1,
      82.1,
      93,
      88.4,
      124c-8.6-30.1-20.2-68-28.8-98.2c-4,
      1-7.8,
      2.9-11,
      5.5c-3.1,
      2.3-5.7,
      5.2-7.7,
      8.5c12.4,
      26.5,
      27.4,
      60.4,
      39.9,
      86.7C66.3,
      102.6,
      48.7,
      71,
      34.2,
      47.2c-3.1,
      2.5-5.8,
      5.5-7.8,
      9c-2.3,
      3.9-3.7,
      8.3-4.1,
      12.8c15.9,
      19.4,
      34.9,
      42.7,
      50.9,
      62.1C56.6,
      115,
      36,
      94.3,
      19.4,
      78.2c-1.9,
      3.5-3,
      7.4-3.1,
      11.4c-0.4,
      5.1,
      0.1,
      10.3,
      1.5,
      15.2l32.3,
      24l3.4,
      26.6l35.6,
      0.3c2.5,
      3,
      3.4,
      4.6,
      7.6,
      4.8c3.2-0.1,
      6-1.9,
      7.4-4.8h35.4L143,
      129l32-23.9c1.4-4.9,
      2-10.1,
      1.6-15.2c-0.3-4-1.3-7.8-3-11.4c-16.6,
      16-37.2,
      36.8-53.8,
      52.8c16.1-19.2,
      34.9-42.6,
      51-62.2c-0.8-4.4-1.9-8.8-4.4-12.7c-2-3.4-4.5-6.5-7.5-9.1c-14.6,
      23.8-32.4,
      55.6-46.8,
      79.4c12.8-26.4,
      27.4-60.3,
      40-86.5c-2-3.3-4.5-6.2-7.5-8.6c-3.3-2.6-7.1-4.6-11.2-5.7c-9,
      30-20.1,
      68.2-28.8,
      98.2c6.1-31.1,
      13.7-70.7,
      19.8-101.9c-3.1-2.1-6.5-3.6-10.1-4.5c-4.2-0.9-8.5-1-12.7-0.4C99.2,
      50.2,
      99.1,
      90.8,
      96.6,
      123.6z`,
    opacity: 1,
    transform: [0, 0],
    from: animate
      ? {
          opacity: 0,
          transform: [0, -200]
        }
      : { transform: [0, 0] },
    config: {
      mass: 3,
      tension: 200,
      friction: 25
    },
    ref: ref => {
      logoPropsRef.current = ref;
      forceUpdate();
    }
  }));
  const colorPropsRef = useRef();
  const [colorProps] = useSpring(() => ({
    shellOuterFill: "#D42E12",
    shellInnerFill: "#F7D117",
    from: animate
      ? { shellOuterFill: "black", shellInnerFill: "white" }
      : { shellOuterFill: "#D42E12", shellInnerFill: "#F7D117" },
    config: {
      duration: 500
    },
    ref: ref => {
      colorPropsRef.current = ref;
      forceUpdate();
    }
  }));

  useChain([logoPropsRef, colorPropsRef], [0, 0.5]);

  return (
    <div style={{ width, height }}>
      <animated.svg
        style={{ textAlign: "center", padding: "0 auto", margin: 0 }}
        viewBox="0 0 200 180"
        width={width}
        height={height}
      >
        <animated.path
          d={logoProps.shellOuterPath}
          fill={colorProps.shellOuterFill}
          transform={logoProps.transform.interpolate((x, y) => `translate(${x}, ${y})`)}
          style={{
            opacity: logoProps.opacity
          }}
        />
        <animated.path
          d={logoProps.shellInnerPath}
          fill={colorProps.shellInnerFill}
          transform={logoProps.transform.interpolate((x, y) => `translate(${x}, ${y})`)}
          style={{
            opacity: logoProps.opacity
          }}
        />
      </animated.svg>
    </div>
  );
}

ShellLogo.propTypes = {
  animate: PropTypes.bool,
  width: PropTypes.any,
  height: PropTypes.any
};

ShellLogo.defaultProps = {
  animate: false,
  width: 200,
  height: 180
};

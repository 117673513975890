import { hasGlobalAdminRole } from "@salvus/shared/modules/authorization";
import { Divider } from "antd";
import React, { useContext, useMemo } from "react";
import CustomSelect from "../../../../components/CustomSelect";
import { useAuth } from "../../../../context/AuthContext";
import TenantContext from "../../../../context/TenantContext";

const TenantSelection = () => {
  const { currentUser } = useAuth();
  const { tenants, activeTenantKey, changeTenant } = useContext(TenantContext);

  const hasGlobalAccess = useMemo(
    () => hasGlobalAdminRole(currentUser),
    [currentUser]
  );

  const defaultClassName = "tenant-select";

  return (
    <>
      <div
        className={`${defaultClassName}-container`}
        key="tenant-selection-container"
      >
        <CustomSelect
          className={defaultClassName}
          placeholder="Select Tenant..."
          direction="horizontal"
          mode=""
          onChange={changeTenant}
          value={activeTenantKey}
          bordered={false}
          options={tenants?.map(t => ({
            name: t.name,
            label: t.icon ? (
              <>
                {t.icon}
                {t.name}
              </>
            ) : (
              t.name
            ),
            key: t.key,
            value: t.key,
            disabled: !(hasGlobalAccess || currentUser.tenants?.includes(t.key))
          }))}
        />
      </div>
      <Divider type="vertical" className="divider" />
    </>
  );
};

export default TenantSelection;

export const defaultFetchArgsFn = (query, offset, searchConfig, body) => [
  query,
  offset,
  searchConfig,
  body
];

export const getFormDataFormat = filterContext => ({
  ...filterContext,
  selectedKeywords: filterContext.selectedKeywords.map(item => item.value),
  selectedActivities: filterContext.selectedActivities.map(item => item.name),
  "selectedDateRange-min": filterContext.selectedDateRange[0],
  "selectedDateRange-max": filterContext.selectedDateRange[1],
  selectedContentType: filterContext.selectedContentType.map(item => item.name),
  selectedDataSources: filterContext.selectedDataSources.map(item => item.name)
});

export const lookupValue = (lookupData, selector, propertyName = "label") =>
  lookupData.find(selector)?.[`${propertyName}`];

export default {
  defaultFetchArgsFn,
  getFormDataFormat,
  lookupValue
};

import "antd/dist/reset.css"; // Import Ant Design styles
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import { ContextProvider } from "./context";
import Layout from "./layouts/MainLayout";
import { RoutesMapper } from "./routes";

const App = () => (
  <Router>
    <ErrorBoundary>
      <ContextProvider>
        <Layout>
          <RoutesMapper />
        </Layout>
      </ContextProvider>
    </ErrorBoundary>
  </Router>
);

export default App;

import { Button, Checkbox, Form, Select } from "antd";
import { set } from "lodash";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import CustomModal from "../../../components/CustomModal";
import CustomSelect from "../../../components/CustomSelect";
import LayoutContext from "../../../context/LayoutContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  useApi,
  userSubscriptionsEndpoint
} from "../../API";
import { useAuthorization } from "../../Auth/hooks";
import AssetsSelect from "../../Investigate/components/AssetsSelect";
import TopicsSelect from "../../Investigate/components/TopicsSelect";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { flex: 1 }
};

const { Option } = Select;

const SubscriptionForm = ({ open, onCancel, onSave, data }) => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const { activeTenantKey } = useAuthorization();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [subscriptions, setSubscriptions] = useState({});

  const { resetFields } = form;

  const { request: putUserSubscription } = useApi(
    userSubscriptionsEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const modalTitle = data
    ? `Edit: ${data._source.firstName} ${data._source.lastName}`
    : "Add Subscription";

  const fetchData = useCallback(() => {
    resetFields();
    setSubscriptions(
      data._source?.subscriptions?.[`${activeTenantKey}`]?.discoveriesReport ||
        {}
    );
  }, [data, resetFields, activeTenantKey]);

  useEffect(() => {
    if (!form || !data || !open) {
      return;
    }

    fetchData();
  }, [data, open, form, fetchData]);

  const isSubscribed = useMemo(
    () => Boolean(subscriptions?.subscribed),
    [subscriptions]
  );

  const handleSubscibeOnChange = useCallback(async value => {
    setIsDirty(true);
    setSubscriptions({
      subscribed: value,
      dataProviderKeys: value ? ["utl"] : [],
      topicKeys: [],
      assetKeys: []
    });
  }, []);

  const handleTopicsOnChange = useCallback(async values => {
    setIsDirty(true);
    setSubscriptions(prev => ({
      ...prev,
      topicKeys: values || []
    }));
  }, []);

  const handleAssetsOnChange = useCallback(async values => {
    setIsDirty(true);
    setSubscriptions(prev => ({
      ...prev,
      assetKeys: values || []
    }));
  }, []);

  const handleCleanup = useCallback(async () => {
    resetFields();
    setSubscriptions({});
    setIsDirty(false);
    setSaving(false);
  }, [resetFields]);

  const handleOnCancel = useCallback(async () => {
    handleCleanup();
    onCancel();
  }, [onCancel, handleCleanup]);

  const handleOnSave = useCallback(async () => {
    setSaving(true);
    try {
      const id = data?._id;

      const payload = {};
      set(
        payload,
        `subscriptions.${activeTenantKey}.discoveriesReport`,
        subscriptions || {}
      );

      await putUserSubscription({
        url: `${userSubscriptionsEndpoint}/${id}`,
        data: payload
      });

      notify(notificationTypes.success, {
        message: "Subscription Updated",
        description: `${data?._source?.firstName} ${data?._source?.lastName}'s subscription has been successfully updated`
      });

      onSave();
      handleCleanup();
    } catch (err) {
      setSaving(false);
      notify(notificationTypes.error, {
        message: "Unable to update your subscriptions",
        description: err?.response?.data?.message || err.message || ""
      });
    }
  }, [
    data,
    activeTenantKey,
    subscriptions,
    putUserSubscription,
    notify,
    notificationTypes,
    onSave,
    handleCleanup
  ]);

  return !open ? null : (
    <CustomModal
      key="tenant-modal"
      title={modalTitle}
      open={open}
      onCancel={handleOnCancel}
      footer={[
        <div style={{ flex: "1 1 0%" }} key="actions">
          <Button key="cancel" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={saving}
            onClick={() => form.submit()}
            disabled={saving || !isDirty}
          >
            Save
          </Button>
        </div>
      ]}
    >
      <Form
        key="form"
        form={form}
        {...formItemLayout}
        className="form-slim"
        onFinish={handleOnSave}
        labelWrap
      >
        <Form.Item
          labelCol={{ span: 11 }}
          label="Receive Daily Discoveries Report?"
        >
          <div>
            <Checkbox
              checked={subscriptions?.subscribed}
              onChange={e => {
                setIsDirty(true);
                handleSubscibeOnChange(e.target.checked);
              }}
            />
          </div>
        </Form.Item>

        <Form.Item
          key="dataProviders"
          name="dataProviders"
          label="Data Providers"
        >
          <div>
            <CustomSelect
              label=""
              value={subscriptions?.dataProviderKeys}
              placeholder=""
              direction="vertical"
              mode=""
              disabled
            >
              {[{ label: "UTL", value: "UTL" }].map(i => (
                <Option key={i.value} value={i.value}>
                  {i.label}
                </Option>
              ))}
            </CustomSelect>
          </div>
        </Form.Item>

        <Form.Item label="Topics">
          <div>
            <TopicsSelect
              label=""
              disabled={!isSubscribed}
              direction="vertical"
              value={subscriptions?.topicKeys}
              allowSelectAll
              allowDeselectAll
              onChange={handleTopicsOnChange}
            />
          </div>
        </Form.Item>
        <Form.Item label="Assets">
          <div>
            <AssetsSelect
              label=""
              disabled={!isSubscribed}
              direction="vertical"
              value={subscriptions?.assetKeys}
              allowSelectAll
              allowDeselectAll
              onChange={handleAssetsOnChange}
            />
          </div>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

SubscriptionForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  data: PropTypes.object
};

SubscriptionForm.defaultProps = {
  open: false,
  onCancel: () => {},
  onSave: () => {},
  data: null
};

export default SubscriptionForm;

import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";
import { startCase } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useParams } from "react-router-dom";
import ComponentLoader from "../../../components/ComponentLoader";
import LayoutContext from "../../../context/LayoutContext";
import TenantContext from "../../../context/TenantContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  tenantAssetsEndpoint,
  useApi
} from "../../API";
import { useAuthorization } from "../../Auth/hooks";
import AssetForm from "./AssetForm";

const Assets = () => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const { activeTenantKey, loadActiveTenantProfile } =
    useContext(TenantContext);
  const { hasSecurityRoles, adminRoles } = useAuthorization();

  const { tenantId } = useParams();
  const [tenantKey, setTenantKey] = useState();
  const [data, setData] = useState();
  const [formInstructions, setFormInstructions] = useState({});

  const canManage = useMemo(
    () => hasSecurityRoles(adminRoles.tenants),
    [adminRoles.tenants, hasSecurityRoles]
  );

  //
  const {
    request: getTenantAssets,
    loading: loadingTenantAssets,
    error: errorGettingTenantAssets
  } = useApi(
    tenantAssetsEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const { request: putTenantAsset } = useApi(
    tenantAssetsEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const fetchData = useCallback(async () => {
    const response = await getTenantAssets({
      url: `${tenantAssetsEndpoint}/${tenantId}`
    });
    const responseData = response?.result;

    setTenantKey(responseData?.key);
    setData(responseData?.assets || []);
  }, [getTenantAssets, tenantId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePutAssets = useCallback(
    async (origPayload, newPayload) => {
      const newAssets = [
        ...((origPayload
          ? data?.filter(k => k.key !== origPayload.key)
          : data) || []),
        newPayload
      ];

      const payload = {
        assets: newAssets
      };

      await putTenantAsset({
        url: `${tenantAssetsEndpoint}/${tenantId}`,
        data: payload
      });

      fetchData();

      if (activeTenantKey === tenantKey) {
        loadActiveTenantProfile();
      }

      setFormInstructions({});
    },
    [
      activeTenantKey,
      data,
      fetchData,
      loadActiveTenantProfile,
      putTenantAsset,
      tenantId,
      tenantKey
    ]
  );

  const handleAddAsset = useCallback(
    async (origPayload, newPayload) => {
      await handlePutAssets(origPayload, newPayload);
      notify(notificationTypes.success, {
        message: "Tenant Assets Updated",
        description: `${newPayload.name} has been successfully added`
      });
    },
    [handlePutAssets, notificationTypes, notify]
  );

  const handleUpdateAsset = useCallback(
    async (origPayload, newPayload) => {
      await handlePutAssets(origPayload, newPayload);
      notify(notificationTypes.success, {
        message: "Tenant Assets Updated",
        description: `${newPayload.name} has been successfully updated`
      });
    },
    [handlePutAssets, notificationTypes, notify]
  );

  const columns = useMemo(
    () => [
      {
        title: "Key",
        key: "key",
        width: 150,
        sorter: (a, b) => a?.key?.localeCompare(b?.key),
        render: record => record?.key
      },
      {
        title: "Name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.name?.localeCompare(b?.name),
        render: record => startCase(record.name)
      },
      {
        title: "Lookup Exp",
        key: "lookupExp",
        sorter: (a, b) => a?.lookupExp?.localeCompare(b?.lookupExp),
        render: record => record.lookupExp
      },
      {
        title: "Onboarded",
        key: "isOnboarded",
        width: 125,
        sorter: (a, b) => a.isOnboarded - b.isOnboarded,
        render: record => (record?.isOnboarded ? "Yes" : "No")
      },
      {
        title: "Status",
        key: "status",
        width: 100,
        sorter: (a, b) => a?.status?.localeCompare(b?.status),
        render: record => startCase(record?.status)
      },
      {
        title: "Actions",
        className: "actions",
        width: 175,
        render: record => (
          <Button
            type="link"
            style={{ marginRight: "4px" }}
            disabled={!canManage}
            onClick={() => {
              setFormInstructions({
                open: true,
                onSaveAction: handleUpdateAsset,
                data: record
              });
            }}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
        )
      }
    ],
    [canManage, handleUpdateAsset]
  );

  if (errorGettingTenantAssets) {
    return (
      <ComponentLoader
        hasErrors={Boolean(errorGettingTenantAssets)}
        errorComponent="Unable to retrieve the data at this time"
      />
    );
  }

  return (
    <>
      <Row key="row-actions" gutter={[5, 5]} className="top-filter-row">
        <Col flex="auto">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={!canManage}
            style={{
              marginLeft: "auto"
            }}
            onClick={() => {
              setFormInstructions({
                open: true,
                onSaveAction: handleAddAsset
              });
            }}
          >
            Add Asset
          </Button>
        </Col>
      </Row>
      <Table
        className="table align-top"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          showTotal: total => `Total ${total} items`,
          hideOnSinglePage: true
        }}
        loading={loadingTenantAssets}
        columns={columns}
        dataSource={data}
        rowKey={item => item?.key}
        scroll={{ x: true }}
      />
      <AssetForm
        open={formInstructions?.open}
        onCancel={() => setFormInstructions({})}
        onSave={formInstructions?.onSaveAction}
        data={formInstructions?.data}
      />
    </>
  );
};

export default Assets;

import { Typography } from "antd";
import React from "react";
import { PROJECT_VERSION } from "../../config";
import "./index.less";

export const VersionText = () => (
  <Typography.Text
    className="version-text"
    style={{ textAlign: "center", margin: "0 auto" }}
  >
    V{PROJECT_VERSION}
  </Typography.Text>
);

export default VersionText;

import { InfoCircleOutlined } from "@ant-design/icons";
import { highlightData, makeUniqueGroupings } from "@salvus/shared/utils";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography
} from "antd";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import {
  DefaultRequestConfig,
  RequestMethodType,
  topicKeywordAdhocEndpoint,
  useApi
} from "../../API";

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { flex: 1 }
};

const KeywordForm = ({ open, onCancel, onSave, data, rawValidation }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [proceeding, setProceeding] = useState();
  const [succeeding, setSucceeding] = useState();

  const [testData, setTestData] = useState();
  const [highlightTestData, setHighlightTestData] = useState();

  const { resetFields, setFieldsValue } = form;
  const { TextArea } = Input;
  const modalTitle = data?.raw ? `Edit: ${data.raw}` : "Add Keyword(s)";
  const { request: postAdHocKeyword } = useApi(
    topicKeywordAdhocEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const fetchData = useCallback(async () => {
    resetFields();
    setFieldsValue(data);
    setProceeding(
      data?.excludeProceeding ? data.excludeProceeding.join(", ") : null
    );
    setSucceeding(
      data?.excludeSucceeding ? data.excludeSucceeding.join(", ") : null
    );
  }, [data, setFieldsValue, resetFields]);

  useEffect(() => {
    if (!form || !data || !open) {
      return;
    }

    fetchData();
  }, [data, open, form, fetchData]);

  const handleCleanup = useCallback(async () => {
    resetFields();
    setFieldsValue({});
    setIsDirty(false);
    setSaving(false);
    setProceeding();
    setSucceeding();
    setHighlightTestData();
    setTestData();
  }, [resetFields, setFieldsValue]);

  const handleOnCancel = useCallback(async () => {
    handleCleanup();
    onCancel();
  }, [onCancel, handleCleanup]);

  const validateRaw = async (_, value) => {
    const rawValue = value.toLowerCase().trim();
    if (!rawValue) {
      return Promise.reject(new Error("Please input a Word or Phrase"));
    }

    if (data?.raw === rawValue) {
      return Promise.resolve();
    }

    const found = await rawValidation(rawValue);
    if (found) {
      return Promise.reject(new Error("This Word or Phrase already exists"));
    }

    return Promise.resolve();
  };

  const getPaylod = useCallback(
    formData => {
      const rawValue = (formData.raw || "").toLowerCase().trim();
      return {
        raw: rawValue,
        words: rawValue.split(" ").filter(Boolean),
        exactPhraseOnly:
          formData?.exactPhraseOnly != null ? formData.exactPhraseOnly : false,
        includePlurals:
          formData?.includePlurals != null ? formData.includePlurals : false,
        excludeProceeding: (proceeding?.split(",").filter(Boolean) || [])?.map(
          i => i?.toLowerCase()?.trim()
        ),
        excludeSucceeding: (succeeding?.split(",").filter(Boolean) || [])?.map(
          i => i?.toLowerCase()?.trim()
        )
      };
    },
    [proceeding, succeeding]
  );

  const handleOnSave = useCallback(
    async values => {
      setSaving(true);
      const payload = getPaylod(values);
      onSave(data, payload);
      handleCleanup();
    },
    [data, getPaylod, handleCleanup, onSave]
  );

  const handleOnResults = useCallback(async () => {
    setHighlightTestData(null);
    const payload = {
      topic: getPaylod(form.getFieldsValue()),
      text: testData
    };

    const response = await postAdHocKeyword({
      url: `${topicKeywordAdhocEndpoint}`,
      data: payload
    });

    const responseData = response.result || {};

    const characterCounter = 0;
    const highlightCoordinates = [];
    let highlightedData = null;

    Object.entries(responseData.nlp?.keywords || {})
      .filter(([_, value]) => value)
      .forEach(([_, itemValue]) => {
        itemValue.forEach(element => {
          highlightCoordinates.push(element.span);
        });
      });

    const valueLength = testData.length + 1;

    highlightedData = highlightData(
      testData,
      characterCounter,
      valueLength,
      makeUniqueGroupings(highlightCoordinates)
    );

    setHighlightTestData(highlightedData || testData);
  }, [form, getPaylod, postAdHocKeyword, testData]);

  const handleTextAreaOnChange = event => {
    setTestData(event.target.value?.toLowerCase() || "");
  };

  return !open ? null : (
    <CustomModal
      title={modalTitle}
      open={open}
      onCancel={handleOnCancel}
      width="45%"
      footer={
        <div style={{ flex: "1 1 0%" }} key="actions">
          <Button key="cancel" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={saving}
            onClick={() => form.submit()}
            disabled={saving || !isDirty}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        {...formItemLayout}
        className="form-slim"
        key="form"
        onFinish={handleOnSave}
      >
        <Form.Item
          name="raw"
          label="Words or Phrases"
          validateTrigger="onBlur"
          tooltip={{
            title:
              "Enter a single word or multiple words seperated with a space to form a phrase",
            icon: <InfoCircleOutlined />
          }}
          rules={[
            {
              required: true,
              message: "Please input a Word or Phrase"
            },
            {
              validator: validateRaw,
              validateTrigger: "onBlur"
            }
          ]}
          onChange={() => setIsDirty(true)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Exclude if proceeding with"
          tooltip={{
            title:
              "If you want to exclude phrases enter a comma delimted list of words that come before the keyword",
            icon: <InfoCircleOutlined />
          }}
        >
          <Input
            onChange={e => {
              setIsDirty(true);
              setProceeding(e.currentTarget.value);
            }}
            value={proceeding}
          />
        </Form.Item>
        <Form.Item
          label="Exclude if succeeding with"
          tooltip={{
            title:
              "If you want to exclude phrases enter a comma delimted list of words that come after the keyword",
            icon: <InfoCircleOutlined />
          }}
        >
          <Input
            onChange={e => {
              setIsDirty(true);
              setSucceeding(e.currentTarget.value);
            }}
            value={succeeding}
          />
        </Form.Item>

        <Form.Item
          name="exactPhraseOnly"
          label="Exact Phrase Only?"
          valuePropName="checked"
        >
          <Switch onChange={() => setIsDirty(true)} />
        </Form.Item>

        <Form.Item
          name="includePlurals"
          label="Include Plurals?"
          valuePropName="checked"
        >
          <Switch onChange={() => setIsDirty(true)} />
        </Form.Item>

        <Divider />

        {!highlightTestData ? (
          <Form.Item
            key="testtext"
            rules={[
              {
                required: false
              }
            ]}
            label="Text to Test"
            style={{ marginBottom: "20px", minHeight: "150px" }}
          >
            <Row gutter={10}>
              <Col span={18}>
                <TextArea
                  rows={4}
                  style={{ height: 120, resize: "none" }}
                  onChange={handleTextAreaOnChange}
                  value={testData}
                />
              </Col>
              <Col
                span={6}
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  key="testresults"
                  type="primary"
                  loading={saving}
                  onClick={() => {
                    handleOnResults();
                  }}
                  disabled={!testData}
                >
                  Show Results
                </Button>
              </Col>
            </Row>
          </Form.Item>
        ) : (
          <Form.Item
            label="Highlighted Text"
            key="highlighttextlabel"
            wrapperCol={{ flex: 0 }}
            style={{ marginBottom: "20px", minHeight: "150px" }}
          >
            <Row
              gutter={10}
              style={{
                marginTop: "5px"
              }}
            >
              <Col span={18}>
                <Typography.Text>
                  {parse(highlightTestData || "")}
                </Typography.Text>
              </Col>
              <Col
                span={6}
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  key="testedit"
                  type="primary"
                  onClick={() => {
                    setHighlightTestData();
                  }}
                >
                  Edit Text
                </Button>
              </Col>
            </Row>
          </Form.Item>
        )}
      </Form>
    </CustomModal>
  );
};

KeywordForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  data: PropTypes.object,
  rawValidation: PropTypes.func
};

KeywordForm.defaultProps = {
  open: false,
  onCancel: () => {},
  onSave: () => {},
  data: null,
  rawValidation: () => {}
};

export default KeywordForm;

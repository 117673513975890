import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useAuthorization } from "../../modules/Auth/hooks";
import { filterNonSecuredRoutes, settingsRouteList } from "../../routeList";
import SideMenu from "./SideMenu";

const SettingsSideMenu = () => {
  const { currentUser } = useAuth();
  const { activeTenantKey } = useAuthorization();

  const filteredRoutes = useMemo(
    () =>
      filterNonSecuredRoutes(
        settingsRouteList,
        currentUser,
        activeTenantKey
      )?.map(route => ({
        key: route?.key,
        path: route?.path,
        ...(route?.icon ? { icon: route?.icon } : null),
        ...(route?.onClick
          ? { label: route?.name, onClick: route.onClick }
          : {
              label: (
                <Link to={() => ({ pathname: route?.path })}>
                  <span>{route?.name}</span>
                </Link>
              )
            })
      })),
    [currentUser, activeTenantKey]
  );

  return <SideMenu routes={filteredRoutes} title="Settings" />;
};

export default SettingsSideMenu;

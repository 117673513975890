import { useCallback, useState } from "react";

const useForceUpdate = () => {
  const [state, setState] = useState(false);

  return useCallback(() => {
    setState(!state);
  }, [state, setState]);
};

export default useForceUpdate;

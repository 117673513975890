/* eslint-disable no-console */
import {
  OidcUserStatus,
  useOidc,
  useOidcAccessToken,
  useOidcUser
} from "@axa-fr/react-oidc";
import axios from "axios";
import decode from "jwt-decode";
import { get, isEmpty, now } from "lodash";
import PropTypes from "prop-types";
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import useLocalStorageState from "use-local-storage-state";
import { SERVICES } from "../config";
import { AccessTokenStorageKey } from "../constants/common";
import { AuthMessage, Login } from "../modules/Auth/components";

const { LoadingError } = OidcUserStatus;

const decorateWithUserClaims = async accessToken => {
  const response = await axios({
    url: SERVICES.AUTH,
    method: "POST",
    data: {
      accessToken
    },
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  return response.data.result?.jwt;
};

const isTokenPayloadValid = tokenPayload => {
  if (!tokenPayload) {
    return false;
  }
  const timestamp = Math.round(now() / 1000);
  const expiry = get(tokenPayload, "exp");
  return !!(expiry && expiry - timestamp > 3);
};

/**
 * @param {string} token
 * @return {Boolean} true if token is valid
 */
const isTokenValid = token => {
  if (!token) {
    return false;
  }
  try {
    const playLoad = decode(token);
    return isTokenPayloadValid(playLoad);
  } catch (error) {
    return false;
  }
};

const onEvent = async (configurationName, eventName, data) => {
  if (eventName === "token_timer") {
    if (
      Math.min(data?.timeLeft, 0) <= 5 ||
      Math.min(data?.timeLeft, 0) % 60 === 0
    ) {
      console.log(`oidc:${configurationName}:${eventName}`, data);
    }
  } else if (!data?.message?.includes("because navigator")) {
    console.log(`oidc:${configurationName}:${eventName}`, data);
  }
  // for debug only
  // switch (eventName) {
  //   case "token_timer":
  //     break;
  //   case "refreshTokensAsync_begin":
  //     break;
  //   case "refreshTokensAsync_end":
  //     break;
  //   case "token_aquired":
  //   case "token_renewed":
  //     break;
  //   default:
  // }
};

export const PingIdContext = createContext({});

export const PingIdProvider = forwardRef(
  ({ env, children }, oidcHandlerRef) => {
    const { configName } = env;
    const [authenticationError, setAuthenticationError] = useState(null);
    const [user, setUser] = useState(null);
    const [defaultTenantKey, setDefaultTenantKey] = useState(null);

    const { login, logout, isAuthenticated } = useOidc(configName);
    const { oidcUser, oidcUserLoadingState } = useOidcUser(configName);
    const oidcAccessTokenState = useOidcAccessToken(configName);

    const [
      salvusAccessToken,
      setSalvusAccessToken,
      { removeItem: removeSalvusAccessTokenFromLocalStorage }
    ] = useLocalStorageState(AccessTokenStorageKey);

    const isLoaded = useMemo(
      () => !isEmpty(user) && !isEmpty(oidcUser),
      [oidcUser, user]
    );

    const setToken = useCallback(
      accessToken => {
        setSalvusAccessToken(accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      },
      [setSalvusAccessToken]
    );

    const clearToken = useCallback(() => {
      removeSalvusAccessTokenFromLocalStorage();
      axios.defaults.headers.common.Authorization = null;
    }, [removeSalvusAccessTokenFromLocalStorage]);

    const logoutWrapper = useCallback(async () => {
      // TODO: audit logger
      await logout(`${window.location.origin}`);
      setUser(null);
      clearToken();
    }, [clearToken, logout]);

    const loginWrapper = useCallback(async () => {
      try {
        await login();
      } catch (err) {
        console.error(err);
        await logoutWrapper();
      }
    }, [login, logoutWrapper]);

    const LoginComponent = useMemo(
      () => (
        <Login
          login={loginWrapper}
          message="Your session was lost"
          type="danger"
        />
      ),
      [loginWrapper]
    );

    const setDecoratedClaims = useCallback(
      /**
       * in short, set current user using the jwt and accessToken payload
       * @param {string} jwt
       * @param {object} accessTokenPayload
       */
      (jwt, accessTokenPayload) => {
        const claims = decode(jwt);
        // TODO: only update user and its claims inside it when it has different uid
        // TODO: new jwt => just set token claim
        if (
          !isEmpty(oidcUser) &&
          (isEmpty(user) || user?.uid !== accessTokenPayload?.uid)
        ) {
          const currentUser = {
            ...claims
          };
          setUser(currentUser);

          setDefaultTenantKey(claims.tenants?.[0]);
        }
      },
      [user, oidcUser]
    );

    const decorateToken = useCallback(() => {
      const { accessToken, accessTokenPayload } = oidcAccessTokenState;
      if (accessToken && isTokenPayloadValid(accessTokenPayload) && oidcUser) {
        decorateWithUserClaims(accessToken)
          .then(jwt => {
            setToken(jwt);
            setDecoratedClaims(jwt, accessTokenPayload);
            // TODO: auditLogger(jwt).loginAuditLog();
          })
          .catch(err => {
            console.error({ decoratingError: err });
            clearToken();
            setAuthenticationError(err);
          });
      }
    }, [
      clearToken,
      oidcAccessTokenState,
      setDecoratedClaims,
      setToken,
      oidcUser
    ]);

    const getToken = useCallback(() => {
      // eslint-disable-next-line quotes
      const accessToken = salvusAccessToken;
      return isTokenValid(salvusAccessToken) ? accessToken : null;
    }, [salvusAccessToken]);

    useEffect(() => {
      decorateToken();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oidcAccessTokenState, oidcUser]);

    useEffect(() => {
      if (oidcHandlerRef.current) {
        oidcHandlerRef.current.onEvent = onEvent;
      }
    }, [oidcHandlerRef]);

    useEffect(() => {
      if (oidcHandlerRef.current) {
        oidcHandlerRef.current.LoginComponent = LoginComponent;
      }
    }, [LoginComponent, oidcHandlerRef]);

    useEffect(() => {
      if (oidcUserLoadingState === LoadingError) {
        setAuthenticationError(oidcUserLoadingState);
      }
    }, [oidcUserLoadingState]);

    const contextValue = useMemo(
      () => ({
        oidcUser,
        currentUser: user,
        isAuthenticated,
        login: loginWrapper,
        logout: logoutWrapper,
        isLogOut: !isAuthenticated,
        getToken,
        env,
        defaultTenantKey
      }),
      [
        oidcUser,
        user,
        isAuthenticated,
        loginWrapper,
        logoutWrapper,
        env,
        getToken,
        defaultTenantKey
      ]
    );

    if (authenticationError) {
      return (
        <Login
          login={loginWrapper}
          message="Login attempt failed"
          type="danger"
        />
      );
    }

    if (!isAuthenticated) {
      return <Login login={loginWrapper} />;
    }

    if (!isLoaded) {
      return <AuthMessage message="Loading..." />;
    }

    return (
      <PingIdContext.Provider value={contextValue}>
        {children}
      </PingIdContext.Provider>
    );
  }
);

PingIdProvider.propTypes = {
  children: PropTypes.node.isRequired,
  env: PropTypes.any.isRequired
};

export const usePingId = () => useContext(PingIdContext);

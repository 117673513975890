import { useMemo } from "react";

export const useQueryString = () => {
  const search = window.location.search;
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default {
  useQueryString
};

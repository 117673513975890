import PropTypes from "prop-types";
import React from "react";
import { AuthContextProvider } from "./AuthContext";
import { LayoutContextProvider } from "./LayoutContext";
import { TenantContextProvider } from "./TenantContext";

export const ContextProvider = ({ children }) => (
  <LayoutContextProvider>
    <AuthContextProvider>
      <TenantContextProvider>{children}</TenantContextProvider>
    </AuthContextProvider>
  </LayoutContextProvider>
);

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ContextProvider;

import classicProjectLogo from "./ClassicProjectLogo";
import projectLogo from "./ProjectLogo";
import shellLogo from "./ShellLogo";

export const ShellLogo = shellLogo;

export const ClassicProjectLogo = classicProjectLogo;

export const ProjectLogo = projectLogo;

export default {
  ShellLogo,
  ProjectLogo,
  ClassicProjectLogo
};

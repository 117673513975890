import React from "react";
import PageLayout from "../../layouts/PageLayout";
import Filters from "./Filters";
import { LearningContextProvider } from "./LearningContext";
import Results from "./Results";

import "./index.less";

const LearningPage = () => (
  <LearningContextProvider>
    <PageLayout
      className="learning-page"
      side={{
        placement: "left",
        component: <Filters />,
        size: "large"
      }}
    >
      <Results />
    </PageLayout>
  </LearningContextProvider>
);

export default LearningPage;

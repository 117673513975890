import { Button, Form } from "antd";
import { startCase } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import CustomSelect from "../../../components/CustomSelect";
import LayoutContext from "../../../context/LayoutContext";
import TenantContext from "../../../context/TenantContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  useApi,
  userTenantsEndpoint,
  usersEndpoint
} from "../../API";

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { flex: 1 }
};

const AddUsersForm = ({ open, onCancel, onSave }) => {
  const { activeTenantKey } = useContext(TenantContext);
  const { notify, notificationTypes } = useContext(LayoutContext);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { resetFields } = form;

  const { request: getUsers } = useApi(
    usersEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const { request: addUsersToTenant } = useApi(
    userTenantsEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const fetchData = useCallback(async () => {
    const response = await getUsers();
    const responseData = response.result.hits.hits || [];
    resetFields();
    setUsers(
      responseData?.filter(d => !d?._source?.tenants?.includes(activeTenantKey))
    );
  }, [getUsers, resetFields, activeTenantKey]);

  useEffect(() => {
    if (!form || !open) {
      return;
    }

    fetchData();
  }, [open, form, fetchData]);

  const handleCleanup = useCallback(async () => {
    resetFields();
    setIsDirty(false);
    setSaving(false);
    setUsers([]);
    setSelectedUsers([]);
  }, [resetFields]);

  const handleOnCancel = useCallback(async () => {
    handleCleanup();
    onCancel();
  }, [onCancel, handleCleanup]);

  const handleUsersOnChange = useCallback(async values => {
    setSelectedUsers(values);
    setIsDirty(true);
  }, []);

  const handleOnSave = useCallback(async () => {
    setSaving(true);
    try {
      await addUsersToTenant({
        data: { ids: selectedUsers }
      });

      notify(notificationTypes.success, {
        message: "Add User(s) to Tenant",
        description: `Users were successfully added to ${startCase(
          activeTenantKey
        )}`
      });

      onSave();
      handleCleanup();
    } catch (err) {
      setSaving(false);
      notify(notificationTypes.error, {
        message: "Unable to add users to tenant",
        description: err?.response?.data?.message || err.message || ""
      });
    }
  }, [
    addUsersToTenant,
    selectedUsers,
    notify,
    notificationTypes,
    activeTenantKey,
    onSave,
    handleCleanup
  ]);

  return !open ? null : (
    <CustomModal
      key="add-users-to-tenant-modal"
      title="Add Users to Tenant"
      open={open}
      onCancel={handleOnCancel}
      footer={[
        <div style={{ flex: "1 1 0%", marginTop: "10px" }} key="actions">
          <Button key="cancel" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={saving}
            onClick={() => form.submit()}
            disabled={saving || !isDirty}
          >
            Save
          </Button>
        </div>
      ]}
    >
      <Form
        key="form"
        form={form}
        {...formItemLayout}
        className="form-slim"
        onFinish={handleOnSave}
        labelAlign="left"
      >
        <Form.Item
          name="users"
          label="User(s)"
          key="users"
          style={{ margin: "25px 0" }}
        >
          <div>
            <CustomSelect
              value={selectedUsers}
              placeholder="Select user(s)..."
              direction="vertical"
              required
              onChange={handleUsersOnChange}
              options={users?.map(i => ({
                value: i._id,
                label: `${i._source.firstName} ${i._source.lastName}`
              }))}
            />
          </div>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

AddUsersForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

AddUsersForm.defaultProps = {
  open: false,
  onCancel: () => {},
  onSave: () => {}
};

export default AddUsersForm;

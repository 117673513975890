import React from "react";
import SettingsSideMenu from "../../components/SideMenus/SettingsSideMenu";
import PageLayout from "../../layouts/PageLayout";
import Content from "../../modules/System/Users/Content";

const UsersPage = () => (
  <PageLayout
    className="users-page"
    side={{
      placement: "left",
      component: <SettingsSideMenu />
    }}
    header={{
      title: "System Users",
      description:
        "Manage users, assign system roles, add/remove users from tenants and assign tenant roles."
    }}
  >
    <Content />
  </PageLayout>
);

export default UsersPage;

import { Select } from "antd";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import CustomSelect from "../../../../components/CustomSelect";
import TenantContext from "../../../../context/TenantContext";
import ColorBadge from "../ColorBadge";

const defaultClassName = "data-sources-filter";

const { Option } = Select;

const DataSourcesSelect = ({
  label,
  info,
  placeholder,
  direction,
  ...rest
}) => {
  const { activeTenantDataSources } = useContext(TenantContext);

  return (
    <div style={{ width: "100%" }}>
      <CustomSelect
        className={defaultClassName}
        label={label}
        info={info}
        placeholder={placeholder}
        direction={direction}
        optionsData={activeTenantDataSources}
        {...rest}
      >
        {activeTenantDataSources.map(i => (
          <Option key={i.key} value={i.key} label={i.name}>
            <ColorBadge
              color={i.color}
              text={i.name}
              size="small"
              style={{ width: "100%" }}
            />
          </Option>
        ))}
      </CustomSelect>
    </div>
  );
};

DataSourcesSelect.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  placeholder: PropTypes.string,
  direction: PropTypes.oneOf(["horizontal", "vertical"])
};

DataSourcesSelect.defaultProps = {
  label: "Data Sources",
  info: "Sources of Reports that are used to process NLP.",
  placeholder: "Select Data Sources...",
  direction: "vertical"
};

export default DataSourcesSelect;

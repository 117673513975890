import { Menu, Typography } from "antd";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import LayoutContext from "../../context/LayoutContext";

import "./index.less";

const SideMenu = ({ routes, title }) => {
  const { currentTheme } = useContext(LayoutContext);
  const location = useLocation();

  return (
    <div className="side-menu-container">
      <Typography.Title
        level={5}
        style={{ marginBottom: "13px", marginTop: "5px" }}
      >
        {title}
      </Typography.Title>
      <Menu
        theme={currentTheme}
        key="side-menu"
        selectedKeys={[location.pathname]}
        style={{
          borderRight: 0
        }}
        items={routes}
      />
    </div>
  );
};

SideMenu.propTypes = {
  title: PropTypes.string,
  routes: PropTypes.array
};

SideMenu.defaultProps = {
  title: "",
  routes: []
};

export default SideMenu;

import {
  normalizeSeachParams,
  predefinedDates,
  toArray
} from "@salvus/shared/utils";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import createPersistedReducer from "use-persisted-reducer";
import { DashboardStorageKey } from "../../../constants/common";
import { useQueryString } from "../../../utils/hooks/queryString";

const usePersistedStorage = createPersistedReducer(
  DashboardStorageKey,
  localStorage
);

const actionTypes = {
  RESET_FILTERS: "RESET_FILTERS",
  UPDATE_TOPICS: "UPDATE_TOPICS",
  CLEAR_TOPICS: "CLEAR_TOPICS",
  UPDATE_DATA_SOURCES: "UPDATE_DATA_SOURCES",
  CLEAR_DATA_SOURCES: "CLEAR_DATA_SOURCES",
  UPDATE_DATES: "UPDATE_DATES",
  CLEAR_DATES: "CLEAR_DATES",
  UPDATE_ASSETS: "UPDATE_ASSETS",
  CLEAR_ASSETS: "CLEAR_ASSETS",
  SET_DISCOVERIES_VISUALIZER_STATE: "SET_DISCOVERIES_VISUALIZER_STATE",
  SET_TRENDS_VISUALIZER_STATE: "SET_TRENDS_VISUALIZER_STATE"
};

const resetState = {
  topics: [],
  dataProviders: ["utl"],
  dataSources: [],
  dates: [
    predefinedDates().startOfYesterday.utc().valueOf(),
    predefinedDates().endOfTomorrow.utc().valueOf()
  ],
  assets: [],
  discoveriesVisualizerState: "byTopics",
  trendsVisualizerState: "byTopics"
};

const initialState = {
  topics: [],
  dataProviders: ["utl"],
  dataSources: [],
  dates: [],
  assets: [],
  discoveriesVisualizerState: "byTopics",
  trendsVisualizerState: "byTopics"
};

const DashboardContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.RESET_FILTERS:
      return { ...state, ...resetState };

    case actionTypes.UPDATE_TOPICS:
      return {
        ...state,
        topics: action.payload
      };

    case actionTypes.CLEAR_TOPICS:
      return {
        ...state,
        topics: resetState.topics
      };

    case actionTypes.UPDATE_DATA_SOURCES:
      return {
        ...state,
        dataSources: action.payload
      };

    case actionTypes.CLEAR_DATA_SOURCES:
      return {
        ...state,
        dataSources: resetState.dataSources
      };

    case actionTypes.UPDATE_DATES:
      return {
        ...state,
        dates: action.payload?.length ? action.payload : resetState.dates
      };

    case actionTypes.CLEAR_DATES:
      return {
        ...state,
        dates: resetState.dates
      };

    case actionTypes.UPDATE_ASSETS:
      return {
        ...state,
        assets: action.payload
      };

    case actionTypes.CLEAR_ASSETS:
      return {
        ...state,
        assets: resetState.assets
      };

    case actionTypes.SET_DISCOVERIES_VISUALIZER_STATE:
      return {
        ...state,
        discoveriesVisualizerState: action.payload
      };

    case actionTypes.SET_TRENDS_VISUALIZER_STATE:
      return {
        ...state,
        trendsVisualizerState: action.payload
      };
    default:
      return state;
  }
};

export const DashboardContextProvider = ({ children }) => {
  const [state, dispatch] = usePersistedStorage(reducer, {});
  const [isReady, setIsReady] = useState(false);
  const query = useQueryString();

  const filterOperations = useMemo(
    () => ({
      resetFilters: () => {
        dispatch({ type: actionTypes.RESET_FILTERS });
      },
      updateTopics: payload => {
        dispatch({
          type: actionTypes.UPDATE_TOPICS,
          payload
        });
      },
      clearTopics: () => {
        dispatch({ type: actionTypes.CLEAR_TOPICS });
      },
      updateDataSources: payload => {
        dispatch({
          type: actionTypes.UPDATE_DATA_SOURCES,
          payload
        });
      },
      clearDataSources: () => {
        dispatch({ type: actionTypes.CLEAR_DATA_SOURCES });
      },
      updateDates: payload => {
        dispatch({
          type: actionTypes.UPDATE_DATES,
          payload
        });
      },
      clearDates: () => {
        dispatch({ type: actionTypes.CLEAR_DATES });
      },
      updateAssets: payload => {
        dispatch({ type: actionTypes.UPDATE_ASSETS, payload });
      },
      clearAssets: () => {
        dispatch({ type: actionTypes.CLEAR_ASSETS });
      },
      setDiscoveriesVisualizerState: payload => {
        dispatch({
          type: actionTypes.SET_DISCOVERIES_VISUALIZER_STATE,
          payload
        });
      },
      setTrendsVisualizerState: payload => {
        dispatch({
          type: actionTypes.SET_TRENDS_VISUALIZER_STATE,
          payload
        });
      }
    }),
    [dispatch]
  );

  const storageValues = useMemo(
    () => JSON.parse(localStorage.getItem(DashboardStorageKey)),
    []
  );

  const getDefaultValue = useCallback(
    (params, paramName) =>
      isEmpty(params)
        ? storageValues?.[`${paramName}`] || resetState[`${paramName}`]
        : resetState[`${paramName}`],
    [storageValues]
  );

  const setParameters = useCallback(() => {
    const params = normalizeSeachParams(query.toString());

    filterOperations.updateDates(
      params?.filters?.dates?.length === 2
        ? params.filters.dates.map(d => Number(d))
        : getDefaultValue(params, "dates")
    );

    filterOperations.updateDataSources(
      params?.filters?.dataSources
        ? toArray(params.filters.dataSources)
        : getDefaultValue(params, "dataSources")
    );

    filterOperations.updateTopics(
      params?.filters?.topics
        ? toArray(params.filters.topics)
        : getDefaultValue(params, "topics")
    );

    filterOperations.updateAssets(
      params?.filters?.assets
        ? toArray(params.filters.assets)
        : getDefaultValue(params, "assets")
    );

    filterOperations.setDiscoveriesVisualizerState(
      params?.filters?.discoveriesVisualizerState
        ? params.filters.discoveriesVisualizerState
        : getDefaultValue(params, "discoveriesVisualizerState")
    );

    filterOperations.setTrendsVisualizerState(
      params?.filters?.trendsVisualizerState
        ? params.filters.trendsVisualizerState
        : getDefaultValue(params, "trendsVisualizerState")
    );

    setIsReady(true);

    if (!isEmpty(params)) {
      window.history.replaceState("", "Dashboard", "/dashboard");
    }
  }, [filterOperations, getDefaultValue, query]);

  useEffect(() => {
    if (isReady) {
      return;
    }

    setParameters();
  }, [isReady, query, setParameters]);

  const value = useMemo(
    () => ({
      filterContext: isReady
        ? { ...initialState, ...state }
        : { ...initialState },
      isFiltering: isReady
        ? !isEqual({ ...initialState }, { ...state })
        : false,
      filterOperations,
      isReady
    }),
    [isReady, state, filterOperations]
  );

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

DashboardContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashboardContext;

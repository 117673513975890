import {
  normalizeSeachParams,
  predefinedDates,
  toArray,
  toBoolean
} from "@salvus/shared/utils";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import createPersistedReducer from "use-persisted-reducer";
import { DiscoveriesStorageKey } from "../../../constants/common";
import { useQueryString } from "../../../utils/hooks/queryString";

const usePersistedStorage = createPersistedReducer(
  DiscoveriesStorageKey,
  localStorage
);

const actionTypes = {
  RESET_FILTERS: "RESET_FILTERS",
  UPDATE_TOPICS: "UPDATE_TOPICS",
  CLEAR_TOPICS: "CLEAR_TOPICS",
  UPDATE_DATA_SOURCES: "UPDATE_DATA_SOURCES",
  CLEAR_DATA_SOURCES: "CLEAR_DATA_SOURCES",
  UPDATE_DATES: "UPDATE_DATES",
  CLEAR_DATES: "CLEAR_DATES",
  UPDATE_SEARCH: "UPDATE_SEARCH",
  CLEAR_SEARCH: "CLEAR_SEARCH",
  UPDATE_ASSETS: "UPDATE_ASSETS",
  CLEAR_ASSETS: "CLEAR_ASSETS",
  SET_MUST_INCLUDE_ALL_KEYWORDS: "SET_MUST_INCLUDE_ALL_KEYWORDS",
  UPDATE_ACTIVITY_STATUS: "UPDATE_ACTIVITY_STATUS",
  CLEAR_ACTIVITY_STATUS: "CLEAR_ACTIVITY_STATUS",
  UPDATE_ACTIVITY_ACTIONS: "UPDATE_ACTIVITY_ACTIONS",
  CLEAR_ACTIVITY_ACTIONS: "CLEAR_ACTIVITY_ACTIONS"
};

const resetState = {
  topics: [],
  dataProviders: ["utl"],
  dataSources: [],
  dates: [
    predefinedDates().startOfYesterday.utc().valueOf(),
    predefinedDates().endOfTomorrow.utc().valueOf()
  ],
  search: [],
  assets: [],
  mustIncludeAllSearchWords: false,
  activityStatus: [],
  activityActions: []
};

const initialState = {
  topics: [],
  dataProviders: ["utl"],
  dataSources: [],
  dates: [],
  search: [],
  assets: [],
  mustIncludeAllSearchWords: false,
  activityStatus: [],
  activityActions: []
};

const DiscoveriesContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.RESET_FILTERS:
      return { ...state, ...resetState };

    case actionTypes.UPDATE_TOPICS:
      return {
        ...state,
        topics: action.payload
      };

    case actionTypes.CLEAR_TOPICS:
      return {
        ...state,
        topics: resetState.topics
      };

    case actionTypes.UPDATE_DATA_SOURCES:
      return {
        ...state,
        dataSources: action.payload
      };

    case actionTypes.CLEAR_DATA_SOURCES:
      return {
        ...state,
        dataSources: resetState.dataSources
      };

    case actionTypes.UPDATE_DATES:
      return {
        ...state,
        dates: action.payload?.length ? action.payload : resetState.dates
      };

    case actionTypes.CLEAR_DATES:
      return {
        ...state,
        dates: resetState.dates
      };

    case actionTypes.UPDATE_SEARCH:
      return {
        ...state,
        search: action.payload
      };

    case actionTypes.CLEAR_SEARCH:
      return {
        ...state,
        search: resetState.search
      };

    case actionTypes.UPDATE_ASSETS:
      return {
        ...state,
        assets: action.payload
      };

    case actionTypes.CLEAR_ASSETS:
      return {
        ...state,
        assets: resetState.assets
      };

    case actionTypes.SET_MUST_INCLUDE_ALL_KEYWORDS:
      return {
        ...state,
        mustIncludeAllSearchWords: action.payload
      };

    case actionTypes.UPDATE_ACTIVITY_STATUS:
      return {
        ...state,
        activityStatus: action.payload
      };

    case actionTypes.CLEAR_ACTIVITY_STATUS:
      return {
        ...state,
        activityStatus: resetState.dataSources
      };

    case actionTypes.UPDATE_ACTIVITY_ACTIONS:
      return {
        ...state,
        activityActions: action.payload
      };

    case actionTypes.CLEAR_ACTIVITY_ACTIONS:
      return {
        ...state,
        activityActions: resetState.dataSources
      };

    default:
      return state;
  }
};

export const DiscoveriesContextProvider = ({ children }) => {
  const [state, dispatch] = usePersistedStorage(reducer, initialState);
  const [isReady, setIsReady] = useState(false);
  const query = useQueryString();

  const filterOperations = useMemo(
    () => ({
      resetFilters: () => {
        dispatch({ type: actionTypes.RESET_FILTERS });
      },
      toggleTopics: payload => {
        dispatch({
          type: actionTypes.TOGGLE_TOPICS,
          payload
        });
      },
      updateTopics: payload => {
        dispatch({
          type: actionTypes.UPDATE_TOPICS,
          payload
        });
      },
      clearTopics: () => {
        dispatch({ type: actionTypes.CLEAR_TOPICS });
      },
      updateDataSources: payload => {
        dispatch({ type: actionTypes.UPDATE_DATA_SOURCES, payload });
      },
      clearDataSources: () => {
        dispatch({ type: actionTypes.CLEAR_DATA_SOURCES });
      },
      updateDates: payload => {
        dispatch({ type: actionTypes.UPDATE_DATES, payload });
      },
      clearDates: () => {
        dispatch({ type: actionTypes.CLEAR_DATES });
      },
      updateSearch: payload => {
        dispatch({ type: actionTypes.UPDATE_SEARCH, payload });
      },
      clearSearch: () => {
        dispatch({ type: actionTypes.CLEAR_SEARCH });
      },
      updateAssets: payload => {
        dispatch({ type: actionTypes.UPDATE_ASSETS, payload });
      },
      clearAssets: () => {
        dispatch({ type: actionTypes.CLEAR_ASSETS });
      },
      setMustIncludeAllSearchWords: payload => {
        dispatch({
          type: actionTypes.SET_MUST_INCLUDE_ALL_KEYWORDS,
          payload
        });
      },
      updateActivityStatus: payload => {
        dispatch({ type: actionTypes.UPDATE_ACTIVITY_STATUS, payload });
      },
      clearActivityStatus: () => {
        dispatch({ type: actionTypes.CLEAR_ACTIVITY_STATUS });
      },
      updateActivityActions: payload => {
        dispatch({ type: actionTypes.UPDATE_ACTIVITY_ACTIONS, payload });
      },
      clearActivityActions: () => {
        dispatch({ type: actionTypes.CLEAR_ACTIVITY_ACTIONS });
      }
    }),
    [dispatch]
  );

  const storageValues = useMemo(
    () => JSON.parse(localStorage.getItem(DiscoveriesStorageKey)),
    []
  );

  const getDefaultValue = useCallback(
    (params, paramName) =>
      isEmpty(params)
        ? storageValues?.[`${paramName}`] || resetState[`${paramName}`]
        : resetState[`${paramName}`],
    [storageValues]
  );

  const setParameters = useCallback(() => {
    const params = normalizeSeachParams(query.toString());

    filterOperations.updateSearch(
      params?.filters?.search
        ? toArray(params.filters.search)
        : getDefaultValue(params, "search")
    );

    filterOperations.updateDates(
      params?.filters?.dates?.length === 2
        ? params.filters.dates.map(d => Number(d))
        : getDefaultValue(params, "dates")
    );

    filterOperations.updateDataSources(
      params?.filters?.dataSources
        ? toArray(params.filters.dataSources)
        : getDefaultValue(params, "dataSources")
    );

    filterOperations.updateTopics(
      params?.filters?.topics
        ? toArray(params.filters.topics)
        : getDefaultValue(params, "topics")
    );

    filterOperations.updateAssets(
      params?.filters?.assets
        ? toArray(params.filters.assets)
        : getDefaultValue(params, "assets")
    );

    filterOperations.setMustIncludeAllSearchWords(
      // eslint-disable-next-line eqeqeq
      params?.filters?.mustIncludeAllSearchWords != undefined
        ? toBoolean(params.filters.mustIncludeAllSearchWords)
        : getDefaultValue(params, "mustIncludeAllSearchWords")
    );

    filterOperations.updateActivityStatus(
      params?.filters?.activityStatus
        ? toArray(params.filters.activityStatus)
        : getDefaultValue(params, "activityStatus")
    );

    filterOperations.updateActivityActions(
      params?.filters?.activityActions
        ? toArray(params.filters.activityActions)
        : getDefaultValue(params, "activityActions")
    );

    setIsReady(true);

    if (!isEmpty(params)) {
      window.history.replaceState("", "Discoveries", "/discoveries");
    }
  }, [filterOperations, getDefaultValue, query]);

  useEffect(() => {
    if (isReady) {
      return;
    }

    setParameters();
  }, [isReady, query, setParameters]);

  const value = useMemo(
    () => ({
      filterContext: isReady
        ? { ...initialState, ...state }
        : { ...initialState },
      isFiltering: isReady
        ? !isEqual({ ...initialState }, { ...state })
        : false,
      filterOperations,
      isReady
    }),
    [isReady, state, filterOperations]
  );
  return (
    <DiscoveriesContext.Provider value={value}>
      {children}
    </DiscoveriesContext.Provider>
  );
};

DiscoveriesContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default DiscoveriesContext;

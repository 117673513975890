import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";

import "./index.less";

const InfoTooltip = ({ info, props }) => (
  <Tooltip title={info}>
    <InfoCircleOutlined className="info-tooltip-icon" {...props} />
  </Tooltip>
);

InfoTooltip.propTypes = {
  info: PropTypes.string,
  props: PropTypes.object
};

InfoTooltip.defaultProps = {
  info: null,
  props: null
};

export default InfoTooltip;

export const createFilters = ({
  dates,
  dataProviders,
  dataSources,
  assets,
  topics
}) => {
  const filters = [];

  if (dates?.length === 2) {
    filters.push({
      fields: "publishedOn",
      alias: "Date",
      operation: "RANGE",
      values: dates
    });
  }

  if (dataProviders?.length) {
    filters.push({
      fields: "dataProviderKey",
      alias: "Data Providers",
      values: dataProviders
    });
  }

  if (dataSources?.length) {
    filters.push({
      fields: "dataSourceKey",
      alias: "Data Sources",
      values: dataSources
    });
  }

  if (assets?.length) {
    filters.push({
      fields: "assetKey",
      alias: "Assets",
      values: assets
    });
  }

  if (topics?.length) {
    filters.push({
      fields: "topicKey",
      alias: "Topics",
      values: topics
    });
  }

  return {
    filters
  };
};

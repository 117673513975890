import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Table
} from "antd";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Redirect, useParams } from "react-router-dom";
import ComponentLoader from "../../../components/ComponentLoader";
import LayoutContext from "../../../context/LayoutContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  topicKeywordsEndpoint,
  useApi
} from "../../API";
import { useAuthorization } from "../../Auth/hooks";
import KeywordForm from "./KeywordForm";
import Visualizer from "./Visualizer";

const { Search } = Input;

const Content = ({ onTopicNameChange }) => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const { hasSecurityRoles, adminRoles } = useAuthorization();

  const { topicId } = useParams();
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [formInstructions, setFormInstructions] = useState({});

  const canManage = useMemo(
    () => hasSecurityRoles(adminRoles.topics),
    [adminRoles.topics, hasSecurityRoles]
  );

  //
  const {
    request: getTopicKeywords,
    loading: loadingTopicKeywords,
    error: errorGetTopicKeywords
  } = useApi(
    topicKeywordsEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );
  const { request: putTopicKeywords } = useApi(
    topicKeywordsEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const fetchData = useCallback(async () => {
    const response = await getTopicKeywords({
      url: `${topicKeywordsEndpoint}/${topicId}`
    });
    const responseData = response.result._source || {};

    setData(responseData);
    onTopicNameChange(responseData?.name);
    // adding onTopicNameChange will cause this to be called twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTopicKeywords, topicId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRawValidation = useCallback(
    async value => {
      const found = data?.keywords?.find(i => i?.raw === value);
      return Promise.resolve(found);
    },
    [data]
  );

  const handleAddKeywords = useCallback(
    async (origPayload, newPayload) => {
      const newKeywords = [
        ...((origPayload
          ? data?.keywords?.filter(k => k.raw !== origPayload.raw)
          : data?.keywords) || []),
        newPayload
      ];

      const payload = {
        keywords: newKeywords
      };

      await putTopicKeywords({
        url: `${topicKeywordsEndpoint}/${topicId}`,
        data: payload
      });
      notify(notificationTypes.success, {
        message: "Topic Keyword Updated",
        description: `${newPayload.raw} has been successfully added`
      });
      fetchData();
      setFormInstructions({});
    },
    [data, putTopicKeywords, topicId, notify, notificationTypes, fetchData]
  );

  const handleRemoveKeywords = useCallback(
    async value => {
      const newKeywords = [
        ...(data?.keywords?.filter(k => k.raw !== value) || [])
      ];

      const payload = {
        keywords: newKeywords
      };

      await putTopicKeywords({
        url: `${topicKeywordsEndpoint}/${topicId}`,
        data: payload
      });
      notify(notificationTypes.success, {
        message: "Topic Keyword Updated",
        description: `${value} has been successfully removed`
      });
      fetchData();
      setFormInstructions({});
    },
    [data, putTopicKeywords, topicId, notify, notificationTypes, fetchData]
  );

  const columns = useMemo(
    () => [
      {
        title: "Words / Phrases",
        key: "raw",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.raw.localeCompare(b.raw),
        render: record => record?.raw
      },
      {
        title: "Exclude Proceeding",
        key: "excludeProceeding",
        render: record => record?.excludeProceeding?.join(", ")
      },
      {
        title: "Exclude Succeeding",
        key: "excludeSucceeding",
        render: record => record?.excludeSucceeding?.join(", ")
      },
      {
        title: "Exact Phrase Only",
        key: "exactPhraseOnly",
        width: 200,
        sorter: (a, b) => a.exactPhraseOnly - b.exactPhraseOnly,
        render: record => <div>{record?.exactPhraseOnly ? "Yes" : "No"}</div>
      },
      {
        title: "Include Plurals",
        key: "includePlurals",
        width: 200,
        sorter: (a, b) => a.includePlurals - b.includePlurals,
        render: record => <div>{record?.includePlurals ? "Yes" : "No"}</div>
      },
      {
        title: "Actions",
        className: "actions",
        width: 200,
        render: record => (
          <>
            <Button
              type="link"
              style={{ marginRight: "4px" }}
              disabled={!canManage}
              onClick={() => {
                setFormInstructions({
                  open: true,
                  onSaveAction: handleAddKeywords,
                  data: record
                });
              }}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Remove these words/phrases?"
              onConfirm={async () => handleRemoveKeywords(record?.raw)}
            >
              <Button
                icon={<DeleteOutlined />}
                type="link"
                danger
                disabled={!canManage}
              >
                Remove
              </Button>
            </Popconfirm>
          </>
        )
      }
    ],
    [canManage, handleAddKeywords, handleRemoveKeywords]
  );

  if (!topicId || (data != null && isEmpty(data))) {
    return <Redirect to="/topics" />;
  }

  if (errorGetTopicKeywords) {
    return (
      <ComponentLoader
        hasErrors={!!errorGetTopicKeywords}
        errorComponent="Unable to retrieve the data at this time"
      />
    );
  }
  return (
    <>
      <Row gutter={20} type="flex" align="middle" className="graphs-row">
        <Col span={24}>
          <Visualizer topicKeyword={data?.key} />
        </Col>
      </Row>

      <Row gutter={20} type="flex" align="middle" className="graphs-row">
        <Col span={24}>
          <Card
            className="container-card no-title-border no-actions-border"
            style={{ marginTop: "20px" }}
          >
            <Row key="row-actions" gutter={[5, 5]} className="top-filter-row">
              <Col flex="auto">
                <Search
                  key="keywords-search"
                  allowClear
                  placeholder="Search for Keyword..."
                  onSearch={value => setSearch(value)}
                />
              </Col>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  disabled={!canManage}
                  style={{
                    marginLeft: "auto"
                  }}
                  onClick={() => {
                    setFormInstructions({
                      open: true,
                      onSaveAction: handleAddKeywords
                    });
                  }}
                >
                  Add Keyword
                </Button>
              </Col>
            </Row>
            <Table
              className="table align-top"
              loading={loadingTopicKeywords}
              pagination={{
                defaultPageSize: 20,
                showSizeChanger: true,
                showTotal: total => `Total ${total} items`,
                hideOnSinglePage: true
              }}
              columns={columns}
              dataSource={data?.keywords?.filter(
                value =>
                  !search ||
                  value.raw
                    .split(" ")
                    .filter(Boolean)
                    .some(str => str?.includes(search.toLowerCase()))
              )}
              rowKey={item => item?.raw}
              scroll={{ x: true }}
            />
          </Card>
        </Col>
      </Row>

      <KeywordForm
        open={formInstructions?.open}
        onCancel={() => setFormInstructions({})}
        onSave={formInstructions?.onSaveAction}
        rawValidation={handleRawValidation}
        data={formInstructions?.data}
      />
    </>
  );
};

Content.propTypes = {
  onTopicNameChange: PropTypes.func
};

Content.defaultProps = {
  onTopicNameChange: () => {}
};

export default Content;

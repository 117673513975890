import { Col, Row } from "antd";
import React from "react";
import AssetsVisualizer from "./AssetsVisualizer";
import DiscoveriesVisualizer from "./DiscoveriesVisualizer";
import StatsVisualizer from "./StatsVisualizer";
import TrendVisualizer from "./TrendVisualizer";

const Content = () => (
  <>
    <Row gutter={[20, 20]} type="flex" align="middle" className="graphs-row">
      <Col xs={24} md={18}>
        <DiscoveriesVisualizer />
      </Col>
      <Col xs={24} md={6} flex="auto" className="chart-container">
        <AssetsVisualizer />
      </Col>
    </Row>

    <Row
      gutter={20}
      type="flex"
      align="middle"
      style={{ marginTop: "20px" }}
      className="graphs-row"
    >
      <Col span={24}>
        <TrendVisualizer />
      </Col>
    </Row>

    <Row gutter={20} type="flex" align="middle" style={{ marginTop: "20px" }}>
      <Col span={24}>
        <StatsVisualizer />
        <div style={{ margin: "5px", minHeight: "10px" }} />
      </Col>
    </Row>
  </>
);

export default Content;

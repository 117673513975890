import { Card, Col, Row, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ComponentLoader from "../../../components/ComponentLoader";
import {
  DefaultRequestConfig,
  RequestMethodType,
  analyticsEndpoint,
  useApi
} from "../../API";
import DashboardContext from "./Context";
import { createFilters } from "./utils";

const StatsVisualizer = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const { request: getAnalytics } = useApi(
    analyticsEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const {
    filterContext: { dataProviders },
    isReady
  } = useContext(DashboardContext);

  const fetchData = useCallback(async () => {
    const filters = createFilters({
      dataProviders
    });

    const payload = {
      requests: [
        {
          type: "stats-data-sources",
          key: "stats-data-sources",
          ...filters
        },
        {
          type: "count-of-discoveries",
          key: "count-of-discoveries",
          ...filters
        },
        {
          type: "stats-trained-models",
          key: "stats-trained-models",
          ...filters
        },
        {
          type: "stats-assets",
          key: "stats-assets",
          ...filters
        }
      ]
    };

    const response = await getAnalytics({ data: payload });

    setData(response?.result);
  }, [dataProviders, getAnalytics]);

  useEffect(() => {
    const asyncFetchData = async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);
    };

    if (isReady) {
      asyncFetchData();
    }
  }, [fetchData, isReady]);

  const renderTotalNumberOfReports = useCallback(() => {
    const reportData = data?.find(r => r.key === "stats-data-sources");

    return (
      <Card
        style={{ textAlign: "center" }}
        className="container-card no-title-border standout small-padding"
      >
        <ComponentLoader
          isLoading={loading}
          hasErrors={reportData?.errors}
          hasNoData={!reportData}
          noDataComponent="No stats to show"
        >
          <Typography.Text
            type="secondary"
            style={{
              fontSize: "1.2vw",
              margin: 0,
              fontWeight: "bold",
              textTransform: "uppercase"
            }}
          >
            Total reports ingested
          </Typography.Text>
          <Typography.Title
            level={1}
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: "3vw",
              fontWeight: "bolder"
            }}
          >
            {reportData?.results?.total?.value.toLocaleString("en-US")}
          </Typography.Title>
        </ComponentLoader>
      </Card>
    );
  }, [data, loading]);

  const renderTotalNumberOfAssets = useCallback(() => {
    const reportData = data?.find(r => r.key === "stats-assets");

    return (
      <Card
        style={{ textAlign: "center" }}
        className="container-card no-title-border standout small-padding"
      >
        <ComponentLoader
          isLoading={loading}
          hasErrors={reportData?.errors}
          hasNoData={!reportData}
          noDataComponent="No stats to show"
        >
          <Typography.Text
            type="secondary"
            style={{
              fontSize: "1.2vw",
              margin: 0,
              fontWeight: "bold",
              textTransform: "uppercase"
            }}
          >
            Total Assets Onboarded
          </Typography.Text>
          <Typography.Title
            level={1}
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: "3vw",
              fontWeight: "bolder"
            }}
          >
            {reportData?.results?.total?.value.toLocaleString("en-US")}
          </Typography.Title>
        </ComponentLoader>
      </Card>
    );
  }, [data, loading]);

  const renderTotalNumberOfTrainedModels = useCallback(() => {
    const reportData = data?.find(r => r.key === "stats-trained-models");

    return (
      <Card
        style={{ textAlign: "center" }}
        className="container-card no-title-border standout small-padding"
      >
        <ComponentLoader
          isLoading={loading}
          hasErrors={reportData?.errors}
          hasNoData={!reportData}
          noDataComponent="No stats to show"
        >
          <Typography.Text
            type="secondary"
            style={{
              fontSize: "1.2vw",
              margin: 0,
              fontWeight: "bold",
              textTransform: "uppercase"
            }}
          >
            Total NLP Models Trained
          </Typography.Text>
          <Typography.Title
            level={1}
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: "3vw",
              fontWeight: "bolder"
            }}
          >
            {reportData?.results?.total?.value.toLocaleString("en-US")}
          </Typography.Title>
        </ComponentLoader>
      </Card>
    );
  }, [data, loading]);

  const renderTotalNumberOfDiscoveries = useCallback(() => {
    const reportData = data?.find(r => r.key === "count-of-discoveries");

    return (
      <Card
        style={{ textAlign: "center" }}
        className="container-card no-title-border standout small-padding"
      >
        <ComponentLoader
          isLoading={loading}
          hasErrors={reportData?.errors}
          hasNoData={!reportData}
          noDataComponent="No stats to show"
        >
          <Typography.Text
            type="secondary"
            style={{
              fontSize: "1.2vw",
              margin: 0,
              fontWeight: "bold",
              textTransform: "uppercase"
            }}
          >
            Total discoveries made
          </Typography.Text>
          <Typography.Title
            level={1}
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: "3vw",
              fontWeight: "bolder"
            }}
          >
            {reportData?.results?.total?.value.toLocaleString("en-US")}
          </Typography.Title>
        </ComponentLoader>
      </Card>
    );
  }, [data, loading]);

  return (
    <Row gutter={12} className="stats-visualizer">
      <Col span={6}>{renderTotalNumberOfAssets()}</Col>
      <Col span={6}>{renderTotalNumberOfTrainedModels()}</Col>
      <Col span={6}>{renderTotalNumberOfReports()}</Col>
      <Col span={6}>{renderTotalNumberOfDiscoveries()}</Col>
    </Row>
  );
};

export default StatsVisualizer;

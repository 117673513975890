import { GlobalOutlined } from "@ant-design/icons";
import React from "react";

export const AccessTokenStorageKey = "auth.accessToken";
export const ActiveTenantKeyStorageKey = "tenants.activeTenantKey";
export const DashboardStorageKey = "dashboard";
export const DiscoveriesStorageKey = "discoveries";

export const GlobalTenantConfig = {
  key: "__ALL__",
  name: "Global",
  icon: <GlobalOutlined style={{ color: "green", marginRight: "0.5rem" }} />
};

import { Tabs } from "antd";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import ComponentLoader from "../../../components/ComponentLoader";
import {
  DefaultRequestConfig,
  RequestMethodType,
  tenantsEndpoint,
  useApi
} from "../../API";
import Assets from "./Assets";

const Content = ({ onTenantNameChange }) => {
  const { tenantId } = useParams();
  const [data, setData] = useState("1");
  const [activeTab, setActiveTab] = useState("1");

  //
  const { request: getTenant, error: errorGettingTenant } = useApi(
    tenantsEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const fetchData = useCallback(async () => {
    const response = await getTenant({
      url: `${tenantsEndpoint}/${tenantId}`
    });
    const responseData = response.result._source || {};
    setData(responseData);
    onTenantNameChange(responseData?.name);
    // adding onTenantNameChange will cause this to be called twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTenant, tenantId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!tenantId || (data !== undefined && isEmpty(data))) {
    return <Redirect to="/settings/tenants" />;
  }

  if (errorGettingTenant) {
    return (
      <ComponentLoader
        hasErrors={Boolean(errorGettingTenant)}
        errorComponent="Unable to retrieve the data at this time"
      />
    );
  }

  return (
    <Tabs
      activeKey={activeTab}
      onChange={setActiveTab}
      items={[
        {
          key: "1",
          label: "Assets",
          children: <Assets />
        }
      ]}
    />
  );
};

Content.propTypes = {
  onTenantNameChange: PropTypes.func
};

Content.defaultProps = {
  onTenantNameChange: () => {}
};

export default Content;

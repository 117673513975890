import { Button, Form, Input } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import LayoutContext from "../../../context/LayoutContext";

import {
  DefaultRequestConfig,
  RequestMethodType,
  useApi,
  usersEndpoint
} from "../../API";

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { flex: 1 }
};

const UserForm = ({ open, onCancel, onSave }) => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { request: postUserData } = useApi(
    usersEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const { resetFields, setFieldsValue } = form;

  const handleCleanup = useCallback(async () => {
    resetFields();
    setFieldsValue({});
    setIsDirty(false);
    setSaving(false);
  }, [resetFields, setFieldsValue]);

  const handleOnCancel = useCallback(async () => {
    handleCleanup();
    onCancel();
  }, [onCancel, handleCleanup]);

  const handleOnSave = useCallback(
    async values => {
      setSaving(true);
      try {
        const formData = values;
        const payload = {
          ...formData
        };

        await postUserData({ data: payload });

        notify(notificationTypes.success, {
          message: "User was Added",
          description: `User ${payload.firstName} ${payload.lastName} has been successfully added`
        });

        onSave(payload);
        handleCleanup();
      } catch (err) {
        setSaving(false);
        notify(notificationTypes.error, {
          message: "Unable to add this user",
          description: err?.response?.data?.message || err.message || ""
        });
      }
    },
    [postUserData, notify, notificationTypes, onSave, handleCleanup]
  );

  return !open ? null : (
    <CustomModal
      key="user-modal"
      title="Add User"
      open={open}
      onCancel={handleOnCancel}
      footer={[
        <div style={{ flex: "1 1 0%" }} key="actions">
          <Button key="cancel" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={saving}
            onClick={() => form.submit()}
            disabled={saving || !isDirty}
          >
            Save
          </Button>
        </div>
      ]}
    >
      <Form
        key="form"
        onFinish={handleOnSave}
        form={form}
        {...formItemLayout}
        className="form-slim"
      >
        <Form.Item
          key="firstname"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input First Name"
            }
          ]}
          label="First Name"
          onChange={() => setIsDirty(true)}
        >
          <Input key="firstnameinput" />
        </Form.Item>

        <Form.Item
          key="lastname"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please input Last Name"
            }
          ]}
          label="Last Name"
          onChange={() => setIsDirty(true)}
        >
          <Input key="lastnameinput" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input a valid email"
            }
          ]}
          label="Email"
          onChange={() => setIsDirty(true)}
        >
          <Input />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

UserForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  id: PropTypes.string
};

UserForm.defaultProps = {
  open: false,
  onCancel: () => {},
  onSave: () => {},
  id: null
};

export default UserForm;

import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { getSimilarData } from "../../../utils/api";
import { getItemsFromBasicQuery } from "../../../utils/hooks/fetchlfidata";
import { useCustomPOSTRequest } from "../../../utils/hooks/restRequest";
import ComponentLoader from "../../ComponentLoader";
import CustomDrawer from "../../CustomDrawer";
import Item from "./Item";

const SimilarItems = ({ open, title, instructions, onClose }) => {
  const [data, setData] = useState(null);
  const {
    postData: apiGet,
    loading,
    error
  } = useCustomPOSTRequest(getSimilarData);

  const fetchData = useCallback(async () => {
    const getSimilar = await apiGet({
      searchFor: instructions?.searchFor,
      maxResults: instructions?.maxResults,
      samplingSize: instructions?.samplingSize,
      threshold: instructions?.threshold
    });
    setData(getItemsFromBasicQuery(getSimilar.body.hits));
  }, [apiGet, instructions]);

  useEffect(() => {
    if (!open || !instructions?.searchFor || error) {
      return;
    }

    fetchData();
  }, [instructions, open, error, fetchData]);

  return !open ? null : (
    <CustomDrawer open={open} title={title} onClose={onClose}>
      <ComponentLoader
        isLoading={!data && loading && error !== null}
        hasNoData={data && !data?.length}
        hasErrors={error !== null}
        errorComponent="NO RELATED LEARNINGS FOUND"
        noDataComponent="NO RELATED LEARNINGS FOUND"
      >
        {data?.map(i => (
          <Item
            key={`${i.id}${i.title}`}
            data={i}
            selectedKeywords={[]}
            compact
          />
        ))}
      </ComponentLoader>
    </CustomDrawer>
  );
};

SimilarItems.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  instructions: PropTypes.object,
  onClose: PropTypes.func
};

SimilarItems.defaultProps = {
  open: false,
  title: null,
  instructions: null,
  onClose: () => {}
};

export default SimilarItems;

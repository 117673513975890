import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Radio, Row, Spin, Tabs, theme } from "antd";
import { get, omit, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { AutoSizer } from "react-virtualized";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import useLocalStorageState from "use-local-storage-state";
import AnimatedNumber from "../../components/AnimatedNumber";
import ComponentLoader from "../../components/ComponentLoader";
import Item from "../../components/Learning/Results/Item";
import SimilarItems from "../../components/Learning/Results/SimilarItems";
import { SORT_BY_OPTIONS, SORT_OPTIONS } from "../../constants/learning";
import { addUserSearchHistory } from "../../utils/api";
import { useFetchLFIResults } from "../../utils/hooks/fetchlfidata";
import { useCustomPOSTRequest } from "../../utils/hooks/restRequest";
import LearningContext from "./LearningContext";
import KeywordSuggestReplacer from "./keywordSuggestReplacer";

const { TabPane } = Tabs;
const { useToken } = theme;

const ResultTabPane = ({ sortOption, onLFICountUpdated, sortByOption }) => {
  const { filterContext, availableSuggestions, isReady } =
    useContext(LearningContext);
  const [similarItemsInstructions, setSimilarItemsInstructions] =
    useState(null);
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);
  const queryArguments = useMemo(
    () => ({
      filterContext: {
        ...filterContext,
        selectedSortOption: sortOption,
        availableSuggestions: {
          ...omit(availableSuggestions, ["activities"]),
          activities: availableSuggestions.activities.map(act => ({
            label: act.label,
            value: act.value
          }))
        },
        sortByOption
      }
    }),
    [sortOption, filterContext, availableSuggestions, sortByOption]
  );
  const { selectedKeywords, selectedActivities } = filterContext;
  const { postData } = useCustomPOSTRequest(addUserSearchHistory);

  const uploadUserSearch = useCallback(
    async (context, item) => {
      await postData({ context, item });
    },
    [postData]
  );

  const { results, loading, hasMore, fetchMore, resultCount, refetch } =
    useFetchLFIResults(isReady, queryArguments);

  const loadMore = () => {
    if (!loading) {
      fetchMore();
    }
  };

  useEffect(() => {
    onLFICountUpdated(sortOption, resultCount);
  }, [resultCount, onLFICountUpdated, sortOption]);

  const heightMap = useRef({});
  const listRef = useRef({});
  const updateHeightMap = (index, size) => {
    heightMap.current = { ...heightMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  };

  const uniqResults = useMemo(() => uniqBy(results, "id"), [results]);

  const getItemSize = index => heightMap.current[index] || 270;

  const generateItem = (item, index) => (
    <Item
      key={`${item.id}${item?.title}`}
      data={item}
      selectedKeywords={selectedKeywords}
      selectedActivities={selectedActivities}
      showSimilarIncident
      selected={similarItemsInstructions?.selected === item.id}
      onItemSelected={value => {
        setSimilarItemsInstructions(value);
      }}
      onItemReacted={() => {
        setIsRefreshNeeded(true);
      }}
      onOpenLink={() => uploadUserSearch(filterContext, item)}
      updateHeight={height => {
        updateHeightMap(index, height);
      }}
    />
  );

  const getItemAt = index => generateItem(uniqResults.at(index), index);
  const items = () =>
    uniqResults.map((item, index) => generateItem(item, index));

  const rowRenderer = ({ index, style }) => (
    <div style={{ marginTop: "6px", ...style }}>{getItemAt(index)}</div>
  );

  return (
    <>
      <SimilarItems
        open={similarItemsInstructions?.open}
        instructions={similarItemsInstructions?.instructions}
        title="Similar Learnings"
        onClose={() => {
          setSimilarItemsInstructions(null);
        }}
      />
      <Row justify="end">
        <Col>
          <Button
            type="primary"
            style={{ zIndex: 1000 }}
            disabled={!isRefreshNeeded}
            loading={loading}
            onClick={async () => {
              await refetch();
              setIsRefreshNeeded(false);
            }}
          >
            Refresh
          </Button>
        </Col>
      </Row>

      <div className="result-content-wrapper">
        <AutoSizer disableWidth style={{ height: "100%" }}>
          {({ width, height }) => (
            <InfiniteLoader
              isItemLoaded={() => !hasMore}
              loadMoreItems={(_startIndex, stopIndex) => {
                if (stopIndex > uniqResults.length - 7) {
                  loadMore();
                }
              }}
              itemCount={uniqResults.length - (hasMore ? 1 : 0)}
              threshold={10}
            >
              {({ onItemsRendered, ref: setRef }) => (
                <Spin spinning={loading}>
                  <ComponentLoader
                    // isLoading={!items || loading}
                    isLoading={false}
                    hasNoData={results && !results?.length}
                    noDataComponent="No data found matching this criteria"
                  >
                    <VariableSizeList
                      ref={el => {
                        setRef(el);
                        listRef.current = el;
                      }}
                      height={height}
                      width={width}
                      itemSize={getItemSize}
                      itemCount={uniqResults.length}
                      onItemsRendered={onItemsRendered}
                      itemData={items}
                    >
                      {rowRenderer}
                    </VariableSizeList>
                  </ComponentLoader>
                </Spin>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    </>
  );
};

ResultTabPane.propTypes = {
  sortOption: PropTypes.string.isRequired,
  onLFICountUpdated: PropTypes.func.isRequired,
  sortByOption: PropTypes.string.isRequired
};

const Results = () => {
  const { token } = useToken();
  const {
    availableSuggestions: { sortOptions, sortByOptions }
  } = useContext(LearningContext);

  // Store the Current Sort Option
  const [currentSortOption, setCurrentSortOption] = useLocalStorageState(
    "persistedSortOption",
    { defaultValue: get(SORT_OPTIONS, "0.key", "mostRelated") }
  );

  const [sortBy, setSortBy] = useLocalStorageState("persistedSortBy", {
    defaultValue: get(SORT_BY_OPTIONS, "0.key", "closestMatch")
  });

  const [sortOptionsResultCount, setSortOptionsResultCount] = useState({});

  const onLFICountUpdated = useCallback((sortOptionKey, count) => {
    setSortOptionsResultCount(val => ({ ...val, [sortOptionKey]: count }));
  }, []);

  const displayCount = useMemo(
    () => sortOptionsResultCount[currentSortOption],
    [sortOptionsResultCount, currentSortOption]
  );

  return (
    <div className="results-container">
      <Row justify="space-between" align="bottom">
        <Col>
          <div className="result-count">
            Learnings:{" "}
            <span className="result-count-number">
              <AnimatedNumber value={Math.max(displayCount, 0)} />
            </span>
          </div>
        </Col>
        <Col>
          <Dropdown
            overlayStyle={{ zIndex: 9990 }}
            overlay={
              <Radio.Group
                onChange={e => setSortBy(e.target.value)}
                style={{
                  width: 200,
                  height: 100,
                  padding: 12,
                  borderRadius: 10,
                  border: `1px solid ${token.colorBorder}`,
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around"
                }}
                value={sortBy}
              >
                {sortByOptions.map(item => (
                  <Radio
                    key={item.key}
                    value={item.key}
                    className="sort-ranking-option"
                  >
                    <span
                      className="sort-ranking-option"
                      style={{ fontSize: 18 }}
                    >
                      {item.name}
                    </span>
                  </Radio>
                ))}
              </Radio.Group>
            }
          >
            <Button
              type="link"
              className="sort-ranking-button"
              icon={<DownOutlined />}
            >
              Sort by:{" "}
              <span className="sort-by-option">
                {sortByOptions.find(item => item.key === sortBy).name}
              </span>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <KeywordSuggestReplacer />
      <Tabs
        className="results-tabs"
        onChange={setCurrentSortOption}
        defaultActiveKey={currentSortOption}
      >
        {sortOptions.map(item => {
          const keyText =
            sortOptionsResultCount[item.key] >= 0
              ? `(${Math.max(sortOptionsResultCount[item.key], 0)})`
              : "";
          return (
            <TabPane
              tab={`${item.name} ${keyText}`}
              key={item.key}
              forceRender
              disabled={sortOptionsResultCount[item.key] <= 0}
            >
              <ResultTabPane
                sortOption={item.key}
                sortByOption={sortBy}
                onLFICountUpdated={onLFICountUpdated}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default Results;

import { InfoCircleOutlined } from "@ant-design/icons";
import { longHourTimeFormat, newDateWithFormat } from "@salvus/shared/utils";
import { Button, Form, Input, Select, TimePicker, Typography } from "antd";
import { omit } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import CustomSelect from "../../../components/CustomSelect";
import LayoutContext from "../../../context/LayoutContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  tenantsEndpoint,
  useApi
} from "../../API";

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { flex: 1 }
};

const { TextArea } = Input;
const { Option } = Select;

const TenantForm = ({ open, onCancel, onSave, id }) => {
  const { notify, notificationTypes } = useContext(LayoutContext);

  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [status, setStatus] = useState("inactive");
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [schedulers, setSchedulers] = useState();

  const { request: getTenant } = useApi(
    tenantsEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const { request: postTenant } = useApi(
    tenantsEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const { request: putTenant } = useApi(
    tenantsEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const { resetFields, setFieldsValue } = form;
  const modalTitle = id ? `Edit: ${data?.name}` : `Add Tenant`;

  const fetchData = useCallback(async () => {
    const response = await getTenant({
      url: `${tenantsEndpoint}/${id}`
    });
    const responseData = response.result._source || {};

    setData(responseData);
    resetFields();

    setFieldsValue(omit(responseData, ["isOnboarded", "status"]));
    setIsOnboarded(responseData?.isOnboarded);
    setStatus(responseData?.status);
    setSchedulers(responseData?.schedulers);
  }, [getTenant, id, resetFields, setFieldsValue]);

  useEffect(() => {
    if (!open || !id) {
      return;
    }
    fetchData();
  }, [open, id, fetchData]);

  const handleIsOnboardedChange = useCallback(value => {
    setIsDirty(true);
    setIsOnboarded(value);
  }, []);

  const handleStatusChange = useCallback(value => {
    setIsDirty(true);
    setStatus(value);
  }, []);

  const handleSchedulerChange = useCallback((time, timeString) => {
    setIsDirty(true);
    setSchedulers(prev => ({
      ...prev,
      discoveriesReport: timeString
    }));
  }, []);

  const handleCleanup = useCallback(async () => {
    resetFields();
    setData();
    setFieldsValue({});
    setIsOnboarded(false);
    setSchedulers();
    setStatus("inactive");
    setIsDirty(false);
    setSaving(false);
  }, [resetFields, setFieldsValue]);

  const handleOnCancel = useCallback(async () => {
    handleCleanup();
    onCancel();
  }, [onCancel, handleCleanup]);

  const handleOnSave = useCallback(
    async values => {
      setSaving(true);
      try {
        const payload = {
          ...values,
          isOnboarded,
          status,
          schedulers
        };

        if (id) {
          await putTenant({ url: `${tenantsEndpoint}/${id}`, data: payload });
        } else {
          await postTenant({ data: payload });
        }

        notify(notificationTypes.success, {
          message: `Tenant ${id ? "Updated" : "Added"}`,
          description: `${payload.name} has been successfully ${
            id ? "updated" : "added"
          }`
        });

        onSave(payload);
        handleCleanup();
      } catch (err) {
        setSaving(false);
        notify(notificationTypes.error, {
          message: id
            ? "Unable to update this tenant"
            : "Unable to add this tenant",
          description: err?.response?.data?.message || err.message || ""
        });
      }
    },
    [
      isOnboarded,
      status,
      schedulers,
      id,
      notify,
      notificationTypes,
      onSave,
      handleCleanup,
      putTenant,
      postTenant
    ]
  );

  return !open || (id && !data) ? null : (
    <CustomModal
      key="tenant-modal"
      title={modalTitle}
      open={open}
      onCancel={handleOnCancel}
      footer={[
        <div style={{ flex: "1 1 0%" }} key="actions">
          <Button key="cancel" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={saving}
            onClick={() => form.submit()}
            disabled={saving || !isDirty}
          >
            Save
          </Button>
        </div>
      ]}
    >
      <Form
        key="form"
        onFinish={handleOnSave}
        form={form}
        {...formItemLayout}
        className="form-slim"
      >
        {data && (
          <Form.Item key="key" name="key" label="Key">
            <Typography.Text className="ant-form-text" type="secondary">
              {data.key}
            </Typography.Text>
          </Form.Item>
        )}

        <Form.Item
          key="name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a name"
            }
          ]}
          label="Name"
          onChange={() => setIsDirty(true)}
        >
          <Input key="nameinput" />
        </Form.Item>

        <Form.Item
          key="description"
          name="description"
          rules={[
            {
              required: false
            }
          ]}
          label="Description"
        >
          <TextArea
            rows={4}
            style={{ height: 120, resize: "none" }}
            onChange={() => setIsDirty(true)}
          />
        </Form.Item>

        <Form.Item key="isOnboarded" name="isOnboarded" label="Onboarded">
          <div>
            <CustomSelect
              value={isOnboarded}
              placeholder="Has Onboarded?..."
              onChange={handleIsOnboardedChange}
              mode=""
            >
              {[
                { label: "Yes", value: true },
                { label: "No", value: false }
              ].map(i => (
                <Option key={i.value} value={i.value}>
                  {i.label}
                </Option>
              ))}
            </CustomSelect>
          </div>
        </Form.Item>

        <Form.Item key="status" name="status" label="Status">
          <div>
            <CustomSelect
              placeholder="Select status..."
              mode=""
              value={status}
              onChange={handleStatusChange}
            >
              {[
                { label: "Active", key: "active" },
                { label: "Inactive", key: "inactive" }
              ].map(i => (
                <Option key={i.key} value={i.key}>
                  {i.label}
                </Option>
              ))}
            </CustomSelect>
          </div>
        </Form.Item>

        <Form.Item
          key="schedulers"
          name="schedulers"
          label="Daily Discovery Schedule"
          tooltip={{
            title:
              "Please select the UTC Hour and Minute to send the daily discovery email report.",
            icon: <InfoCircleOutlined />
          }}
        >
          <div>
            <TimePicker
              value={
                schedulers?.discoveriesReport
                  ? newDateWithFormat(
                      schedulers.discoveriesReport,
                      longHourTimeFormat
                    )
                  : null
              }
              format={longHourTimeFormat}
              minuteStep={30}
              onChange={handleSchedulerChange}
            />
          </div>
        </Form.Item>

        <Form.Item
          key="communicationEndpoint"
          name="communicationEndpoint"
          rules={[
            {
              type: "url",
              message: "Please input a valid url"
            }
          ]}
          label="Teams Channel Webhook"
          tooltip={{
            title:
              "Paste your Teams Channel Incoming Webhook Uri here and we will send alerts to this channel.",
            icon: <InfoCircleOutlined />
          }}
          onChange={() => setIsDirty(true)}
        >
          <Input key="communicationEndpointinput" />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

TenantForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  id: PropTypes.string
};

TenantForm.defaultProps = {
  open: false,
  onCancel: () => {},
  onSave: () => {},
  id: null
};

export default TenantForm;

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { animated, useSpring } from "react-spring";

const AnimatedNumber = ({ value, formatValue, ...props }) => {
  const [animatedProps, set] = useSpring(() => ({ value, from: { value: 0 } }));

  useEffect(() => {
    set({ value });
  }, [value, set, formatValue]);

  return <animated.span {...props}>{animatedProps.value.interpolate(v => formatValue(v))}</animated.span>;
};

AnimatedNumber.propTypes = {
  value: PropTypes.number,
  formatValue: PropTypes.func
};

AnimatedNumber.defaultProps = {
  value: 0,
  formatValue: n => Math.floor(n, 10)
};

export default AnimatedNumber;

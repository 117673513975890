import { Column } from "@ant-design/plots";
import { serializeSearchParams } from "@salvus/shared/utils";
import { startCase } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import tinyColor from "tinycolor2";
import ComponentLoader from "../../../components/ComponentLoader";
import CustomCard from "../../../components/CustomCard";
import TenantContext from "../../../context/TenantContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  analyticsEndpoint,
  useApi
} from "../../API";
import OvalSegmented from "../components/OvalSegmented";
import DashboardContext from "./Context";
import { createFilters } from "./utils";

const dimensions = {
  byAsset: [
    { key: "assetKey", order: "asc" },
    { key: "topicKey", order: "asc" }
  ],
  byTopics: [
    { key: "topicKey", order: "asc" },
    { key: "assetKey", order: "asc" }
  ]
};

const numberOfItemsToShow = 3;

const DiscoveriesVisualizer = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const history = useHistory();

  const {
    filterContext: {
      topics,
      dataProviders,
      assets,
      dates,
      discoveriesVisualizerState
    },
    filterOperations: { setDiscoveriesVisualizerState },
    isReady
  } = useContext(DashboardContext);

  const { request: getAnalytics, loading: loadingAnalytics } = useApi(
    analyticsEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const { activeTenantAssets, activeTenantTopics } = useContext(TenantContext);

  const serializedParams = useCallback(
    (selectedTopics, selectedAssets) =>
      serializeSearchParams({
        filters: {
          dates,
          dataProviders,
          topics: selectedTopics,
          assets: selectedAssets
        }
      }),
    [dataProviders, dates]
  );

  const fetchData = useCallback(async () => {
    const payload = {
      requests: [
        {
          type: "count-of-discoveries",
          dimensions: dimensions[discoveriesVisualizerState],
          ...createFilters({
            topics,
            dataProviders,
            assets,
            dates
          })
        }
      ]
    };

    const response = await getAnalytics({ data: payload });

    if (response?.result?.[0]?.errors) {
      setError(true);
      return;
    }

    setData(response?.result?.[0]?.results?.data);
  }, [
    discoveriesVisualizerState,
    topics,
    dataProviders,
    assets,
    dates,
    getAnalytics
  ]);

  const numberOfFiltersPerSource =
    discoveriesVisualizerState === "byTopics"
      ? !assets?.length
        ? activeTenantAssets.length
        : assets?.length
      : !topics?.length
      ? activeTenantTopics.length
      : topics?.length;

  useEffect(() => {
    const asyncFetchData = async () => {
      await fetchData();
    };

    if (isReady) {
      asyncFetchData();
    }
  }, [fetchData, isReady]);

  const lookupValue = useCallback(
    (type, value) => {
      let source = "";

      if (type === "series") {
        source =
          discoveriesVisualizerState === "byTopics"
            ? activeTenantTopics
            : activeTenantAssets;
      }

      if (type === "xAxis") {
        source =
          discoveriesVisualizerState === "byTopics"
            ? activeTenantAssets
            : activeTenantTopics;
      }

      const findItem = source.find(i => i.key === value);
      return startCase(findItem?.name || "");
    },
    [discoveriesVisualizerState, activeTenantAssets, activeTenantTopics]
  );

  const GraphComponent = useMemo(
    () => (
      <Column
        {...{
          data: data?.filter(d => d?.yAxis),
          appendPadding: 5,
          renderer: "svg",
          autoFit: false,
          isStack: true,
          xField: "xAxis",
          yField: "yAxis",
          seriesField: "series",
          legend: {
            position: "right-top",
            offsetX: 10
          },
          meta: {
            series: {
              formatter: value => lookupValue("series", value)
            },
            xAxis: {
              formatter: value => lookupValue("xAxis", value)
            }
          },
          animation: {
            appear: {
              animation: "scale-in-y", // Effects of the first animation
              duration: 500 // Duration of the first animation
            }
          },
          color: ({ series }) => {
            const findItem =
              discoveriesVisualizerState === "byTopics"
                ? activeTenantTopics.find(i => i.key === series)
                : activeTenantAssets.find(i => i.key === series);
            return findItem?.color || tinyColor.random().toHexString();
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
              autoEllipsis: true
            }
          },
          onReady: plot => {
            plot.on("plot:click", params => {
              const { data: clickedData } = params.data;
              const selectedTopics =
                discoveriesVisualizerState === "byAsset"
                  ? clickedData.xAxis
                  : clickedData.series;
              const selectedAssets =
                discoveriesVisualizerState === "byTopics"
                  ? clickedData.xAxis
                  : clickedData.series;
              history.push(
                `/discoveries?${serializedParams(
                  selectedTopics,
                  selectedAssets
                )}`
              );
            });
          },
          ...(numberOfFiltersPerSource > numberOfItemsToShow
            ? {
                slider: {
                  start: 0,
                  end: 0.5,
                  formatter: datum => {
                    const source =
                      discoveriesVisualizerState === "byTopics"
                        ? activeTenantAssets
                        : activeTenantTopics;
                    const findItem = source.find(i => i.key === datum);
                    return startCase(findItem?.name);
                  }
                }
              }
            : {})
        }}
        style={{ height: "100%" }}
      />
    ),
    [
      data,
      numberOfFiltersPerSource,
      lookupValue,
      discoveriesVisualizerState,
      activeTenantTopics,
      history,
      serializedParams,
      activeTenantAssets
    ]
  );

  return (
    <CustomCard
      header={{
        title: "Discoveries",
        description: `Number of discoveries per ${
          discoveriesVisualizerState === "byTopics" ? "Topics" : "Assets"
        }`,
        extra: (
          <OvalSegmented
            options={[
              { value: "byTopics", label: "By Topics" },
              { value: "byAsset", label: "By Assets" }
            ]}
            onChange={value => setDiscoveriesVisualizerState(value)}
            value={discoveriesVisualizerState}
          />
        )
      }}
      className="discoveries-visualizer"
    >
      <ComponentLoader
        isLoading={loadingAnalytics || !data}
        hasErrors={error}
        errorComponent="Unable to retrieve the data at this time"
        hasNoData={data != null && !data?.length}
        noDataComponent="No discoveries to show"
      >
        {GraphComponent}
      </ComponentLoader>
    </CustomCard>
  );
};

export default DiscoveriesVisualizer;

import {
  normalizeSeachParams,
  predefinedDates,
  toArray,
  toBoolean
} from "@salvus/shared/utils";
import { isArray, isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import createPersistedReducer from "use-persisted-reducer";
import {
  SORT_BY_OPTIONS,
  SORT_OPTIONS,
  activityTypes,
  contentTypes,
  dataSources
} from "../../constants/learning";
import { getAggregateQuery, getSuggestedKeywords } from "../../utils/api";
import { useQueryString } from "../../utils/hooks/queryString";
import {
  useCustomGETRequest,
  useCustomPOSTRequest
} from "../../utils/hooks/restRequest";

const defaultStartDate = "01/01/2016";

const storageKey = "LearningState";

const usePersistedStorage = createPersistedReducer(storageKey, localStorage);

const actionTypes = {
  RESET_FILTERS: "RESET_FILTERS",
  PARTIALLY_RESET_FILTERS: "PARTIALLY_RESET_FILTERS",
  ACTIVITY_UPDATED: "ACTIVITY_UPDATED",
  ADD_ACTIVITY: "ADD_ACTIVITY",
  REMOVE_ACTIVITY: "REMOVE_ACITIVITY",
  KEYWORDS_UPDATED: "KEYWORDS_UPDATED",
  REMOVE_KEYWORD: "REMOVE_KEYWORD",
  LFI_SOURCES_UPDATED: "LFI_SOURCES_UPDATED",
  DATE_RANGE_UPDATED: "DATE_RANGE_UPDATED",
  SET_SUGGESTING_KEYWORDS: "SET_SUGGESTING_KEYWORDS",
  SET_FILTER_CONTEXT: "SET_FILTER_CONTEXT",
  SELECT_ALL_ACTIVITY: "SELECT_ALL_ACTIVITY",
  SET_SYNONYMS_ENABLED: "SET_SYNONYMS_ENABLED",
  SET_COUNTRIES: "SET_COUNTRIES",
  TOGGLE_COUNTRIES: "TOGGLE_COUNTRIES",
  SET_MUST_INCLUDE_ALL_KEYWORDS: "SET_MUST_INCLUDE_ALL_KEYWORDS",
  RESET_KEYWORDS_FILTERS: "RESET_KEYWORDS_FILTERS",
  UPDATE_LFIIDS: "UPDATE_LFIIDS",
  CLEAR_LFIIDS: "CLEAR_LFIIDS",
  SET_FILE_NAME: "SET_FILE_NAME",
  CLEAR_FILE_NAME: "CLEAR_FILE_NAME"
};

const availableSuggestions = {
  activities: activityTypes,
  contentTypes,
  dataSources,
  sortOptions: SORT_OPTIONS,
  sortByOptions: SORT_BY_OPTIONS,
  countries: []
};

const resetState = {
  selectedActivities: ["Other"],
  selectedLFIIds: [],
  selectedKeywords: [],
  selectedDataSources: dataSources.map(item => item.value),
  selectedDateRange: [
    predefinedDates(defaultStartDate).startOfYear.utc().valueOf(),
    predefinedDates().endOfDay.utc().valueOf()
  ],
  selectedSortOption: SORT_OPTIONS[0],
  selectedCountries: [],
  synonymsEnabled: false,
  mustIncludeAllKeywords: false,
  file: ""
};

const initialState = {
  selectedActivities: ["Other"],
  selectedLFIIds: [],
  selectedKeywords: [],
  selectedDataSources: [],
  selectedDateRange: [],
  selectedSortOption: SORT_OPTIONS[0],
  selectedCountries: [],
  synonymsEnabled: false,
  mustIncludeAllKeywords: false,
  file: ""
};
const LearningContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.RESET_FILTERS:
      return { ...state, ...resetState };

    case actionTypes.PARTIALLY_RESET_FILTERS:
      return {
        ...state,
        ...initialState,
        selectedActivities: state.selectedActivities,
        selectedLFIIds: state.selectedLFIIds,
        selectedKeywords: state.selectedKeywords,
        selectedCountries: state.selectedCountries,
        synonymsEnabled: state.synonymsEnabled,
        mustIncludeAllKeywords: state.mustIncludeAllKeywords
      };

    case actionTypes.SET_SYNONYMS_ENABLED:
      return {
        ...state,
        synonymsEnabled: action.payload
      };

    case actionTypes.RESET_KEYWORDS_FILTERS:
      return {
        ...state,
        ...initialState,
        selectedActivities: resetState.selectedActivities
      };

    case actionTypes.DATE_RANGE_UPDATED:
      return {
        ...state,
        selectedDateRange: action.payload?.length
          ? action.payload
          : resetState.selectedDateRange
      };

    case actionTypes.LFI_SOURCES_UPDATED:
      return { ...state, selectedDataSources: action.payload };

    case actionTypes.SET_SUGGESTING_KEYWORDS:
      return { ...state, suggestingKeywords: action.payload };

    case actionTypes.ADD_ACTIVITY:
      return {
        ...state,
        selectedActivities: [...state.selectedActivities, action.payload]
      };

    case actionTypes.REMOVE_KEYWORD:
      return {
        ...state,
        selectedKeywords: state.selectedKeywords.filter(
          keyword => keyword.value !== action.payload.value
        )
      };

    case actionTypes.ACTIVITY_UPDATED:
      return {
        ...state,
        selectedActivities: action.payload
      };

    case actionTypes.REMOVE_ACTIVITY:
      return {
        ...state,
        selectedActivities: state.selectedActivities.filter(
          e => e !== action.payload
        )
      };

    case actionTypes.KEYWORDS_UPDATED:
      return {
        ...state,
        selectedKeywords: action.payload
      };

    case actionTypes.SET_FILTER_CONTEXT:
      return {
        ...state,
        ...action.payload
      };

    case actionTypes.SELECT_ALL_ACTIVITY:
      return {
        ...state,
        selectedActivities: availableSuggestions.activities
      };

    case actionTypes.SET_COUNTRIES:
      return {
        ...state,
        selectedCountries: action.payload || []
      };

    case actionTypes.TOGGLE_COUNTRIES:
      return {
        ...state,
        selectedCountries: state.selectedCountries.includes(action.payload)
          ? state.selectedCountries.filter(a => a !== action.payload)
          : [...state.selectedCountries, action.payload]
      };

    case actionTypes.SET_MUST_INCLUDE_ALL_KEYWORDS:
      return {
        ...state,
        mustIncludeAllKeywords: action.payload
      };

    case actionTypes.UPDATE_LFIIDS:
      return { ...state, selectedLFIIds: action.payload };

    case actionTypes.CLEAR_LFIIDS:
      return { ...state, selectedLFIIds: action.payload };

    case actionTypes.SET_FILE_NAME:
      return { ...state, file: action.payload };

    case actionTypes.CLEAR_FILE_NAME:
      return { ...state, file: "", selectedLFIIds: "" };

    default:
      return state;
  }
};

export const LearningContextProvider = ({ children }) => {
  const [state, dispatch] = usePersistedStorage(reducer, initialState);
  const { data } = useCustomGETRequest(getSuggestedKeywords, undefined);
  const { postData: getAggsData } = useCustomPOSTRequest(getAggregateQuery);
  const suggestingKeywords = useMemo(() => (isArray(data) ? data : []), [data]);
  const [countryData, setCountryData] = useState([]);
  const query = useQueryString();
  const [isReady, setIsReady] = useState();

  const getAggregatedData = useCallback(
    async (aggregateName, fieldName) => {
      const response = await getAggsData(aggregateName, fieldName);
      return response.buckets
        ? response.buckets.reduce(
            (acc, item) => ({ ...acc, [item.key]: item.doc_count }),
            {}
          )
        : {};
    },
    [getAggsData]
  );

  useEffect(() => {
    getAggregatedData("countries", "country").then(setCountryData);
  }, [getAggregatedData]);

  const filterOperations = useMemo(
    () => ({
      onSelectAllActivities: () => {
        dispatch({ type: actionTypes.SELECT_ALL_ACTIVITY });
      },
      updateActivity: payload => {
        dispatch({ type: actionTypes.ACTIVITY_UPDATED, payload });
      },
      updateKeywords: payload => {
        dispatch({ type: actionTypes.KEYWORDS_UPDATED, payload });
      },
      updateDataSources: payload => {
        dispatch({
          type: actionTypes.LFI_SOURCES_UPDATED,
          payload
        });
      },
      updateDateRange: payload => {
        dispatch({ type: actionTypes.DATE_RANGE_UPDATED, payload });
      },
      removeActivity: payload => {
        dispatch({ type: actionTypes.REMOVE_ACTIVITY, payload });
      },
      removeKeyword: payload => {
        dispatch({ type: actionTypes.REMOVE_KEYWORD, payload });
      },
      addActivity: payload => {
        dispatch({ type: actionTypes.ADD_ACTIVITY, payload });
      },
      resetFilters: () => {
        dispatch({ type: actionTypes.RESET_FILTERS });
      },
      partiallyResetFilters: () => {
        dispatch({ type: actionTypes.PARTIALLY_RESET_FILTERS });
      },
      setFilterContext: payload => {
        const { ...filterContextData } = payload;
        dispatch({
          type: actionTypes.SET_FILTER_CONTEXT,
          payload: filterContextData
        });
      },
      setSynonymsEnabled: payload => {
        dispatch({
          type: actionTypes.SET_SYNONYMS_ENABLED,
          payload
        });
      },
      setMustIncludeAllKeywords: payload => {
        dispatch({
          type: actionTypes.SET_MUST_INCLUDE_ALL_KEYWORDS,
          payload
        });
      },
      setCountries: payload => {
        dispatch({ type: actionTypes.SET_COUNTRIES, payload });
      },
      toggleCountries: payload => {
        dispatch({ type: actionTypes.TOGGLE_COUNTRIES, payload });
      },
      clearCountries: () => {
        dispatch({ type: actionTypes.SET_COUNTRIES, payload: [] });
      },
      resetKeywordsFilters: () => {
        dispatch({ type: actionTypes.RESET_KEYWORDS_FILTERS });
      },
      updateLFIIds: payload => {
        dispatch({ type: actionTypes.UPDATE_LFIIDS, payload });
      },
      clearLFIIds: () => {
        dispatch({ type: actionTypes.CLEAR_LFIIDS, payload: [] });
      },
      setFileName: payload => {
        dispatch({ type: actionTypes.SET_FILE_NAME, payload });
      },
      clearFileName: () => {
        dispatch({ type: actionTypes.CLEAR_FILE_NAME, payload: "" });
      }
    }),
    [dispatch]
  );

  const storageValues = useMemo(
    () => JSON.parse(localStorage.getItem(storageKey)),
    []
  );

  const getDefaultValue = useCallback(
    (params, paramName) =>
      isEmpty(params)
        ? storageValues?.[`${paramName}`] || resetState[`${paramName}`]
        : resetState[`${paramName}`],
    [storageValues]
  );

  const setParameters = useCallback(() => {
    const params = normalizeSeachParams(query.toString());

    filterOperations.updateActivity(
      params?.filters?.selectedActivities
        ? toArray(params.filters.selectedActivities)
        : getDefaultValue(params, "selectedActivities")
    );

    filterOperations.updateDateRange(
      params?.filters?.selectedDateRange?.length === 2
        ? params.filters.selectedDateRange.map(d => Number(d))
        : getDefaultValue(params, "selectedDateRange")
    );

    filterOperations.updateKeywords(
      params?.filters?.selectedKeywords
        ? toArray(params.filters.selectedKeywords)
        : getDefaultValue(params, "selectedKeywords")
    );

    filterOperations.setSynonymsEnabled(
      params?.filters?.synonymsEnabled !== undefined
        ? toBoolean(params.filters.synonymsEnabled)
        : getDefaultValue(params, "synonymsEnabled")
    );

    filterOperations.setMustIncludeAllKeywords(
      params?.filters?.mustIncludeAllKeywords !== undefined
        ? toBoolean(params.filters.mustIncludeAllKeywords)
        : getDefaultValue(params, "mustIncludeAllKeywords")
    );

    filterOperations.updateDataSources(
      params?.filters?.selectedDataSources
        ? toArray(params.filters.selectedDataSources)
        : getDefaultValue(params, "selectedDataSources")
    );

    filterOperations.setCountries(
      params?.filters?.selectedCountries
        ? toArray(params.filters.selectedCountries)
        : getDefaultValue(params, "selectedCountries")
    );

    setIsReady(true);

    if (!isEmpty(params)) {
      window.history.replaceState("", "Dashboard", "/dashboard");
    }
  }, [query, filterOperations, getDefaultValue]);

  useEffect(() => {
    if (isReady) {
      return;
    }

    setParameters();
  }, [isReady, query, setParameters]);

  const value = useMemo(
    () => ({
      filterContext: isReady
        ? { ...initialState, ...state }
        : { ...initialState },
      isFiltering: isReady
        ? !isEqual({ ...initialState }, { ...state })
        : false,
      availableSuggestions: {
        ...availableSuggestions,
        countries: countryData
      },
      suggestingKeywords,
      filterOperations,
      isReady
    }),
    [state, isReady, suggestingKeywords, filterOperations, countryData]
  );
  return (
    <LearningContext.Provider value={value}>
      {children}
    </LearningContext.Provider>
  );
};

LearningContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LearningContext;

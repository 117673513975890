module.exports = {
  "@shellYellow": "#f7d117",
  "@shellRed": "#d42e12",
  "@shellDarkBlue": "#003882",
  "@shellMidBlue": "#0099BA",
  "@shellDarkGrey": "#595959",
  "@shellVeryDarkGrey": "#404040",
  "@elevation-1":
    "0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 5px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
  "@elevation-2": "0 4px 6px rgba(0, 0, 0, 0.28)",
  "@elevation-3": "0 8px 8px rgba(0, 0, 0, 0.28)",
  "@elevation-4": "0 2px 10px 0 rgba(0, 0, 0, 0.5)",
  "@elevation-5": "0 1px 14px rgba(0, 0, 0, 0.18)",
  "@elevation-6": "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  "@colorPrimary": "#0097bb",
  "@colorErrorBg": "#d42e12",
  "@colorTextBase": "#595959",
  "@colorBorder": "#d9d9d9",
  "@colorBorderSecondary": "#f0f0f0",
  "@borderRadius": "4px",
  "@borderRadiusLG": "8px",
  "@borderRadiusSM": "2px",
  "@standardColorBg": "#ffffff"
};

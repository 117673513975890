import { theme } from "antd";
import lightVars from "./lightVars";

const { defaultAlgorithm } = theme;

export const themeToken = {
  algorithm: defaultAlgorithm,
  token: {
    colorPrimary: lightVars["@shellMidBlue"],
    colorTextBase: lightVars["@shellVeryDarkGrey"],
    colorError: lightVars["@shellRed"],

    fontFamily:
      '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Droid Sans", "Helvetica Neue", sans- serif'
  },
  components: {
    Button: {
      colorTextLightSolid: lightVars["@shellVeryDarkGrey"],
      colorPrimary: lightVars["@shellYellow"],
      colorPrimaryActive: "#d1a908",
      colorPrimaryBorder: "#fff891",
      colorPrimaryHover: lightVars["@shellYellow"],
      colorText: lightVars["@shellDarkGrey"]
    },
    Spin: {
      colorPrimary: lightVars["@shellYellow"]
    },
    Menu: {
      colorItemTextHoverHorizontal: lightVars["@shellYellow"],
      colorItemTextSelected: lightVars["@shellDarkGrey"],
      colorPrimary: "#1677ff",
      controlItemBgActive: "#e6f4ff",
      colorItemTextHover: lightVars["@shellDarkGrey"],
      colorItemText: lightVars["@shellDarkGrey"],
      colorItemTextSelectedHorizontal: lightVars["@shellYellow"],
      colorItemBgSelected: lightVars["@shellYellow"]
    }
  }
};

export default themeToken;

import { Badge } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import "./index.less";

const ColorBadge = ({ size, color, text, ...rest }) => (
  <Badge
    color={color}
    className={classNames("color-badge", {
      "badge-sm": size === "small",
      "badge-lg": size === "large",
      "badge-xl": size === "xl"
    })}
    text={text}
    {...rest}
  />
);

ColorBadge.propTypes = {
  size: PropTypes.oneOf(["", "default", "small", "large", "xl"]),
  color: PropTypes.string,
  text: PropTypes.string
};

ColorBadge.defaultProps = {
  size: "default",
  color: "grey",
  text: ""
};

export default ColorBadge;

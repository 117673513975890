import { Area } from "@ant-design/plots";
import { startCase } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import tinyColor from "tinycolor2";
import ComponentLoader from "../../../components/ComponentLoader";
import CustomCard from "../../../components/CustomCard";
import TenantContext from "../../../context/TenantContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  analyticsEndpoint,
  useApi
} from "../../API";
import OvalSegmented from "../components/OvalSegmented";
import DashboardContext from "./Context";
import { createFilters } from "./utils";

const reportTypes = {
  byAsset: "trends-by-assets",
  byTopics: "trends-by-topics"
};

const TrendVisualizer = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();

  const { request: getAnalytics, loading: loadingAnalytics } = useApi(
    analyticsEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const { activeTenantTopics, activeTenantAssets } = useContext(TenantContext);

  const {
    filterContext: { topics, dataProviders, assets, trendsVisualizerState },
    filterOperations: { setTrendsVisualizerState },
    isReady
  } = useContext(DashboardContext);

  const fetchData = useCallback(async () => {
    const payload = {
      requests: [
        {
          type: reportTypes[trendsVisualizerState],
          ...createFilters({
            topics,
            dataProviders,
            assets
          })
        }
      ]
    };

    const response = await getAnalytics({ data: payload });

    if (response?.result?.[0]?.errors) {
      setError(true);
      return;
    }

    setData(response?.result?.[0]?.results?.data);
  }, [trendsVisualizerState, topics, dataProviders, assets, getAnalytics]);

  useEffect(() => {
    const asyncFetchData = async () => {
      await fetchData();
    };

    if (isReady) {
      asyncFetchData();
    }
  }, [fetchData, isReady]);

  const lookupValue = useCallback(
    value => {
      if (trendsVisualizerState === "byAsset") {
        return value;
      }

      const findItem = activeTenantTopics.find(i => i.key === value);
      return startCase(findItem?.name || "");
    },
    [activeTenantTopics, trendsVisualizerState]
  );

  const GraphComponent = useMemo(
    () => (
      <Area
        {...{
          data: data?.filter(d => d?.series),
          appendPadding: 5,
          renderer: "svg",
          autoFit: true,
          isStack: true,
          startOnZero: false,
          xField: "xAxis",
          yField: "yAxis",
          seriesField: "series",
          // slider: {
          //   start: 0.1,
          //   end: 0.5
          // },
          legend: {
            position: "right-top",
            offsetX: 0,
            itemName: {
              formatter: (text, _) => startCase(text)
            }
          },
          smooth: true,
          animation: {
            appear: {
              animation: "path-in",
              duration: 500
            }
          },
          color: ({ series }) => {
            const findItem =
              trendsVisualizerState === "byTopics"
                ? activeTenantTopics.find(i => i.key === series)
                : activeTenantAssets.find(i => i.key === series);
            return findItem?.color || tinyColor.random().toHexString();
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: true,
              autoEllipsis: true,
              formatter: datum =>
                [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December"
                ][datum || 0]
            }
          },
          meta: {
            series: {
              formatter: value => lookupValue(value)
            }
          }
        }}
        style={{ height: "100%" }}
      />
    ),
    [data, activeTenantTopics, lookupValue, trendsVisualizerState]
  );

  return (
    <CustomCard
      header={{
        title: "Trends",
        description: `Trends over time per ${
          trendsVisualizerState === "byAsset" ? "Assets" : "Topics"
        }`,
        extra: (
          <OvalSegmented
            options={[
              { value: "byTopics", label: "By Topics" },
              { value: "byAsset", label: "By Assets" }
            ]}
            onChange={value => setTrendsVisualizerState(value)}
            value={trendsVisualizerState}
          />
        )
      }}
      className="trends-visualizer"
    >
      <ComponentLoader
        isLoading={loadingAnalytics || !data}
        hasErrors={error}
        errorComponent="Unable to retrieve the data at this time"
        hasNoData={data != null && !data?.length}
        noDataComponent="No trends to show"
      >
        {GraphComponent}
      </ComponentLoader>
    </CustomCard>
  );
};

export default TrendVisualizer;

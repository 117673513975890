import { notification } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

const REQUEST_ERROR = "Unable to complete request";

export const useCustomGETRequest = (
  axiosGETRequest,
  id,
  shouldAutomaticFetch = true
) => {
  // const { getToken } = useAuth();
  const isSubscribedRef = useRef(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({});

  const refetch = useCallback(
    async config => {
      setLoading(true);
      setError("");
      try {
        // const accessToken = getToken();
        // setAuthHeaders(accessToken);
        const response = await axiosGETRequest(id, config);
        if (isSubscribedRef.current) {
          setData(response.data);
          setLoading(false);
        }
        return response.data;
      } catch (err) {
        if (err?.response?.status === 401) {
          window.location.reload();
        }
        if (isSubscribedRef.current) {
          setError(err.message || REQUEST_ERROR);
          setLoading(false);
          setData({});
        }
      }
      return { result: [] };
    },
    [axiosGETRequest, id]
  );

  useEffect(() => {
    if (shouldAutomaticFetch) {
      refetch().then();
    }
  }, [refetch, shouldAutomaticFetch]);

  useEffect(
    () => () => {
      isSubscribedRef.current = false;
    },
    []
  );
  return {
    loading,
    error,
    data,
    refetch
  };
};

export const useCustomPOSTRequest = (
  axiosPOSTRequest,
  getSuccessNotificationConfig = null,
  getErrorNotificationConfig = null
) => {
  // const { getToken } = useAuth();
  const isSubscribedRef = useRef(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(
    () => () => {
      isSubscribedRef.current = false;
    },
    []
  );
  const postData = useCallback(
    async (...args) => {
      setLoading(true);
      try {
        // const accessToken = getToken();
        // setAuthHeaders(accessToken);
        const response = await axiosPOSTRequest(...args);
        if (getSuccessNotificationConfig) {
          notification.success(getSuccessNotificationConfig(response));
        }
        if (isSubscribedRef.current) {
          setLoading(false);
        }
        return response.data || response;
      } catch (err) {
        if (err?.response?.status === 401) {
          window.location.reload();
        }
        if (getErrorNotificationConfig) {
          notification.error(getErrorNotificationConfig(err));
        }
        if (isSubscribedRef.current) {
          setLoading(false);
          setError(err.message);
        }
        throw new Error(
          err?.response?.data?.message || err?.message || REQUEST_ERROR
        );
      }
    },
    [axiosPOSTRequest, getSuccessNotificationConfig, getErrorNotificationConfig]
  );

  return { postData, loading, error };
};

export const useCustomPUTRequest = (
  axiosPUTRequest,
  getSuccessNotificationConfig = null,
  getErrorNotificationConfig = null
) => {
  // const { getToken } = useAuth();
  const isSubscribedRef = useRef(true);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => () => {
      isSubscribedRef.current = false;
    },
    []
  );
  const putData = useCallback(
    async (...args) => {
      setLoading(true);
      try {
        // const accessToken = getToken();
        // setAuthHeaders(accessToken);
        const response = await axiosPUTRequest(...args);
        if (getSuccessNotificationConfig) {
          notification.success(getSuccessNotificationConfig(response));
        }

        if (isSubscribedRef.current) {
          setLoading(false);
        }
        return response.data;
      } catch (err) {
        if (getErrorNotificationConfig) {
          notification.error(getErrorNotificationConfig(err));
        }
        if (isSubscribedRef.current) {
          setLoading(false);
        }
        throw new Error(
          err?.response?.data?.message || err?.message || REQUEST_ERROR
        );
      }
    },
    [axiosPUTRequest, getSuccessNotificationConfig, getErrorNotificationConfig]
  );

  return { putData, loading };
};

export const useCustomDELETERequest = axiosDELETERequest => {
  // const { getToken } = useAuth();

  const deleteData = useCallback(
    async id => {
      try {
        // const accessToken = getToken();
        // setAuthHeaders(accessToken);
        const response = await axiosDELETERequest(id);

        return response.data;
      } catch (err) {
        throw new Error(err.message || REQUEST_ERROR);
      }
    },
    [axiosDELETERequest]
  );

  return { deleteData };
};

export default {
  useCustomPUTRequest,
  useCustomPOSTRequest,
  useCustomGETRequest,
  useCustomDELETERequest
};

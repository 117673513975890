import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { formatDate } from "@salvus/shared/utils";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Tag,
  Timeline,
  Typography
} from "antd";
import { get, isEmpty, omit, set } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CustomCard from "../../../components/CustomCard";
import CustomDrawer from "../../../components/CustomDrawer";
import CustomSelect from "../../../components/CustomSelect";
import {
  ACTIVITY_ACTIONS,
  ACTIVITY_STATUS
} from "../../../constants/investigation";
import { useAuth } from "../../../context/AuthContext";
import LayoutContext from "../../../context/LayoutContext";
import TenantContext from "../../../context/TenantContext";
import { lookupValue } from "../../../utils/index";
import {
  DefaultRequestConfig,
  RequestMethodType,
  dataActivityEndpoint,
  dataEndpoint,
  useApi
} from "../../API";
import DiscoveriesContext from "./Context";

const { TextArea } = Input;

const ActionForm = ({ data, index, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const { validateFields, resetFields, setFieldsValue } = form;

  useEffect(() => {
    if (form && data) {
      setFieldsValue(data);
    }
  }, [data, form, setFieldsValue]);

  const handleCleanup = async () => {
    resetFields();
    setFieldsValue({});
    setIsDirty(false);
    setSaving(false);
  };

  const handleOnCancel = async () => {
    handleCleanup();
    onCancel();
  };

  const handleOnSave = async () => {
    setSaving(true);

    const formData = await validateFields();
    const payload = {
      ...omit(data, ["status", "action", "comment"]),
      ...formData
    };

    await onSave(payload, index);
    handleCleanup();
  };

  return !data ? null : (
    <Form
      key="actionForm"
      form={form}
      className="form-slim"
      layout="vertical"
      onFinish={handleOnSave}
    >
      <Form.Item
        key="status"
        name="status"
        rules={[
          {
            required: true,
            message: "Please select a status"
          }
        ]}
        label="What's this issue's new status?"
      >
        <Select
          placeholder="Select status..."
          onChange={() => setIsDirty(true)}
          options={ACTIVITY_STATUS}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item
        key="action"
        name="action"
        rules={[{ required: false }]}
        label="What action was taken?"
      >
        <Select
          placeholder="Select action..."
          onChange={() => setIsDirty(true)}
          allowClear={false}
          options={ACTIVITY_ACTIONS}
        />
      </Form.Item>
      <Form.Item
        key="comment"
        name="comment"
        rules={[{ required: false }]}
        label="Comment"
      >
        <TextArea
          rows={4}
          style={{ height: 120, resize: "none" }}
          onChange={() => setIsDirty(true)}
        />
      </Form.Item>
      <div style={{ display: "flex" }}>
        <Button key="cancel" onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          loading={saving}
          disabled={saving || !isDirty}
          style={{ marginLeft: "8px" }}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

ActionForm.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

ActionForm.defaultProps = {
  data: null,
  index: 0,
  onCancel: () => {},
  onSave: () => {}
};

const Activity = ({ open, id, onClose }) => {
  const { notify, notificationTypes } = useContext(LayoutContext);
  const [data, setData] = useState();
  const [topicKeys, setTopicKeys] = useState([]);
  const [selectedTopicKey, setSelectedTopicKey] = useState(undefined);
  const [timelineData, setTimelineData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const { request: getData } = useApi(
    dataEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const { request: putActivityData } = useApi(
    dataActivityEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const { currentUser } = useAuth();
  const { activeTenantTopics } = useContext(TenantContext);

  const {
    filterContext: { topics }
  } = useContext(DiscoveriesContext);

  const getTimelineColor = status => {
    const found = ACTIVITY_STATUS.find(stat => stat.value === status);
    return found?.color || "gray";
  };

  const fetchData = useCallback(async () => {
    const response = await getData({ url: `${dataEndpoint}/${id}` });
    setData(response.result);
  }, [getData, id]);

  const initializeTopicKeys = useCallback(async () => {
    const generatedTopicKeys = data?._source?.autoGenerated?.topics || [];
    // Get the Value and Labels
    const filteredTopicKeys = [];
    generatedTopicKeys?.forEach(item => {
      const found = activeTenantTopics.find(key => key.key === item);
      if (found) {
        filteredTopicKeys.push({
          value: found.key,
          label: found.name
        });
      }
    });

    setTopicKeys(filteredTopicKeys);

    if (filteredTopicKeys.length === 1) {
      setSelectedTopicKey(filteredTopicKeys[0].value);
      return;
    }

    const selectedFound = filteredTopicKeys.find(
      item => item.value === selectedTopicKey || item.value === topics[0]
    );

    if (selectedFound) {
      setSelectedTopicKey(selectedFound.value);
    }
  }, [data, selectedTopicKey, activeTenantTopics, topics]);

  useEffect(() => {
    if (!open || !id) {
      return;
    }

    fetchData();
  }, [id, open, fetchData]);

  useEffect(() => {
    if (!data) {
      return;
    }

    initializeTopicKeys();
  }, [data, initializeTopicKeys]);

  const handleRemoveEntry = async index => {
    //
    const filteredActivity = data._source?.activity?.[
      `${selectedTopicKey}`
    ].filter((i, idx) => idx !== index - 1);

    const newPayload = {
      activity: set(
        data._source?.activity,
        [`${selectedTopicKey}`],
        filteredActivity
      )
    };

    await putActivityData({
      url: `${dataActivityEndpoint}/${id}`,
      data: newPayload
    });

    notify(notificationTypes.success, {
      message: "Activity was Removed",
      description: "You have successfully removed this Activity"
    });

    await fetchData();
  };

  const handleSaveEntry = async (payload, index) => {
    //
    setEditIndex();

    const newPayload = get(data._source, "activity", {});
    const customPayload = {
      activity: set(newPayload, `${selectedTopicKey}[${index - 1}]`, payload)
    };
    await putActivityData({
      url: `${dataActivityEndpoint}/${id}`,
      data: customPayload
    });

    notify(notificationTypes.success, {
      message: "Activity was Saved",
      description: "You have successfully saved this Activity"
    });

    await fetchData();
  };

  const handleCancelEntry = () => {
    setEditIndex();
  };

  const handleCleanup = () => {
    setData();
    setEditIndex();
    setTopicKeys([]);
    setSelectedTopicKey("");
    setTimelineData([]);
  };

  const handleOnClose = async () => {
    handleCleanup();
    onClose();
  };

  const renderLabel = dataItem => {
    const { createdOn, author } = dataItem;

    if (!createdOn) {
      return null;
    }

    return (
      <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
        {formatDate(createdOn, "MM/DD/YYYY h:mma")}
        <br />
        {author}
      </Typography.Text>
    );
  };

  const renderEntry = (dataItem, index) => {
    if (isEmpty(dataItem)) {
      return <Button onClick={() => setEditIndex(index)}>Add Entry</Button>;
    }

    if (index === editIndex) {
      return (
        <ActionForm
          data={dataItem}
          index={index}
          onCancel={handleCancelEntry}
          onSave={handleSaveEntry}
        />
      );
    }

    const activityStatus = lookupValue(
      ACTIVITY_STATUS,
      item => item.value === dataItem.status
    );

    const activityAction = lookupValue(
      ACTIVITY_ACTIONS,
      item => item.value === dataItem.action
    );

    return (
      <CustomCard
        className="show-arrow"
        {...(!editIndex && !dataItem.readOnly
          ? {
              footer: {
                actions: [
                  <Button
                    key={`${index}-edit`}
                    type="link"
                    onClick={() => setEditIndex(index)}
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Button>,
                  <Popconfirm
                    key={`${index}-comment`}
                    title="Remove this Comment?"
                    onConfirm={async () => handleRemoveEntry(index)}
                  >
                    <Button type="link" danger icon={<DeleteOutlined />}>
                      Remove
                    </Button>
                  </Popconfirm>
                ]
              }
            }
          : null)}
      >
        <div>
          {activityStatus && (
            <div>
              <Tag
                color={getTimelineColor(dataItem.status)}
                key={`${index}-tag`}
              >
                {lookupValue(
                  ACTIVITY_STATUS,
                  item => item.value === dataItem.status
                )}
              </Tag>
            </div>
          )}
          {activityAction && (
            <div>
              <Typography.Text type="secondary">
                <strong>{activityAction}</strong>
              </Typography.Text>
            </div>
          )}
          <div>
            <Typography.Text type="secondary">
              {dataItem.comment}
            </Typography.Text>
          </div>
        </div>
      </CustomCard>
    );
  };

  const mapTimelineData = () => {
    const items = [];
    if (!selectedTopicKey) {
      setTimelineData(items);
      return;
    }

    // Let's setup the default one
    items.push({
      createdOn: data._source.createdOn,
      createdBy: "",
      author: "",
      status: "",
      action: "",
      comment: `Insights were discovered in ${data._source.metadata.name}`,
      readOnly: true
    });

    // Prepare the timeline data
    // based on the selection
    data._source?.activity?.[`${selectedTopicKey}`]?.forEach(item => {
      items.push({
        ...item
      });
    });

    if (!editIndex) {
      items.push({});
    }

    if (editIndex && editIndex === items.length) {
      items.push({
        createdOn: new Date().valueOf(),
        createdBy: currentUser.uid,
        author: `${currentUser.displayName}`,
        status: "",
        action: "",
        comment: ""
      });
    }

    const newTimelineData = items.map((item, index) => ({
      label: renderLabel(item),
      color: getTimelineColor(item.status),
      children: renderEntry(item, index)
    }));

    setTimelineData(newTimelineData);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setEditIndex();
    mapTimelineData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopicKey, data]);

  useEffect(() => {
    mapTimelineData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editIndex]);

  return !data || !open ? null : (
    <CustomDrawer
      open={open}
      title={`${data._source.metadata.name} #${data._id}`}
      onClose={handleOnClose}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto"
        }}
      >
        <div style={{ marginBottom: "24px", marginLeft: "12px" }}>
          <CustomSelect
            mode=""
            style={{ width: "300px" }}
            label="Topics"
            direction="horizontal"
            value={selectedTopicKey || undefined}
            placeholder="Select Topic..."
            onChange={setSelectedTopicKey}
            options={topicKeys}
          />
        </div>
        <div style={{ overflowX: "hidden", overflowY: "auto" }}>
          <Timeline
            className="activity-timeline"
            mode="left"
            items={timelineData}
            style={{ marginTop: "10px" }}
          />
        </div>
      </div>
    </CustomDrawer>
  );
};

Activity.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  onClose: PropTypes.func
};

Activity.defaultProps = {
  open: false,
  id: null,
  onClose: () => {}
};

export default Activity;

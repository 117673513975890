import React from "react";
import PageLayout from "../../layouts/PageLayout";
import Content from "../../modules/System/Profile/Content";

import "./index.less";

const ProfilePage = () => (
  <PageLayout
    className="profile-page"
    header={{
      title: "Profile",
      description: "Manage your information below."
    }}
  >
    <Content />
  </PageLayout>
);

export default ProfilePage;

import { get, intersection, isEmpty, uniq } from "lodash-es";

const GlobalAdminSystemRoles = [
  "GlobalAdmin", // Same as Admin, plus User Management
  "Admin" // Role with all possible privileges except for User Management
];

const TenantRoles = [
  "TenantAdmin",
  "DashboardAdmin",
  "DashboardReader",
  "DiscoveriesAdmin",
  "DiscoveriesCollaborator",
  "DiscoveriesReader",
  "TenantManagementAdmin",
  "TenantManagementReader",
  "UserManagementAdmin",
  "UserManagementReader",
  "SubscriptionManagementAdmin",
  "SubscriptionManagementReader",
  "TopicManagementAdmin",
  "TopicManagementReader",
  "LogManagementAdmin",
  "LogManagementReader"
];

const AdminRoles = {
  dashboard: ["GlobalAdmin", "Admin", "TenantAdmin", "DashboardAdmin"],
  discoveries: ["GlobalAdmin", "Admin", "TenantAdmin", "DiscoveriesAdmin"],
  discoveriesCollaborator: ["DiscoveriesCollaborator"],
  tenants: ["GlobalAdmin", "Admin", "TenantAdmin", "TenantManagementAdmin"],
  users: ["GlobalAdmin", "TenantAdmin", "UserManagementAdmin"],
  subscriptions: [
    "GlobalAdmin",
    "Admin",
    "TenantAdmin",
    "SubscriptionManagementAdmin"
  ],
  topics: ["GlobalAdmin", "Admin", "TenantAdmin", "TopicManagementAdmin"],
  logs: ["GlobalAdmin", "Admin", "TenantAdmin", "LogManagementAdmin"]
};

const ReaderRoles = {
  dashboard: ["DashboardReader"],
  discoveries: ["DiscoveriesReader"],
  tenants: ["TenantManagementReader"],
  users: ["UserManagementReader"],
  subscriptions: ["SubscriptionManagementReader"],
  topics: ["TopicManagementReader"],
  logs: ["LogManagementReader"]
};

const userTenants = claims => claims.tenants || [];
const userSystemRoles = claims => claims.systemRoles || [];
const userTenantRoles = (claims, tenantKey) => {
  if (tenantKey && !isEmpty(claims?.tenantRoles)) {
    return get(claims.tenantRoles, tenantKey, []) || [];
  }
  return [];
};

const userActiveRoles = (claims, tenantKey) => {
  const systemRoles = userSystemRoles(claims);
  const tenantRoles = userTenantRoles(claims, tenantKey);
  return uniq([...systemRoles, ...tenantRoles]);
};

const hasGlobalAdminRole = claims =>
  intersection(GlobalAdminSystemRoles, userSystemRoles(claims) || []).length >
  0;

const hasSecurityRoles = (claims, tenantKey, securityRoles = []) => {
  // No roles required
  if (!securityRoles?.length) {
    return true;
  }

  // Get all "Active" Roles
  const roles = userActiveRoles(claims, tenantKey);

  // Roles are required but user has none
  if (!roles?.length) {
    return false;
  }

  return intersection(roles, securityRoles || []).length;
};

const hasActiveGlobalAdminRole = claims =>
  intersection(GlobalAdminSystemRoles, userSystemRoles(claims)).length > 0;

const hasActiveTenantRole = (claims, tenantKey) =>
  intersection(TenantRoles, userTenantRoles(claims, tenantKey)).length > 0;

export {
  AdminRoles,
  GlobalAdminSystemRoles,
  ReaderRoles,
  TenantRoles,
  hasActiveGlobalAdminRole,
  hasActiveTenantRole,
  hasGlobalAdminRole,
  hasSecurityRoles,
  userActiveRoles,
  userSystemRoles,
  userTenantRoles,
  userTenants
};

// import { hasGlobalAdminRole } from "@salvus/shared/modules/authorization";
// import { isEmpty } from "lodash";
// import PropTypes from "prop-types";
// import React, { useCallback, useEffect, useMemo, useState } from "react";
// import useLocalStorageState from "use-local-storage-state";
// import {
//   ActiveTenantKeyStorageKey,
//   GlobalTenantConfig
// } from "../constants/common";
// import {
//   DefaultRequestConfig,
//   RequestMethodType,
//   tenantProfileEndpoint,
//   tenantsEndpoint,
//   useApi
// } from "../modules/API";
// import { AuthMessage } from "../modules/Auth/components";
// import { useAuth } from "./AuthContext";

// const TenantContext = React.createContext();

// export const TenantContextProvider = ({ children }) => {
//   const { defaultTenantKey, currentUser } = useAuth();
//   const [
//     activeTenantKey,
//     setActiveTenantKey,
//     { removeItem: removeActiveTenantKeyFromLocalStorage }
//   ] = useLocalStorageState(ActiveTenantKeyStorageKey);
//   const [activeTenantProfile, setActiveTenantProfile] = useState(null);
//   const [changingTenant, setIsChangingTenant] = useState(false);

//   const requestConfig = useMemo(
//     () => ({ params: { isOnboarded: true, status: "active" } }),
//     []
//   );

//   const hasGlobalAccess = useMemo(
//     () => hasGlobalAdminRole(currentUser),
//     [currentUser]
//   );

//   const {
//     data: tenantsData,
//     error: errorGettingTenants,
//     loading: loadingTenants,
//     request: getTenants
//   } = useApi(tenantsEndpoint, RequestMethodType.GET, requestConfig, true);

//   const { error: errorGettingTenantProfile, request: getTenantProfile } =
//     useApi(
//       tenantProfileEndpoint,
//       RequestMethodType.GET,
//       DefaultRequestConfig,
//       false
//     );

//   const changeTenant = useCallback(
//     value => {
//       if (!value) {
//         removeActiveTenantKeyFromLocalStorage();
//         return;
//       }

//       setActiveTenantKey(value);
//       setIsChangingTenant(true);
//       window.location.reload();
//     },
//     [removeActiveTenantKeyFromLocalStorage, setActiveTenantKey]
//   );

//   const fetchData = useCallback(data => {
//     if (isEmpty(data?.result)) {
//       return [];
//     }

//     const tenantsD = data.result.hits.hits.map(t => ({
//       key: t._source.key,
//       name: t._source.name,
//       description: t._source.description
//     }));

//     return [...tenantsD, GlobalTenantConfig];
//   }, []);

//   const tenants = useMemo(
//     () => fetchData(tenantsData),
//     [fetchData, tenantsData]
//   );

//   useEffect(() => {
//     if (!tenants?.length) {
//       return;
//     }

//     if (
//       activeTenantKey &&
//       tenants.filter(i => i.key === activeTenantKey).length < 1
//     ) {
//       removeActiveTenantKeyFromLocalStorage();
//       return;
//     }

//     // If there is an activeTenantKey but user does not have access, clean it up
//     if (activeTenantKey && !hasGlobalAccess) {
//       if (!currentUser.tenants.includes(activeTenantKey)) {
//         removeActiveTenantKeyFromLocalStorage();
//         return;
//       }
//     }

//     if (
//       !activeTenantKey &&
//       defaultTenantKey &&
//       tenants.filter(i => i.key === defaultTenantKey).length
//     ) {
//       setActiveTenantKey(defaultTenantKey);
//       return;
//     }

//     if (!activeTenantKey && hasGlobalAccess) {
//       setActiveTenantKey(GlobalTenantConfig.key);
//     }
//   }, [
//     activeTenantKey,
//     defaultTenantKey,
//     currentUser,
//     hasGlobalAccess,
//     setActiveTenantKey,
//     changeTenant,
//     removeActiveTenantKeyFromLocalStorage,
//     tenants
//   ]);

//   /**
//    * This sets the active tenant key and pulls the
//    * tenant profile to be exposed through context
//    */
//   const loadActiveTenantProfile = useCallback(async () => {
//     if (activeTenantKey === GlobalTenantConfig.key || !activeTenantKey) {
//       setActiveTenantProfile(null);
//       return;
//     }

//     // Get the Tenant Profile
//     const tenantProfile = await getTenantProfile({
//       url: `${tenantProfileEndpoint}/${activeTenantKey}`
//     });

//     setActiveTenantProfile(tenantProfile?.result);
//   }, [activeTenantKey, getTenantProfile]);

//   useEffect(() => {
//     if (!activeTenantKey) {
//       return;
//     }

//     loadActiveTenantProfile();
//   }, [activeTenantKey, loadActiveTenantProfile]);

//   const activeTenantTopics = useMemo(
//     () => activeTenantProfile?.topics || [],
//     [activeTenantProfile?.topics]
//   );

//   const activeTenantAssets = useMemo(
//     () => activeTenantProfile?.assets || [],
//     [activeTenantProfile?.assets]
//   );

//   const activeTenantDataSources = useMemo(
//     () => activeTenantProfile?.dataSources || [],
//     [activeTenantProfile?.dataSources]
//   );

//   const value = useMemo(
//     () => ({
//       tenants,
//       loadingTenants,
//       errorGettingTenants,
//       activeTenantKey,
//       activeTenantProfile,
//       changeTenant,
//       getTenants,
//       loadActiveTenantProfile,
//       activeTenantTopics,
//       activeTenantAssets,
//       activeTenantDataSources
//     }),
//     [
//       tenants,
//       loadingTenants,
//       errorGettingTenants,
//       activeTenantKey,
//       activeTenantProfile,
//       changeTenant,
//       getTenants,
//       loadActiveTenantProfile,
//       activeTenantTopics,
//       activeTenantAssets,
//       activeTenantDataSources
//     ]
//   );

//   if (errorGettingTenants) {
//     return (
//       <AuthMessage type="danger" message="Unable to load available Tenants" />
//     );
//   }

//   if (errorGettingTenantProfile) {
//     return (
//       <AuthMessage type="danger" message="Unable to load Tenant Profile" />
//     );
//   }

//   if (!tenants?.length || loadingTenants) {
//     return <AuthMessage message="Loading Tenants..." />;
//   }

//   if (changingTenant) {
//     return <AuthMessage message="Changing Tenants..." />;
//   }

//   return (
//     <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
//   );
// };

// TenantContextProvider.propTypes = {
//   children: PropTypes.node.isRequired
// };

// export default TenantContext;

import { hasGlobalAdminRole } from "@salvus/shared/modules/authorization";
import { normalizeSeachParams } from "@salvus/shared/utils";
import { Button, Typography } from "antd";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import {
  ActiveTenantKeyStorageKey,
  DashboardStorageKey,
  DiscoveriesStorageKey,
  GlobalTenantConfig
} from "../constants/common";
import {
  DefaultRequestConfig,
  RequestMethodType,
  tenantProfileEndpoint,
  tenantsEndpoint,
  useApi
} from "../modules/API";
import { AuthMessage } from "../modules/Auth/components";
import { useQueryString } from "../utils/hooks/queryString";
import { useAuth } from "./AuthContext";

const TenantContext = React.createContext();

export const TenantContextProvider = ({ children }) => {
  const { currentUser } = useAuth();

  const [initialized, setInitialized] = useState(false);
  const [activeTenantKey, setActiveTenantKey] = useState(null);
  const [activeTenantProfile, setActiveTenantProfile] = useState(null);

  const [isChangingTenant, setIsChangingTenant] = useState(false);
  const [unauthorizedAttempt, setUnauthorizedAttempt] = useState(false);
  const query = useQueryString();

  const [
    storageActiveTenantKey,
    setStorageActiveTenantKey,
    { removeItem: removeActiveTenantKeyFromLocalStorage }
  ] = useLocalStorageState(ActiveTenantKeyStorageKey);

  const [, , { removeItem: removeDashboardFromLocalStorage }] =
    useLocalStorageState(DashboardStorageKey);

  const [, , { removeItem: removeDiscoveriesFromLocalStorage }] =
    useLocalStorageState(DiscoveriesStorageKey);

  const requestConfig = useMemo(
    () => ({ params: { isOnboarded: true, status: "active" } }),
    []
  );

  const hasGlobalAccess = useMemo(
    () => hasGlobalAdminRole(currentUser),
    [currentUser]
  );

  const {
    data: tenantsData,
    error: errorGettingTenants,
    loading: loadingTenants,
    request: loadTenants
  } = useApi(tenantsEndpoint, RequestMethodType.GET, requestConfig, true);

  const { error: errorGettingTenantProfile, request: getTenantProfileData } =
    useApi(
      tenantProfileEndpoint,
      RequestMethodType.GET,
      DefaultRequestConfig,
      false
    );

  const getTenantFromParam = useCallback(() => {
    if (query?.size === 0) {
      return "";
    }

    const params = normalizeSeachParams(query.toString());
    if (isEmpty(params)) {
      return "";
    }

    return params?.tenantKey;
  }, [query]);

  /**
   * Tenants are called immediately, extract out and set the tenants
   * based on strategy
   */
  const fetchTenants = useCallback(data => {
    if (isEmpty(data?.result)) {
      return [];
    }

    const response = data.result.hits.hits.map(t => ({
      key: t._source.key,
      name: t._source.name,
      description: t._source.description
    }));

    return [...response, GlobalTenantConfig];
  }, []);

  const tenants = useMemo(
    () => fetchTenants(tenantsData),
    [fetchTenants, tenantsData]
  );

  const fetchTenantProfileData = useCallback(
    async value => {
      if (!value || value === GlobalTenantConfig.key) {
        return null;
      }

      const tenantProfile = await getTenantProfileData({
        url: `${tenantProfileEndpoint}/${value}`
      });

      return tenantProfile?.result;
    },
    [getTenantProfileData]
  );

  // update storage after activeTenantKey is set
  const setActiveTenant = useCallback(
    async value => {
      setActiveTenantKey(value);

      // Set the Storage
      if (value !== storageActiveTenantKey) {
        // TODO: This is a hack, I do not like this here
        // need to fire event and allow both storages to clear
        removeDashboardFromLocalStorage();
        removeDiscoveriesFromLocalStorage();

        if (!value) {
          removeActiveTenantKeyFromLocalStorage();
        } else {
          setStorageActiveTenantKey(value);
        }
      }

      // Set the Profile
      if (!value || value === GlobalTenantConfig.key) {
        setActiveTenantProfile(null);
      } else {
        setActiveTenantProfile(await fetchTenantProfileData(value));
      }
    },
    [
      fetchTenantProfileData,
      removeActiveTenantKeyFromLocalStorage,
      removeDashboardFromLocalStorage,
      removeDiscoveriesFromLocalStorage,
      setStorageActiveTenantKey,
      storageActiveTenantKey
    ]
  );

  //
  const getUserDefaultTenant = useMemo(() => {
    if (currentUser?.tenants?.length) {
      return currentUser.tenants[0];
    }

    return hasGlobalAccess ? GlobalTenantConfig.key : null;
  }, [currentUser, hasGlobalAccess]);

  const blackBox = useCallback(async () => {
    const result = {
      tenantKey: null,
      isUnauthorized: false
    };

    // Deal with tenant being passed via url
    const tenantFromParam = getTenantFromParam();
    if (tenantFromParam) {
      if (
        tenants.filter(i => i.key === tenantFromParam).length &&
        ((tenantFromParam === GlobalTenantConfig.key && hasGlobalAccess) ||
          (tenantFromParam !== GlobalTenantConfig.key &&
            (hasGlobalAccess ||
              currentUser?.tenants?.includes(tenantFromParam))))
      ) {
        result.tenantKey = tenantFromParam;
        return result;
      }
      result.isUnauthorized = true;
    }

    if (storageActiveTenantKey) {
      if (
        tenants.filter(i => i.key === storageActiveTenantKey).length &&
        // (
        //   (storageActiveTenantKey === GlobalTenantConfig.key && hasGlobalAccess)
        //    ||
        //   (storageActiveTenantKey !== GlobalTenantConfig.key && (hasGlobalAccess || currentUser?.tenants?.includes(storageActiveTenantKey)))
        // )
        ((storageActiveTenantKey === GlobalTenantConfig.key &&
          hasGlobalAccess) ||
          (storageActiveTenantKey !== GlobalTenantConfig.key &&
            (hasGlobalAccess ||
              currentUser?.tenants?.includes(storageActiveTenantKey))))
      ) {
        result.tenantKey = storageActiveTenantKey;
        return result;
      }
    }

    const key = getUserDefaultTenant;
    result.tenantKey = key;
    return result;
  }, [
    currentUser,
    getTenantFromParam,
    getUserDefaultTenant,
    hasGlobalAccess,
    storageActiveTenantKey,
    tenants
  ]);

  useEffect(() => {
    const setFromBlackbox = async () => {
      const response = await blackBox();
      setActiveTenant(response.tenantKey);
      setUnauthorizedAttempt(response.isUnauthorized);
      setInitialized(true);
    };

    if (initialized || !tenants?.length) {
      return;
    }

    setFromBlackbox();
  }, [blackBox, initialized, setActiveTenant, tenants]);

  const changeTenant = useCallback(
    async value => {
      setActiveTenant(value);
      setIsChangingTenant(true);
      window.location.reload();
    },
    [setActiveTenant]
  );

  const loadActiveTenantProfile = useCallback(async () => {
    const response = await fetchTenantProfileData(activeTenantKey);
    setActiveTenantProfile(response);
  }, [activeTenantKey, fetchTenantProfileData]);

  const activeTenantTopics = useMemo(
    () => activeTenantProfile?.topics || [],
    [activeTenantProfile?.topics]
  );

  const activeTenantAssets = useMemo(
    () => activeTenantProfile?.assets || [],
    [activeTenantProfile?.assets]
  );

  const activeTenantDataSources = useMemo(
    () => activeTenantProfile?.dataSources || [],
    [activeTenantProfile?.dataSources]
  );

  const value = useMemo(
    () => ({
      tenants,
      loadingTenants,
      errorGettingTenants,
      activeTenantKey,
      activeTenantProfile,
      changeTenant,
      loadTenants,
      loadActiveTenantProfile,
      activeTenantTopics,
      activeTenantAssets,
      activeTenantDataSources
    }),
    [
      tenants,
      loadingTenants,
      errorGettingTenants,
      activeTenantKey,
      activeTenantProfile,
      changeTenant,
      loadTenants,
      loadActiveTenantProfile,
      activeTenantTopics,
      activeTenantAssets,
      activeTenantDataSources
    ]
  );

  const renderUnauthorizedAttempt = useCallback(() => {
    const handleOnClick = () => {
      window.history.replaceState("", "", window.location.pathname);
      setUnauthorizedAttempt(false);
    };

    return (
      <div className="cta-wrapper">
        <div className="cta-container">
          <Typography.Title
            level={1}
            type="danger"
            style={{ margin: "0", textAlign: "center" }}
          >
            Oops!
          </Typography.Title>
          <Typography.Title
            level={3}
            type="danger"
            style={{ margin: "0", textAlign: "center" }}
          >
            You do not have access to this Tenant
          </Typography.Title>
          <Typography.Text style={{ marginTop: "15px", textAlign: "center" }}>
            Press `OK, I understand` button to continue to Salvus.
          </Typography.Text>
          <Button
            type="primary"
            style={{ marginTop: "15px" }}
            onClick={handleOnClick}
          >
            OK, I understand
          </Button>
        </div>
      </div>
    );
  }, []);

  if (errorGettingTenants) {
    return (
      <AuthMessage type="danger" message="Unable to load available Tenants" />
    );
  }

  if (errorGettingTenantProfile) {
    return (
      <AuthMessage type="danger" message="Unable to load Tenant Profile" />
    );
  }

  if (!tenants?.length || loadingTenants || !initialized) {
    return <AuthMessage message="Loading Tenants..." />;
  }

  if (isChangingTenant) {
    return <AuthMessage message="Changing Tenants..." />;
  }

  return (
    <TenantContext.Provider value={value}>
      {unauthorizedAttempt ? renderUnauthorizedAttempt() : children}
    </TenantContext.Provider>
  );
};

TenantContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default TenantContext;

import { ConfigProvider, Spin, notification } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import { useWindowSize } from "../components/CoreComponents";
import { themeToken } from "../themes/light";
import lightVars from "../themes/lightVars";

const availableThemes = [
  { key: "light", value: lightVars },
  { key: "dark", value: lightVars }
];

const notificationTypes = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning"
};

const LayoutContext = React.createContext();

export const LayoutContextProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useLocalStorageState("theme", {
    defaultValue: "light"
  });
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowSize();

  const [notificationApi, notificationContext] = notification.useNotification();

  useEffect(() => {
    const theme =
      availableThemes.find(item => item.name === currentTheme) || {};
    setIsLoading(true);

    window.less
      ?.modifyVars?.(theme.value)
      .then(() => {
        window.less.refreshStyles();
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });

    setIsLoading(false);
  }, [currentTheme]);

  const notify = useCallback(
    (type, details) => {
      switch (type) {
        case notificationTypes.success:
          notificationApi.success(details);
          break;
        case notificationTypes.warning:
          notificationApi.warning(details);
          break;
        case notificationTypes.error:
          notificationApi.error(details);
          break;
        default:
          notificationApi.info(details);
          break;
      }
    },
    [notificationApi]
  );

  const toggleTheme = useCallback(
    async newState => {
      setCurrentTheme(newState);
    },
    [setCurrentTheme]
  );

  const value = useMemo(
    () => ({
      currentTheme,
      toggleTheme,
      isMobile: width < 765,
      notify,
      notificationTypes
    }),
    [currentTheme, notify, toggleTheme, width]
  );

  return isLoading ? (
    <Spin spinning />
  ) : (
    <ConfigProvider theme={{ ...themeToken }}>
      <LayoutContext.Provider value={value}>
        {notificationContext}
        {children}
      </LayoutContext.Provider>
    </ConfigProvider>
  );
};

LayoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutContext;

import { toArray } from "@salvus/shared/utils";
import { debounce, omit } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getSimilarData, learningCount, learningSearch } from "../api";
import { useCustomPOSTRequest } from "./restRequest";

export const getItemsFromBasicQuery = data => {
  try {
    return data.hits.map(item => ({
      ...omit(item, ["_source", "ignored"]),
      ...item._source,
      id: item._id,
      links: toArray(item._source.links),
      highlight: item.highlight || {}
    }));
  } catch (err) {
    return [];
  }
};

export const useFetchLFICount = (queryArguments = {}) => {
  const [resultCount, setResultCount] = useState(-1);
  const { filterContext, countConfig } = queryArguments;
  const { postData } = useCustomPOSTRequest(learningCount);

  // eslint-disable-next-line
  const initialFetch = useCallback(
    debounce((context, config, postFn) => {
      if (context && config) {
        postFn(context, config).then(data => {
          const { count } = data;
          setResultCount(count || 0);
        });
      }
    }, 50),
    []
  );

  useEffect(() => {
    initialFetch(filterContext, countConfig, postData);
  }, [filterContext, countConfig, postData, initialFetch]);

  return {
    resultCount
  };
};

export const useFetchLFIResults = (isReady, queryArguments = {}) => {
  const isSubscribedRef = useRef(true);
  const { filterContext, countConfig, offset, bodyObj, extraQueryData } =
    queryArguments;
  const countQueryArgs = useMemo(
    () => ({ filterContext, countConfig: countConfig || {} }),
    [filterContext, countConfig]
  );
  const { resultCount } = useFetchLFICount(countQueryArgs);
  const [results, setResults] = useState();
  const { postData, loading } = useCustomPOSTRequest(learningSearch);
  const [currentOffset, setCurrentOffset] = useState(offset || 0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = useCallback(
    async (currentResult, items, override = false) => {
      const newOffset = currentResult.length + items.length;
      const newResult = override ? items : [...currentResult, ...items];
      if (isSubscribedRef.current) {
        setResults(newResult);
        setCurrentOffset(newOffset);
      }
    }
  );

  // eslint-disable-next-line
  const initialFetch = useCallback(
    debounce((context, postFn, cOffset, count, extra, body) => {
      if (cOffset === 0 && count > cOffset) {
        postFn(context, cOffset, body, extra).then(data => {
          const items = getItemsFromBasicQuery(data);
          updateData([], items, true).then();
        });
      }
    }, 75),
    [updateData, loading]
  );

  const refetch = useCallback(async () => {
    setCurrentOffset(0);
    setResults();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchMore = useCallback(
    debounce(async () => {
      if (results.length >= resultCount) {
        return;
      }
      const data = await postData(
        filterContext,
        currentOffset,
        bodyObj,
        extraQueryData
      );
      const items = getItemsFromBasicQuery(data);
      await updateData(results, items);
    }, 50),
    [
      currentOffset,
      postData,
      updateData,
      resultCount,
      results,
      bodyObj,
      extraQueryData,
      filterContext
    ]
  );

  useEffect(() => {
    if (!isReady) {
      return;
    }

    initialFetch(
      filterContext,
      postData,
      currentOffset,
      resultCount,
      extraQueryData,
      bodyObj,
      updateData
    );
  }, [
    initialFetch,
    updateData,
    filterContext,
    bodyObj,
    extraQueryData,
    resultCount,
    currentOffset,
    postData,
    isReady
  ]);

  useEffect(() => {
    setCurrentOffset(0);
    setResults();
  }, [queryArguments]);

  useEffect(
    () => () => {
      isSubscribedRef.current = false;
    },
    []
  );

  return {
    resultCount,
    results,
    loading,
    hasMore: results?.length < resultCount,
    fetchMore,
    refetch
  };
};

export const useGetSimilarItems = (
  searchFor,
  maxResults = 10,
  samplingSize = 500,
  threshold = 0.85
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isSubscribedRef = useRef(true);

  const fetchSimilar = useCallback(async () => {
    setLoading(true);
    try {
      const getSimilar = await getSimilarData(
        searchFor,
        maxResults,
        samplingSize,
        threshold
      );
      setData(getSimilar.data || []);
    } catch (err) {
      setError(true);
    }
    setLoading(false);
  }, [searchFor, maxResults, samplingSize, threshold]);

  useEffect(
    () => () => {
      isSubscribedRef.current = false;
    },
    []
  );

  useEffect(() => {
    if (!data && !loading && !error) {
      fetchSimilar();
    }
  }, [data, loading, fetchSimilar, error]);

  return {
    data,
    loading,
    error
  };
};

export default {
  useFetchLFIResults,
  useFetchLFICount,
  useGetSimilarItems
};

import { startCase } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SettingsSideMenu from "../../components/SideMenus/SettingsSideMenu";
import PageLayout from "../../layouts/PageLayout";
import Content from "../../modules/System/TenantDetails/Content";

const TenantDetailsPage = () => {
  const history = useHistory();
  const [tenantName, setTenantName] = useState(null);

  const getTenantName = name => {
    setTenantName(name);
  };

  return (
    <PageLayout
      className="tenant-details-page"
      side={{
        placement: "left",
        component: <SettingsSideMenu />
      }}
      header={{
        title: `${startCase(tenantName || "")}: Details`,
        description: `Manage the Details for "${tenantName || ""}".`,
        onBack: () => history.goBack()
      }}
    >
      <Content onTenantNameChange={getTenantName} />
    </PageLayout>
  );
};

export default TenantDetailsPage;

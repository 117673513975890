import { Button, Form, Input, Typography } from "antd";
import { omit } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import tinycolor from "tinycolor2";
import CustomModal from "../../../components/CustomModal";
import LayoutContext from "../../../context/LayoutContext";
import {
  DefaultRequestConfig,
  RequestMethodType,
  topicsEndpoint,
  useApi
} from "../../API";
import PresentationColor from "../components/PresentationColor";

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { flex: 1 }
};

const { TextArea } = Input;

const TopicForm = ({ open, onCancel, onSave, id }) => {
  const { notify, notificationTypes } = useContext(LayoutContext);

  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [saving, setSaving] = useState(false);
  const [presentationColor, setPresentationColor] = useState();
  const [isDirty, setIsDirty] = useState(false);

  const { request: getTopic } = useApi(
    topicsEndpoint,
    RequestMethodType.GET,
    DefaultRequestConfig,
    false
  );

  const { request: putTopic } = useApi(
    topicsEndpoint,
    RequestMethodType.PUT,
    DefaultRequestConfig,
    false
  );

  const { request: postTopic } = useApi(
    topicsEndpoint,
    RequestMethodType.POST,
    DefaultRequestConfig,
    false
  );

  const { resetFields, setFieldsValue } = form;
  const modalTitle = data ? `Edit: ${data?.name}` : "Add Topic";

  const fetchData = useCallback(async () => {
    const response = await getTopic({ url: `${topicsEndpoint}/${id}` });
    const responseData = response.result._source || {};

    setData(responseData);
    resetFields();
    setFieldsValue(omit(responseData, ["presentationColor"]));
    setPresentationColor(responseData.presentationColor);
  }, [getTopic, resetFields, setFieldsValue, id]);

  useEffect(() => {
    if (!open || !id) {
      return;
    }

    fetchData();
  }, [id, open, fetchData]);

  const handleGenerateColorOnClick = () => {
    const colorHex = tinycolor.random().toHexString();
    setPresentationColor(colorHex);
    setIsDirty(true);
  };

  const handleCleanup = useCallback(async () => {
    resetFields();
    setData();
    setFieldsValue({});
    setPresentationColor();
    setIsDirty(false);
    setSaving(false);
  }, [resetFields, setFieldsValue]);

  const handleOnCancel = useCallback(async () => {
    handleCleanup();
    onCancel();
  }, [onCancel, handleCleanup]);

  const handleOnSave = useCallback(
    async values => {
      setSaving(true);
      try {
        const formData = values;
        const payload = {
          ...omit(data, [
            "key",
            "tenantKey",
            "keywords",
            "presentationColor",
            "createdOn",
            "createdBy",
            "updatedOn",
            "updatedBy"
          ]),
          ...formData,
          presentationColor
        };

        if (id) {
          await putTopic({ url: `${topicsEndpoint}/${id}`, data: payload });
        } else {
          await postTopic({ data: payload });
        }

        notify(notificationTypes.success, {
          message: `Topic ${id ? "Updated" : "Added"}`,
          description: `${payload.name} has been successfully ${
            id ? "updated" : "added"
          }`
        });

        onSave(payload);
        handleCleanup();
      } catch (err) {
        setSaving(false);
        notify(notificationTypes.error, {
          message: id
            ? "Unable to update this topic"
            : "Unable to add this topic",
          description: err?.response?.data?.message || err.message || ""
        });
      }
    },
    [
      data,
      presentationColor,
      id,
      notify,
      notificationTypes,
      onSave,
      handleCleanup,
      putTopic,
      postTopic
    ]
  );

  return !open ? null : (
    <CustomModal
      key="topic-modal"
      title={modalTitle}
      open={open}
      onCancel={handleOnCancel}
      footer={
        <div style={{ flex: "1 1 0%" }} key="actions">
          <Button key="cancel" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={saving}
            onClick={() => form.submit()}
            disabled={saving || !isDirty}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        key="form"
        onFinish={handleOnSave}
        form={form}
        {...formItemLayout}
        className="form-slim"
      >
        {data && (
          <Form.Item key="key" name="key" label="Key">
            <Typography.Text className="ant-form-text" type="secondary">
              {data.key}
            </Typography.Text>
          </Form.Item>
        )}

        <Form.Item key="color" label="Color">
          <div>
            <PresentationColor
              key="presentation-color"
              color={presentationColor}
              showRandomGenerator
              onClick={handleGenerateColorOnClick}
            />
          </div>
        </Form.Item>

        <Form.Item
          key="name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a Name"
            }
          ]}
          label="Name"
          onChange={() => setIsDirty(true)}
        >
          <Input />
        </Form.Item>

        <Form.Item
          key="description"
          name="description"
          rules={[
            {
              required: false
            }
          ]}
          label="Description"
        >
          <TextArea
            rows={4}
            style={{ height: 120, resize: "none" }}
            onChange={() => setIsDirty(true)}
          />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

TopicForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  id: PropTypes.string
};

TopicForm.defaultProps = {
  open: false,
  onCancel: () => {},
  onSave: () => {},
  id: null
};

export default TopicForm;

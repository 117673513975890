import PropTypes from "prop-types";
import React from "react";

const CustomLink = ({ href, onClick, children }) =>
  href ? (
    <a
      style={{ cursor: "pointer" }}
      role="button"
      rel="noopener noreferrer"
      target="_blank"
      href={href}
      tabIndex={0}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <div>{children}</div>
  );

CustomLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

CustomLink.defaultProps = {
  href: null,
  onClick: () => {}
};

export default CustomLink;

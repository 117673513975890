import React, { useContext } from "react";
import SideFilters from "../../../components/SideFilters";
// import DataSourcesSelect from "../components/DataSourcesSelect";
import AssetsSelect from "../components/AssetsSelect";
import DateRangeSelect from "../components/DateRangeSelect";
import TopicsSelect from "../components/TopicsSelect";
import DashboardContext from "./Context";

const Filters = () => {
  const {
    filterContext,
    filterOperations: { resetFilters, updateTopics, updateAssets, updateDates }
  } = useContext(DashboardContext);

  const { topics, assets, dates } = filterContext;

  return (
    <SideFilters onClear={() => resetFilters()} filterContext={filterContext}>
      <TopicsSelect
        value={topics}
        onChange={updateTopics}
        allowSelectAll
        allowDeselectAll
      />
      <AssetsSelect
        value={assets}
        onChange={updateAssets}
        allowSelectAll
        allowDeselectAll
      />
      <DateRangeSelect value={dates} onChange={updateDates} />
    </SideFilters>
  );
};

export default Filters;

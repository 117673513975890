import { Modal } from "antd";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";

import "./index.less";

const defaultClassName = "custom-modal";

const CustomModal = ({
  title,
  open,
  footer,
  onCancel,
  children,
  width,
  ...rest
}) => {
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };

  return (
    <Modal
      open={open}
      className={defaultClassName}
      width={width}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move"
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          // fix eslintjsx-a11y/mouse-events-have-key-events
          // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
          onFocus={() => {}}
          onBlur={() => {}}
          // end
        >
          {title}
        </div>
      }
      centered
      onCancel={onCancel}
      getContainer={false}
      footer={
        footer
          ? [
              <div
                style={{ display: "flex", alignItems: "center" }}
                key="modal-footer"
              >
                {footer}
              </div>
            ]
          : null
      }
      // eslint-disable-next-line react/no-unstable-nested-components
      modalRender={modal => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      {...rest}
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  footer: PropTypes.node,
  children: PropTypes.node,
  width: PropTypes.string
};

CustomModal.defaultProps = {
  title: "",
  open: false,
  onCancel: () => {},
  footer: null,
  children: null,
  width: "40%"
};

export default CustomModal;

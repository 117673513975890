import { Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";

const MessageComponent = ({
  title,
  message,
  type,
  wrapperProps,
  containerProps
}) => (
  <div className="cta-wrapper" {...wrapperProps}>
    <div className="cta-container" {...containerProps}>
      <Typography.Title
        level={1}
        type={type}
        style={{ marginTop: "15px", textAlign: "center" }}
      >
        {title}
      </Typography.Title>
      <Typography.Text style={{ marginTop: "15px", textAlign: "center" }}>
        {message}
      </Typography.Text>
    </div>
  </div>
);

MessageComponent.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  wrapperProps: PropTypes.object,
  containerProps: PropTypes.object
};

MessageComponent.defaultProps = {
  message: "",
  type: "",
  title: "",
  wrapperProps: null,
  containerProps: null
};

export default MessageComponent;

import { datadogRum } from "@datadog/browser-rum";
import { PROJECT_ENV, PROJECT_VERSION } from "../config";

const DD_APP_ID = process.env.REACT_APP_DD_APP_ID;
const DD_TOKEN = process.env.REACT_APP_DD_TOKEN;
const DD_ENV = process.env.REACT_APP_DD_ENV;

export const doDataDogInit = () => {
  if (DD_APP_ID) {
    datadogRum.init({
      applicationId: DD_APP_ID,
      clientToken: DD_TOKEN,
      site: "datadoghq.com",
      service: "salvus",
      env: DD_ENV || PROJECT_ENV,
      version: PROJECT_VERSION,
      sampleRate: 100,
      trackInteractions: true
      // defaultPrivacyLevel: "mask-user-input",
    });
  }
};

import * as authHelper from "@salvus/shared/modules/authorization";
import { toArray } from "@salvus/shared/utils";
import axios from "axios";
import { trim } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  AccessTokenStorageKey,
  ActiveTenantKeyStorageKey,
  GlobalTenantConfig
} from "../../constants/common";
import { useAuth } from "../../context/AuthContext";

const getAuthHeaders = () => {
  const accessToken = trim(localStorage.getItem(AccessTokenStorageKey), '"');
  if (!accessToken) {
    throw new Error("Access token missing.");
  }

  const activeTenantKey = trim(
    localStorage.getItem(ActiveTenantKeyStorageKey),
    '"'
  );

  return {
    Authorization: `Bearer ${accessToken}`,
    ...(activeTenantKey && activeTenantKey !== GlobalTenantConfig.key
      ? { "Salvus-Tenant-Key": activeTenantKey }
      : {})
  };
};

export const useProtectedREST = (
  requestUrl,
  requestMethod,
  requestConfig,
  shouldAutomaticFetch
) => {
  const isSubscribedRef = useRef(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({});

  const request = useCallback(
    async (requestConf = requestConfig) => {
      try {
        setLoading(true);
        setError("");

        const authHeaders = getAuthHeaders();

        const response = await axios({
          url: requestUrl,
          method: requestMethod,
          ...requestConfig,
          ...requestConf,
          headers: {
            ...requestConfig.headers,
            ...requestConf.headers,
            ...authHeaders
          },
          validateStatus: status => status >= 200 && status <= 299
        });
        if (isSubscribedRef.current) {
          setData(response.data);
          setLoading(false);
        }
        return response.data;
      } catch (err) {
        if (isSubscribedRef.current) {
          setLoading(false);
          throw err;
        }
        setError(err);
        return null;
      }
    },
    [requestConfig, requestUrl, requestMethod]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (shouldAutomaticFetch) {
      request({ signal })
        .then()
        .catch(err => {
          if (err.name === "AbortError") {
            // eslint-disable-next-line no-console
            console.warn("successfully aborted");
          } else if (isSubscribedRef.current) {
            setError(err);
            setLoading(false);
            setData({});
          }
        });
    }
    return () => {
      // abort the ongoing request
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestConfig, requestUrl, requestMethod, shouldAutomaticFetch]);

  useEffect(
    () => () => {
      isSubscribedRef.current = false;
    },
    []
  );

  return {
    loading,
    error,
    data,
    request
  };
};

export const useAuthorization = () => {
  const { currentUser } = useAuth();

  const activeTenantKey = useMemo(
    () => trim(localStorage.getItem(ActiveTenantKeyStorageKey), '"'),
    []
  );

  const useSystemRoles = useMemo(
    () => authHelper.userSystemRoles(currentUser),
    [currentUser]
  );

  const userTenantRoles = useMemo(
    () => authHelper.userTenantRoles(currentUser, activeTenantKey),
    [currentUser, activeTenantKey]
  );

  const userActiveRoles = useMemo(
    () => authHelper.userActiveRoles(currentUser, activeTenantKey),
    [currentUser, activeTenantKey]
  );

  const hasGlobalAdminRole = useMemo(
    () => authHelper.hasGlobalAdminRole(currentUser),
    [currentUser]
  );

  const hasSecurityRoles = useCallback(
    securityRoles =>
      authHelper.hasSecurityRoles(
        currentUser,
        activeTenantKey,
        toArray(securityRoles)
      ),
    [currentUser, activeTenantKey]
  );

  const isActiveTenantGlobal = useMemo(
    () => activeTenantKey === GlobalTenantConfig.key,
    [activeTenantKey]
  );

  const isActiveTenantNotGlobal = useMemo(
    () => activeTenantKey && activeTenantKey !== GlobalTenantConfig.key,
    [activeTenantKey]
  );

  const hasActiveGlobalAdminRole = useMemo(
    () => authHelper.hasActiveGlobalAdminRole(currentUser),
    [currentUser]
  );

  const hasActiveTenantRole = useMemo(
    () => authHelper.hasActiveTenantRole(currentUser, activeTenantKey),
    [currentUser, activeTenantKey]
  );

  return {
    useSystemRoles,
    userTenantRoles,
    userActiveRoles,
    hasGlobalAdminRole,
    hasSecurityRoles,
    isActiveTenantGlobal,
    isActiveTenantNotGlobal,
    hasActiveGlobalAdminRole,
    hasActiveTenantRole,
    activeTenantKey,
    adminRoles: authHelper.AdminRoles,
    readerRoles: authHelper.ReaderRoles
  };
};

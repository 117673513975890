import { Input, Typography } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import InfoTooltip from "../InfoTooltip";

import "./index.less";

const { Search } = Input;

const defaultClassNamePrefix = "custom-search";

const CustomSearch = ({
  className,
  label,
  info,
  direction,
  labelProps,
  onSearch,
  searchProps,
  placeholder
}) => (
  <div
    className={classNames(`${defaultClassNamePrefix}`, className, [
      `${defaultClassNamePrefix}-${direction}`
    ])}
  >
    {label && (
      <div className={`${defaultClassNamePrefix}-label`}>
        <Typography.Text {...labelProps}>{label}</Typography.Text>
        {info && <InfoTooltip info={info} />}
      </div>
    )}
    <Search
      className={`${defaultClassNamePrefix}-input`}
      onSearch={onSearch}
      placeholder={placeholder}
      allowClear
      {...searchProps}
    />
  </div>
);

CustomSearch.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.string,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  labelProps: PropTypes.object,
  onSearch: PropTypes.func,
  searchProps: PropTypes.object,
  placeholder: PropTypes.string
};

CustomSearch.defaultProps = {
  className: null,
  label: "Search for",
  info: null,
  direction: "vertical",
  labelProps: null,
  searchProps: null,
  onSearch: () => {},
  placeholder: "Search for ..."
};

export default CustomSearch;

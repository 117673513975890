import { SERVICES } from "../../config";

const investigateEndpointPrefix = `${SERVICES.INVESTIGATE}/api`;
const systemEndpointPrefix = `${SERVICES.SYSTEM}/api`;

// #region INVESTIGATE ENDPOINTS

// INVESTIGATE: ANALYTICS
export const analyticsEndpoint = `${investigateEndpointPrefix}/analytics`;

// INVESTIGATE: TOPICS
export const topicsEndpoint = `${investigateEndpointPrefix}/topics`;
export const topicKeywordsEndpoint = `${investigateEndpointPrefix}/topic-keywords`;
export const topicKeywordAdhocEndpoint = `${investigateEndpointPrefix}/adhoc`;

// INVESTIGATE: DATA
export const dataEndpoint = `${investigateEndpointPrefix}/data`;
export const dataActivityEndpoint = `${investigateEndpointPrefix}/data-activity`;
export const dataFlaggedEndpoint = `${investigateEndpointPrefix}/data-flagged`;
export const dataSearchEndpoint = `${investigateEndpointPrefix}/data-search`;

// #endregion INVESTIGATE ENDPOINTS

// #region SYSTEM ENDPOINTS

// SYSTEM: ASSETS
export const assetsEndpoint = `${systemEndpointPrefix}/assets`;

// SYSTEM: DATA PROVIDERS
export const dataProvidersEndpoint = `${systemEndpointPrefix}/data-providers`;

// SYSTEM: DATA SOURCES
export const dataSourcesEndpoint = `${systemEndpointPrefix}/data-sources`;

// SYSTEM: LOGS
export const logsEndpoint = `${systemEndpointPrefix}/logs`;

// SYSTEM: PIPELINES
export const pipelinesEndpoint = `${systemEndpointPrefix}/pipelines`;
export const pipelineRunsEndpoint = `${systemEndpointPrefix}/pipeline-runs`;

// SYSTEM: TENANTS
export const tenantsEndpoint = `${systemEndpointPrefix}/tenants`;
export const tenantAssetsEndpoint = `${systemEndpointPrefix}/tenant-assets`;
export const tenantProfileEndpoint = `${systemEndpointPrefix}/tenant-profile`;

// SYSTEM: USERS
export const userRolesEndpoint = `${systemEndpointPrefix}/user-roles`;
export const usersEndpoint = `${systemEndpointPrefix}/users`;
export const userSubscriptionsEndpoint = `${systemEndpointPrefix}/user-subscriptions`;
export const userTenantsEndpoint = `${systemEndpointPrefix}/user-tenants`;

// #endregion SYSTEM ENDPOINTS

import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import WORLD_MAP from "../../../constants/worldmap.json";
import lightVars from "../../../themes/lightVars";

const MapChart = ({ selectedCountries, onCountryClick }) => {
  const [currentCountryInfo, setCurrentCountryInfo] = useState("");
  return (
    <>
      <ComposableMap
        data-tip=""
        projectionConfig={{ scale: 180, center: [20, 0] }}
        height={450}
      >
        <ZoomableGroup>
          <Geographies geography={WORLD_MAP}>
            {({ geographies }) => (
              <>
                {geographies.map(geo => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      const { NAME } = geo.properties;
                      setCurrentCountryInfo(`${NAME}`);
                    }}
                    onClick={() => {
                      onCountryClick(geo.properties.ISO_A2);
                    }}
                    onMouseLeave={() => {
                      setCurrentCountryInfo("");
                    }}
                    stroke="#fff"
                    style={{
                      default: {
                        fill: selectedCountries.includes(geo.properties.ISO_A2)
                          ? lightVars["@shellDarkBlue"]
                          : lightVars["@colorBorder"],
                        outline: "#fff"
                      },
                      hover: {
                        fill: lightVars["@shellYellow"],
                        outline: "none",
                        cursor: "pointer"
                      }
                    }}
                  />
                ))}
              </>
            )}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <ReactTooltip>{currentCountryInfo}</ReactTooltip>
    </>
  );
};

MapChart.propTypes = {
  onCountryClick: PropTypes.func,
  selectedCountries: PropTypes.arrayOf(PropTypes.string)
};

MapChart.defaultProps = {
  onCountryClick: () => {},
  selectedCountries: []
};

export default memo(MapChart);
